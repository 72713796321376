import axios, {AxiosRequestConfig} from 'axios';
import store from 'redux/store';
import Cookies from 'js-cookie';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

function headerInterceptor(config: AxiosRequestConfig) {
    const {accessToken, clientID, uid} = store.getState().token
    console.log("access-token1: ", localStorage.getItem('admin--desk--member__accessToken'), "client1: ", localStorage.getItem('admin--desk--member__clientID'), "uid1: ", localStorage.getItem('admin--desk--member__uid'))
    console.log("access-token2: ", accessToken, "client2: ", clientID, "uid2: ", uid)
    console.log("access-token3: ", Cookies.get('access-token'), "client3: ", Cookies.get('client'), "uid3: ", Cookies.get('uid'))

    config.headers['access-token'] = localStorage.getItem('admin--desk--member__accessToken') || accessToken || Cookies.get('access-token');
    config.headers['client'] = localStorage.getItem('admin--desk--member__clientID') || clientID || Cookies.get('client');
    config.headers['uid'] = localStorage.getItem('admin--desk--member__uid') || uid || Cookies.get('uid');
    return config;
}

function errorInterceptor(error: any) {
    console.log(error.response)
    return Promise.reject(error);
}

instance.interceptors.request.use(headerInterceptor, errorInterceptor);

export {headerInterceptor, errorInterceptor}

export default instance;
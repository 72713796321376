import React, {useContext} from 'react';
import './OwnerAccountsList.scss';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import Table from 'elements/Table/Table';
import Button from 'elements/Button/Button';
import IconButton from 'elements/IconButton/IconButton';

import { 
    LinkOff as LinkOffIcon,
    Edit as EditIcon,
    AddBox as AddBoxIcon
} from '@material-ui/icons';

import {ownerBankAccounts} from 'auxiliary/state';
import {storeSetOwnerBankAccounts} from 'auxiliary/dispatch';
import {ModalContext} from 'providers/ModalProvider';

import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';

import OwnerAccountsAddAccount from '../OwnerAccountsAddAccount/OwnerAccountsAddAccount';
import OwnerAccountsEditAccount, {BankAccountInterface} from '../OwnerAccountsEditAccount/OwnerAccountsEditAccount';
import OwnerAccountsDeleteAccount from '../OwnerAccountsDeleteAccount/OwnerAccountsDeleteAccount';

interface Props {
    retrieveBankAccounts(params: string): void;
    ownerBankAccounts: OwnerBankAccountsState;
    storeSetOwnerBankAccounts(params: OwnerBankAccountsState): void; 
}

function OwnerAccountsList(props: Props) {
    const modal = useContext(ModalContext);
    
    const previousPageClickHandler = () => {
        props.retrieveBankAccounts(`/members?page=${props.ownerBankAccounts.pagination.currentPage! - 1}`);
    }
    const nextPageClickHandler = () => {
        props.retrieveBankAccounts(`/members?page=${props.ownerBankAccounts.pagination.currentPage! + 1}`);
    }

    const removeAccountHandler = (id: number) => {
        modal.setData({
            header: 'Confirm',
            content: <OwnerAccountsDeleteAccount id={id} />,
        });
        modal.toggle();
    }

    const addAccountHandler = () => {
        modal.setData({
            header: 'Add Account',
            content: <OwnerAccountsAddAccount />
        });
        modal.show();
    }

    const editAccountHandler = (bankAccount: BankAccountInterface) => {
        console.log(bankAccount);
        modal.setData({
            header: 'Edit Account',
            content: <OwnerAccountsEditAccount bankAccount={bankAccount} />
        });
        modal.show();
    }

    return (
        <div className="OwnerAccountsList">
            <IconButton
                className="add--account--button"
                iconElement={<AddBoxIcon />}
                clickHandler={addAccountHandler}
            />

            <Table
                headers={['Country', 'Bank Name', 'Account Number', 'Account Name', 'Actions']}
                content={
                    props.ownerBankAccounts.bankAccounts.map((el, index) => [
                        el.country || 'N/A',
                        el.bankName || 'N/A',
                        el.accountNumber || '',
                        el.accountName || 'N/A',
                        <div key={index} className="owner--account--actions">
                            <Button
                                text="edit"
                                iconElement={<EditIcon />}
                                size="small"
                                rippleColor="dark"
                                clickHandler={() => editAccountHandler(el)}
                            />
                            
                            <Button
                                text="remove"
                                iconElement={ <LinkOffIcon />}
                                size="small"
                                rippleColor="dark"
                                clickHandler={() => removeAccountHandler(el.id)}
                            />
                        </div>
                    ])
                }
                pagination={{
                    pages: props.ownerBankAccounts.pagination.pages!,
                    currentPage: props.ownerBankAccounts.pagination.currentPage!,
                    totalCount: props.ownerBankAccounts.pagination.totalCount!
                }}
                previousPageClickHandler={previousPageClickHandler}
                nextPageClickHandler={nextPageClickHandler}
            />
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [ownerBankAccounts], dispatchProps: [storeSetOwnerBankAccounts]})
)(OwnerAccountsList);
import React from 'react';
import {connect} from 'react-redux';
import {
    storeSetToken,
    storeRemoveToken,
    storeSetUser,
    storeRemoveUser,
    storeVerifyUser,
    storeSetLinkedAccounts,
    storeRemoveLinkedAccounts,
    storeSetOwnerMembers,
    storeRemoveOwnerMembers,
    storeSetOwnerBankAccounts,
    storeRemoveOwnerBankAccounts,
    storeSetMemberPurchaseRequests,
    storeSetMemberCashOuts,
    storeSetPageConfiguration
} from 'auxiliary/dispatch';
import {
    token,
    user,
    linkedAccounts,
    ownerMembers,
    ownerBankAccounts,
    memberPurchaseRequests,
    memberCashOuts,
    pageConfiguration
} from 'auxiliary/state';

import {TokenStateInterface} from 'redux/reducers/token-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import {OwnerMembersState} from 'redux/reducers/owner/owner-members-reducer';
import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';
import {MemberPurchaseRequestState} from 'redux/reducers/member/member-purchase-requests-reducer';
import {MemberCashOutState} from 'redux/reducers/member/member-cash-outs-reducer';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';

type indexSignature = {
    [key: string]: any
};
export default function withStoreConnection(params: {stateProps?: Array<String>, dispatchProps?: Array<String>}) {
    const mapStateToProps = (state: any) => {
        const storeState: indexSignature = {
            [token]: state[token],
            [user]: state[user],
            [linkedAccounts]: state[linkedAccounts],
            [ownerMembers]: state[ownerMembers],
            [ownerBankAccounts]: state[ownerBankAccounts],
            [memberPurchaseRequests]: state[memberPurchaseRequests],
            [memberCashOuts]: state[memberCashOuts],
            [pageConfiguration]: state[pageConfiguration]
        }
        if (!params.stateProps) return {};
        return params.stateProps.reduce((acc: Object, curr: any) => {
            if (Object.prototype.hasOwnProperty.call(storeState, curr)) {
                return {
                    ...acc,
                    ...{[curr]: storeState[curr]}
                }
            } else {
                throw new Error(`${curr} property is not in storeState`);
            }
        }, {});
    };
    
    const mapDispatchToProps = (dispatch: Function) => {
        const storeDispatch: indexSignature = {
            [storeSetToken]: (responseObject: TokenStateInterface) => dispatch({type: 'SET_TOKEN', payload: responseObject}),
            [storeRemoveToken]: () => dispatch({type: 'REMOVE_TOKEN'}),
            [storeSetUser]: (userInformation: UserStateInterface) => dispatch({type: 'SET_USER', payload: userInformation}),
            [storeRemoveUser]: () => dispatch({type: 'REMOVE_USER'}),
            [storeVerifyUser]: () => dispatch({type: 'VERIFY_USER'}),

            [storeSetLinkedAccounts]: (linkedAccounts: Array<any>) => dispatch({type: 'SET_LINKED_ACCOUNTS', payload: linkedAccounts}),
            [storeRemoveLinkedAccounts]: () => dispatch({type: 'REMOVE_LINKED_ACCOUNTS'}),

            [storeSetOwnerMembers]: (ownerMembers: OwnerMembersState) => dispatch({type: 'SET_OWNER_MEMBERS', payload: ownerMembers}),
            [storeRemoveOwnerMembers]: () => dispatch({type: 'REMOVE_OWNER_MEMBERS'}),

            [storeSetOwnerBankAccounts]: (ownerBankAccounts: OwnerBankAccountsState) => dispatch({type: 'SET_OWNER_BANK_ACCOUNTS', payload: ownerBankAccounts}),
            [storeRemoveOwnerBankAccounts]: () => dispatch({type: 'REMOVE_OWNER_BANK_ACCOUNTS'}),

            [storeSetMemberPurchaseRequests]: (memberPurchaseRequests: MemberPurchaseRequestState) => dispatch({type: 'SET_MEMBER_PURCHASE_REQUESTS', payload: memberPurchaseRequests}),
            [storeSetMemberCashOuts]: (memberCashOuts: MemberCashOutState) => dispatch({type: 'SET_MEMBER_CASH_OUTS', payload: memberCashOuts}),

            [storeSetPageConfiguration]: (pageConfiguration: PageConfigurationState) => dispatch({type: 'SET_PAGE_CONFIGURATION', payload: pageConfiguration})
        };
        if (!params.dispatchProps) return {};
        return params.dispatchProps.reduce((acc, curr: any) => {
            if (Object.prototype.hasOwnProperty.call(storeDispatch, curr)) {
                return {
                    ...acc,
                    ...{[curr]: storeDispatch[curr]}
                }
            } else {
                throw new Error(`${curr} property is not in storeDispatch`);
            }
        }, {});
    };

    return function (Component: any) {
        function EnhancedComponent(props: any) {
            return <Component {...props} />
        }
        return connect(mapStateToProps, mapDispatchToProps)(EnhancedComponent);
    }
}
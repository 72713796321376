import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom';
import './LogOut.scss';
import {ExitToApp as ExitToAppIcon} from '@material-ui/icons';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeRemoveToken, storeRemoveUser, storeRemoveLinkedAccounts, storeRemoveOwnerMembers} from 'auxiliary/dispatch';
import {user} from 'auxiliary/state';
import {LoadingScreenContext} from '../LoadingScreen/LoadingScreenProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import Cookies from 'js-cookie';

interface Props {
    children: 'string' | JSX.Element;
    buttonClass: string;
    icon: boolean;
    storeRemoveToken(): void;
    storeRemoveUser(): void;
    storeRemoveLinkedAccounts(): void;
    storeRemoveOwnerMembers(): void;
    user: {
        role: 'member' | 'owner'
    }
}

function LogOut(props: Props) {
    const history = useHistory();
    const snackbar = useContext(SnackbarContext);
    const loadingScreen = useContext(LoadingScreenContext);
    const logOut = () => {
        loadingScreen.show();

        setTimeout(() => {
            loadingScreen.hide();
            props.storeRemoveToken();
            props.storeRemoveUser();
            if (props.user.role === 'member') {
                props.storeRemoveLinkedAccounts();
            } else if (props.user.role === 'owner') {
                props.storeRemoveOwnerMembers();
            }
            snackbar.show({text: 'Logged-out successfully'});
            history.replace('/');
            
            // remove cookies
            const split_host = window.location.host.split(".");
            split_host.shift();
            const host = split_host.length === 1 ? "" : split_host.join(".");

            Cookies.remove('access-token', {domain: host})
            Cookies.remove('client',  {domain: host})
            Cookies.remove('uid',  {domain: host})
        }, 3000);
    };

    return (
        <button onClick={logOut} className={props.buttonClass || `LogOut`}>
            {props.children ? props.children : props.icon === false ? null : <ExitToAppIcon className="margin-right-3" />}
            {props.children ? null : 'Logout'}
        </button>
    )
}

export default compose(
    withStoreConnection({
        dispatchProps: [storeRemoveToken, storeRemoveUser, storeRemoveLinkedAccounts, storeRemoveOwnerMembers],
        stateProps: [user]
    })
)(LogOut);
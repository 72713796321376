import React, {useState, useRef} from 'react';
import './MemberReferral.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';
import Notice from 'elements/Notice/Notice';
import axios from 'auxiliary/axios';

import { 
    Code as CodeIcon,
    Assignment as AssignmentIcon
} from '@material-ui/icons';

import withStoreConnection from 'hoc/withStoreConnection';
import {compose} from 'redux';
import {user} from 'auxiliary/state';
import {storeSetUser} from 'auxiliary/dispatch';

import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    user: UserStateInterface;
    storeSetUser(params: UserStateInterface): void;
}

function MemberReferral(props: Props) {
    const formNoticeRef = useRef<HTMLDivElement>(null!);

    const initialState = {value: '', valid: false, error: ''};
    const [referralCode, setReferralCode] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);

    const [noticeText, setNoticeText] = useState('');
    const [noticeState, setNoticeState] = useState('hidden');
    const [noticeTimeStamp, setNoticeTimeStamp] = useState(0);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        };

        switch (result.origin) {
            case 'referralCode':
                    setReferralCode(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());
        setReferralCode({...referralCode, error: referralCode.valid ? '' : referralCode.error || 'Referral code is invalid'});
        const result = [
            referralCode
        ].map(e => e.valid);
        if (result.every(valid => valid)) {
            try {
                await axios.post('/account/update_referral_code', {code: referralCode.value, organization_id: props.user.organization.id});
                props.storeSetUser({
                    ...props.user,
                    referralCode: referralCode.value
                });
                setNoticeText("Succesfully updated referral code.");
                setNoticeState("success");
                setNoticeTimeStamp(Date.now())
            } catch(error) {
                setNoticeState("hidden");
                const errorMessage = error.response.data.error;
                setReferralCode({...referralCode, valid: false, error: errorMessage});
            }
        }
    }

    return (
        <div className="MemberReferral">
            <div className="image--container" style={{
                minHeight: (window.innerHeight - 80) + 'px',
                background: `linear-gradient(to right, rgba(0, 0, 0, 0.9), #0000004d), url('https://source.unsplash.com/1600x900/?poker')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
                <h2 className="page--header">Referral Program</h2>
                <div className="referral--code--notice margin-bottom-10">Your referral link is: </div>
                <div className="referral--code">
                    <div>{window.location.host + '/signup/' + props.user.referralCode}</div>
                    <CopyToClipboard
                        text={`${window.location.host}/signup/${props.user.referralCode}`}
                        onCopy={() => {
                            formNoticeRef.current.innerText = 'Your referral link has been copied successfully.';
                        }}>
                        <button className="clipboard--button ripple"><AssignmentIcon /></button>
                    </CopyToClipboard>
                </div>
                <div className="form--notice" ref={formNoticeRef}>You may change your referral code by filling-up the form below</div>
                <section className="main--section">
                    <Notice text={noticeText} noticeState={noticeState} timeStamp={noticeTimeStamp} margin="margin-bottom-10" />
                    <p
                    className="margin-bottom-20"
                    style={{
                        fontSize: '16px',
                        color: '#fff',
                        textAlign: 'center'
                    }}>
                        As a referrer, you'll receive an incentive from your player’s activity.
                    </p>
                    <form onSubmit={submitForm} className="margin-left-right-auto">
                        <p className="text-align-center" style={{color: '#fff', fontSize: '16px'}}>Set new code</p>
                        <InputGroup
                            change={handleChange}
                            error={referralCode.error}
                            iconElement={<CodeIcon />}
                            id="referralCode"
                            placeholder="New referral code"
                            margin="margin-top-20"
                            valid={referralCode.valid}
                            validatedProps={{minLength: 10, alphaNumeric: true, maxLength: 14}}
                            timeStamp={timeStamp}
                        />
                        <Button text="Submit" size="small" backgroundColor="var(--accent-three-shade-three)" />
                    </form>
                </section>
            </div>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetUser]})
)(MemberReferral)
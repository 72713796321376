import React, {useContext, useState} from 'react';
import './OwnerClubActivity.scss';
import Container from 'elements/Container/Container';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';
import {storeSetUser} from 'auxiliary/dispatch';

import {UserStateInterface} from 'redux/reducers/user-reducer';

import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

interface Props {
    user: UserStateInterface;
    storeSetUser(params: UserStateInterface): void;
}

function OwnerClubActivity(props: Props) {
    const [buttonLoading, setButtonLoading] = useState(false);
    const snackbar = useContext(SnackbarContext);
    const quillToolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],

        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],

        ['clean']
    ];
    
    const changeTextEditorValue = (value: string) => {
        const {organization} = {...props.user};
        organization.clubTasks = value;
        props.storeSetUser({
            ...props.user,
            organization: organization  
        })
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = {
            club_tasks: props.user.organization.clubTasks
        }
        
        try {
            setButtonLoading(true);
            await axios.patch('', data);
            setTimeout(() => {
                setButtonLoading(false);
                snackbar.show({text: 'Club activity has been updated'})
            }, 2000)
        } catch(error) {
            setButtonLoading(false);
            console.log(error.response);
        }

    }

    return (
        <div className="OwnerClubActivity">
            <Container>
                <div className="club--activity--header">Club Activity</div>
                <p style={{
                    color: 'var(--dark-text)',
                    fontSize: '13px',
                    fontWeight: 300,
                    marginTop: '6px',
                    textAlign: 'justify'
                }}>
                    If you have any tasks you would like AdminDesk staff to automate for you, 
                    eg. opening tables at the start of the day, please describe them below and hit Submit.
                </p>
                <section className="editor--section margin-top-40">
                    <form onSubmit={submitForm}>
                        <ReactQuill theme="snow"
                            value={props.user.organization.clubTasks}
                            onChange={changeTextEditorValue}
                            modules={{toolbar: quillToolbarOptions}}
                        />
                        <Button
                            text="Submit"
                            margin="margin-top-20"
                            width="80px"
                            loading={buttonLoading}
                        />
                    </form>
                </section>
            </Container>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetUser]})
)(OwnerClubActivity);
import React from 'react';
import './Button.scss';
import {CircularProgress} from '@material-ui/core';

interface Props {
    backgroundColor?: string;
    buttonType?: string;
    className?: string;
    clickHandler?(): void;
    color?: string;
    icon?: string;
    iconElement?: JSX.Element;
    loading?: boolean;
    margin?: string;
    rippleColor?: string;
    size?: string;
    text?: string;
    width?: string;
}

function Button(props: Props) {
    const buttonClass = [
        'Button',
        props.color + '-color',
        props.margin,
        'margin-left-right-auto',
        props.size || 'medium',
        'ripple',
        props.rippleColor,
        props.className
    ];

    return (
        <button
            className={buttonClass.join(' ')}
            data-button-type={props.buttonType}
            onClick={props.clickHandler}
            disabled={props.loading}
            style={{
            color: props.color,
            backgroundColor: props.loading ? '#9E9E9E' : props.backgroundColor,
            width: props.width,
            cursor: props.loading ? 'initial' : 'pointer'
        }}>
            {
                props.loading ?
                    <CircularProgress size={18} />
                    :
                    <React.Fragment>
                        {props.iconElement}
                        {props.text || 'Submit'}
                    </React.Fragment>
            }
            
        </button>
    )
}

export default Button;
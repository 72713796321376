import {ActionInterface} from './interfaces';

const nullState = {
    id: null,
    firstName: null,
    lastName: null,
    email: null,
    mobileNumber: null,
    role: null,
    verified: null,
    referralCode: null,
    organization: {
        name: null,
        identifier: null,
        ownerID: null,
        url: null,
        siteConfigurationID: null
    },
    referrals: {
        refs: [],
        pagination: {
            current: 1,
            pages: 1,
            count: 0
        },
    }
}

const userInformation = JSON.parse(localStorage.getItem('admin--desk--member__user')!) || nullState;
const initialState = {
    id: userInformation['id'],
    firstName: userInformation['firstName'],
    lastName: userInformation['lastName'],
    email: userInformation['email'],
    mobileNumber: userInformation['mobileNumber'],
    role: userInformation['role'],
    verified: userInformation['verified'],
    referralCode: userInformation['referralCode'],
    organization: {
        name: userInformation.organization['name'],
        identifier: userInformation.organization['identifier'],
        ownerID: userInformation.organization['ownerID'],
        url: userInformation.organization['url'],
        siteConfigurationID: userInformation.organization['siteConfigurationID'],
        clubTasks: userInformation.organization['clubTasks']
    },
    referrals: userInformation['referrals']
}

const actions = {
    SET_USER: 'SET_USER',
    SET_MOBILE_NUMBER: 'SET_MOBILE_NUMBER',
    REMOVE_USER: 'REMOVE_USER',
    VERIFY_USER: 'VERIFY_USER'
}

function reducer(state = initialState, action: ActionInterface<UserStateInterface>) {
    switch (action.type) {
        case actions.REMOVE_USER:
            localStorage.removeItem('admin--desk--member__user');
            return {
                ...state,
                ...nullState
            }
        case actions.SET_USER:
            localStorage.setItem('admin--desk--member__user', JSON.stringify(action.payload));
            return {
                ...state,
                ...action.payload
            }
        case actions.SET_MOBILE_NUMBER: {
            const newState = {
                ...state,
                ...action.payload
            };
            localStorage.setItem('admin--desk--member__user', JSON.stringify(newState));
            return newState;
        }
        case actions.VERIFY_USER: {
            const newState = {
                ...state,
                verified: true
            }
            localStorage.setItem('admin--desk--member__user', JSON.stringify(newState));
            return newState;
        }
        default: {
            return state;
        }
    }
}

type Ref = {
    first_game_account: string;
    created_at: string;
    email: string;
    first_name: string;
    last_name: string;
    mobile_number: string;
}

type Referrals = {
    refs: Array<Ref>;
    pagination: {
        current: number,
        pages: number,
        count: number
    }
}

export interface UserStateInterface {
    id: string | null;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    mobileNumber: string | null;
    role: string;
    verified: boolean | null;
    referralCode: string | null;
    organization: {
        id: string | null;
        name: string | null;
        identifier: string | null;
        ownerID: string | null;
        url: string | null;
        siteConfigurationID: string | null;
        clubTasks: string;
        organizationGamePlatform: {
            name: string | null;
        }
    },
    referrals: Referrals;
}

export default reducer;
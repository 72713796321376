import React, {useCallback, useEffect, useRef} from 'react';
import './Member.scss';

import axios from 'auxiliary/axios';

import MemberProfile from './MemberProfile/MemberProfile';
import MemberMenu from './MemberMenu/MemberMenu';
import PurchaseChips from './PurchaseChips/PurchaseChips';
import CashOut from './CashOut/CashOut';
import Transactions from './Transactions/Transactions';
import MemberReferral from './MemberReferral/MemberReferral';
import MemberPromotions from './MemberPromotions/MemberPromotions';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {token, user} from 'auxiliary/state';
import {
    storeSetLinkedAccounts,
    storeSetMemberPurchaseRequests,
    storeSetMemberCashOuts,
    storeSetPageConfiguration,
    storeVerifyUser
} from 'auxiliary/dispatch';

import {
    Switch,
    Route,
    useRouteMatch,
    useHistory,
} from 'react-router-dom';

import Header from '../Header/Header';
import Error from '../Error/Error';

import {MemberPurchaseRequestState} from 'redux/reducers/member/member-purchase-requests-reducer';
import {MemberCashOutState} from 'redux/reducers/member/member-cash-outs-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    location: Object;
    storeSetLinkedAccounts(params: string): void;
    storeSetMemberPurchaseRequests(params: Partial<MemberPurchaseRequestState>): void;
    storeSetMemberCashOuts(params: Partial<MemberCashOutState>): void;
    user: UserStateInterface;
    storeVerifyUser(): void;
}

function Member(props: Props) {
    const history = useHistory();
    const {path} = useRouteMatch();
    const componentRef = useRef<HTMLDivElement>(null!);

    const {
        storeSetLinkedAccounts,
        storeSetMemberPurchaseRequests,
        storeSetMemberCashOuts,
        user,
        storeVerifyUser
    } = props;

    const setPurchaseRequests = useCallback(
        async (url) => {
            try {
                storeSetMemberPurchaseRequests({
                    progressIndicatorVisible: true
                });
                const response = await axios.get(url);
                const purchaseRequests = response.data.purchase_requests.map((el: any) => {
                    return {
                        id: el.id,
                        referenceNumber: el.reference_number,
                        createdAt: el.created_at,
                        amount: el.amount,
                        bankAccount: {
                            id: el.bank_account.id,
                            accountName: el.bank_account.account_name,
                            accountNumber: el.bank_account.account_number,
                            bankName: el.bank_account.bank_name
                        },
                        imageUrl: el.image_url,
                        linkedAccount: {
                            id: el.linked_account.id,
                            gameID: el.linked_account.game_id,
                            username: el.linked_account.username
                        },
                        status: el.status
                    }
                });
                const pagination = {
                    nextUrl: response.data.pagination.next_url,
                    previousUrl: response.data.pagination.previous_url,
                    currentPage: response.data.pagination.current,
                    pages: response.data.pagination.pages,
                    totalCount: response.data.pagination.count
                }
                storeSetMemberPurchaseRequests({
                    purchaseRequests: purchaseRequests,
                    pagination: pagination
                })
                setTimeout(() => {
                    storeSetMemberPurchaseRequests({
                        progressIndicatorVisible: false
                    })
                }, 500)
            } catch(error) {
                console.log('owner members retrieval failure')
            }
        }, [storeSetMemberPurchaseRequests]
    )

    const setCashOuts = useCallback(
        async(url) => {
            try {
                storeSetMemberCashOuts({
                    progressIndicatorVisible: true
                });

                const response = await axios.get(url);
                const cashOuts = response.data.withdrawal_requests.map((el: any) => {
                    return {
                        id: el.id,
                        referenceNumber: el.reference_number,
                        createdAt: el.created_at,
                        chips: el.chips,
                        bankAccount: {
                            id: el.bank_account.id,
                            accountName: el.bank_account.account_name,
                            accountNumber: el.bank_account.account_number,
                            bankName: el.bank_account.bank_name
                        },
                        linkedAccount: {
                            id: el.linked_account.id,
                            gameID: el.linked_account.game_id,
                            username: el.linked_account.username
                        },
                        status: el.status
                    }
                });

                const pagination = {
                    nextUrl: response.data.pagination.next_url,
                    previousUrl: response.data.pagination.previous_url,
                    currentPage: response.data.pagination.current,
                    pages: response.data.pagination.pages,
                    totalCount: response.data.pagination.count
                }

                storeSetMemberCashOuts({
                    cashOuts: cashOuts,
                    pagination: pagination
                });

                setTimeout(() => {
                    storeSetMemberCashOuts({
                        progressIndicatorVisible: false
                    })
                }, 500)
            } catch(error) {
                console.log('cash outs retrieval failed; error: '+ error.response)
            }
        }, [storeSetMemberCashOuts]
    )

    useEffect(function setViewportHeight() {
        const height = window.innerHeight - 80;
        componentRef.current.style.height = height + 'px';
    }, [])

    useEffect(() => {
        const retrieveLinkedAccounts = async function() {
            try {
                const response = await axios.get('/account/linked_accounts');
                storeSetLinkedAccounts(response.data.linked_accounts);
            } catch(error) {
                console.log(error)
            }
        }
        retrieveLinkedAccounts();
    }, [storeSetLinkedAccounts]);

    useEffect(() => {
        const initPurchaseRequests = async function() {
            setPurchaseRequests('/account/purchase_requests');
        }
        initPurchaseRequests();

        const initCashOuts = async function() {
            setCashOuts('/account/withdrawal_requests');
        }
        initCashOuts();
    }, [setPurchaseRequests, setCashOuts]);

    useEffect(() => {
        const checkUserVerified = async () => {
            try {
                const response = await axios.get(`/members/${user.id}`);
                if (!response.data.verified) {
                    history.push('/member-verification', {message: "Please verify your account.", noticeState: 'error'});
                } else {
                    storeVerifyUser();
                }
            } catch(error) {
                console.log(error.response)
            }
        }
        checkUserVerified();
    }, [history, user.id, storeVerifyUser]);
    // unsafe

    return (
        <div className="Member" ref={componentRef}>
            <Header headerType="member" />
            <Switch>
                <Route exact path="/member" component={MemberMenu} />
                <Route path={`${path}/profile`}>
                    <MemberProfile />
                </Route>
                <Route path={`${path}/purchase-chips`}>
                    <PurchaseChips setPurchaseRequests={setPurchaseRequests} />
                </Route>
                <Route path={`${path}/cash-out`}>
                    <CashOut setCashOuts={setCashOuts} />
                </Route>
                <Route path={`${path}/transactions`} >
                    <Transactions setPurchaseRequests={setPurchaseRequests} setCashOuts={setCashOuts} />
                </Route>
                <Route path={`${path}/referral`}>
                    <MemberReferral />
                </Route>
                <Route path={`${path}/promotions`} component={MemberPromotions} />
                <Route path="*" render={() => <Error {...props} />} />
            </Switch>
        </div>
    )
}

export default compose(
    withStoreConnection({
        stateProps: [token, user],
        dispatchProps: [
            storeSetLinkedAccounts,
            storeSetMemberPurchaseRequests,
            storeSetMemberCashOuts,
            storeSetPageConfiguration,
            storeVerifyUser
        ]},
    )
)(Member);
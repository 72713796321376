import React, {useState, useEffect, useContext} from 'react';
import Container from 'elements/Container/Container';
import Heading from 'elements/Heading/Heading';
import './PurchaseChipsStyle.scss';
import Select from 'elements/Select/Select';
import FileInput from 'elements/FileInput/FileInput';
import Button from 'elements/Button/Button';
import FormattedNumberInput from 'elements/FormattedNumberInput/FormattedNumberInput';
import axios from 'auxiliary/axios';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {linkedAccounts} from 'auxiliary/state';
import {ModalContext} from 'providers/ModalProvider';
import {useHistory} from 'react-router-dom';
import money from 'assets/images/money.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

interface Props {
    setPurchaseRequests(params: string): void;
    linkedAccounts: Array<any>;
}

interface InitialState {
    value: any;
    valid: boolean;
    error: string;
}

interface TargetBankData {
    bankName: string;
    accountName: string;
    accountNumber: string;
}

interface ClubConfig {
    chipValue: number;
    currency: string;
}

function PurchaseChips(props: Props) {
    const [pageLoading, setPageLoading] = useState(false);
    const initialState = {value: null, valid: false, error: ''};
    const [pokerAccount, setPokerAccount] = useState<Partial<InitialState>>({...initialState});
    const [targetBankAccount, setTargetBankAccount] = useState<Partial<InitialState>>({...initialState});
    const [selectedFile, setSelectedFile] = useState<Partial<InitialState>>({...initialState});
    const [amountToSend, setAmountToSend] = useState<Partial<InitialState>>({...initialState});

    const [clubBankAccounts, setClubBankAccounts] = useState<Array<any>>([]);
    const [targetBankData, setTargetBankData] = useState<TargetBankData>({bankName: '', accountName: '', accountNumber: ''});
    const [clubConfig, setClubConfig] = useState<ClubConfig>({ chipValue: 1.0, currency: '' });

    const context = useContext(ModalContext);
    const history = useHistory();

    const [initialPokerAccount, setInitialPokerAccount] = useState<{text: string; value: string;}>(null!);

    useEffect(() => {
        const initBankAccounts = async function() {
            try {
                const response = await axios.get('/bank_accounts');
                const bankAccounts: Array<any> = response.data.accounts;
                setClubBankAccounts(bankAccounts.map(account => {
                    return {
                        text: `${account.bank_name} (${account.account_number})`,
                        value: account.id.toString(),
                        data: {
                            accountName: account.account_name,
                            accountNumber: account.account_number,
                            bankName: account.bank_name,
                            country: account.country
                        }
                    }
                }));
            } catch(error) {
                console.log(error.response)
            }
        }
        initBankAccounts();
    }, []);

    useEffect(() => {
        const initClubConfig = async function() {
            try {
                const response = await axios.get('/');

                setClubConfig(() => {
                    return {
                        chipValue: Number(response.data.chip_value),
                        currency: response.data.currency
                    }
                });
            } catch(error) {
                console.log(error.response)
            }
        }

        initClubConfig();
    }, []);

    useEffect(() => {
        const targetBank = clubBankAccounts.find(el => el.value.toString() === targetBankAccount.value);
        if (targetBank) {
            setTargetBankData(targetBank.data)
        }
    }, [targetBankAccount, clubBankAccounts]);

    useEffect(() => {
        if (props.linkedAccounts[0]) {
            setPokerAccount({valid: true, value: props.linkedAccounts[0].id.toString()});
            setInitialPokerAccount({text: props.linkedAccounts[0].username, value: props.linkedAccounts[0].id.toString()});
        }
    }, [props.linkedAccounts])

    useEffect(() => {
        if (clubBankAccounts[0]) {
            setTargetBankAccount({valid: true, value: clubBankAccounts[0].value.toString()})
        }
    }, [clubBankAccounts])

    const handleChange = (result: {value: string, valid: boolean}) => {
        const newState = {
            value: result.value,
            valid: result.valid
        }
        setAmountToSend(newState);
    };

    const handleSelect = (result: {value: string | File; valid: boolean; origin: string}) => {
        const newState = {
            value: result.value,
            valid: result.valid,
        }

        switch(result.origin) {
            case 'poker-account':
                    setPokerAccount(newState);
                break;
            case 'target-bank-account':
                    setTargetBankAccount(newState);
                break;
            case 'selected-file':
                    setSelectedFile(newState)
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    };

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setPokerAccount({...pokerAccount, error: pokerAccount.valid ? '' : 'Please select a valid account.'});
        setTargetBankAccount({...targetBankAccount, error: targetBankAccount.valid ? '' : 'Please select a club bank account.'});
        setSelectedFile({...selectedFile, error: selectedFile.valid ? '' : 'Please select a file.'});
        setAmountToSend({...amountToSend, error: amountToSend.valid ? '' : 'Please enter a valid amount.'});

        const result = [
            pokerAccount,
            targetBankAccount,
            selectedFile,
            amountToSend
        ].map(e => e.valid);
        
        if (result.every(valid => valid)) {
            const formData = new FormData();
            formData.append('bank_account_id', targetBankAccount.value);
            formData.append('linked_account_id', pokerAccount.value);
            formData.append('amount', amountToSend.value.replace(',',''));
            formData.append('image', selectedFile.value!);

            try {
                setPageLoading(true);
                await axios.post('/account/purchase_requests', formData);
                setPageLoading(false);
                props.setPurchaseRequests('/account/purchase_requests');
                context.setData({
                    header: 'Success',
                    content: <div>
                        <p>Thank you for your purchase! We'll get back to you as soon as we finish processing your request.</p>
                        <div className="confirmation--buttons margin-top-20">
                            <Button width="80px" text="Ok" clickHandler={
                                () => {
                                    context.hide();
                                    history.push('/member/transactions/purchase-requests');
                                }
                            } />
                        </div>
                    </div>,
                });

                context.toggle();
            } catch(error) {
                console.log(error.response)
                console.log('failed to process purchase request. Error: ' + error);
                context.setData({
                    header: 'Error',
                    content: (
                        <div className="success--content">
                            <p>
                                Sorry! We cannot process your order. 
                            </p>
                            <p>
                                If you have a pending deposit request, please wait for us to process it before making a new one.
                            </p>
                            <div className="confirmation--buttons margin-top-20">
                                <Button width="80px" text="Ok" clickHandler={
                                    () => {
                                        context.hide();
                                        history.push('/member/transactions/purchase-requests');
                                    }
                                } />
                            </div>
                        </div>
                    )
                });
                context.show();
            }
        } else {
            if ([
                pokerAccount,
                targetBankAccount,
                amountToSend,
            ].map(e => e.valid).includes(false)) {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            }
        }
    }

    return (
        pageLoading ? 
        <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} />
        :
        <Container constrain={900}>
            <div className="PurchaseChips">
                <Heading text="Purchase Chips" level="two" alignment="left" />
                <p className="margin-top-bottom-20">Pay with Bank Transfer</p>
                    <form onSubmit={submitForm}>
                        <div className="payment--input margin-top-bottom-10 padding-left-right-30 padding-top-bottom-40">
                            <div className="select--container">
                                <Select 
                                    id="poker-account" 
                                    options={props.linkedAccounts.map(account => {
                                        return {
                                            text: account.username,
                                            value: account.id.toString()
                                        }
                                    })}
                                    initialSelectedOption={
                                        initialPokerAccount ? initialPokerAccount : null
                                    }
                                    selectText="Player Account"
                                    select={handleSelect}
                                    error={pokerAccount.error!}
                                />
                            </div>

                            <div className="select--container">
                                <Select
                                    id="target-bank-account"
                                    options={clubBankAccounts}
                                    selectText="Bank Sending To"
                                    select={handleSelect}
                                    error={targetBankAccount.error!}
                                    initialValue={
                                        (() =>  {
                                            const result = clubBankAccounts?.find(el => el.value === targetBankAccount.value);
                                            return result ? result.text : '';
                                        })()
                                    }
                                />
                            </div>                        

                            <div className="select--container">
                                <FormattedNumberInput
                                    change={handleChange}
                                    id="amount-to-send"
                                    error={amountToSend.error!}
                                    iconElement={<img src={money} alt="money" />}
                                />
                                <p>1 chip : {clubConfig.chipValue} {clubConfig.currency}</p>
                                <p>This is equivalent to { Number(amountToSend.value ? amountToSend.value.replace(',','') : 0 ) / clubConfig.chipValue } chips.</p>
                            </div>
                        </div>

                        <div className="section--border margin-top-bottom-40"></div>

                        <div className="payment--information">
                            <p>Please transfer the amount to the following account:</p>
                            <div className="payment--information--details padding-left-right-30 padding-top-bottom-20">
                                <div className="payment--information--item">
                                    <span className="caption">Bank name:</span>
                                    <span className="value">{targetBankData.bankName}</span>
                                </div>
                                <div className="payment--information--item">
                                    <span className="caption">Account name:</span>
                                    <span className="value">{targetBankData.accountName}</span>
                                </div>
                                <div className="payment--information--item">
                                    <span className="caption">Account number:</span>
                                    <span className="value">{targetBankData.accountNumber}</span>
                                </div>
{/*                                <div className="payment--information--item">
                                    <span className="caption">Reference:</span>
                                    <span className="value">N/A</span>
                                </div>*/}
                                <p className="margin-top-bottom-20">Once this is done take a screenshot of the receipt and attach it below:</p>
                                <FileInput id="selected-file" fileSelect={handleSelect} error={selectedFile.error!}/>
                                <p style={{color: 'var(--dark-red)'}}>
                                    Please note that if your funds do not appear in our account instantly, 
                                    we will send chips at our own discretion no matter how much proof you can provide.
                                </p>
                                <Button text="Submit" size="small" margin="margin-top-20"/>
                            </div>
                        </div>
                    </form>
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [linkedAccounts]})
)(PurchaseChips)
import React, {useContext, useState, useRef} from 'react';
import './InGameAccountsAdd.scss';
import InGameAccountsForm from '../in-game-accounts-elements/InGameAccountsForm';

import {storeSetLinkedAccounts} from 'auxiliary/dispatch';
import {linkedAccounts} from 'auxiliary/state';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import axios from 'auxiliary/axios';

import {FormParams} from '../in-game-accounts-elements/InGameAccountsForm';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import {ModalContext} from 'providers/ModalProvider';

interface Props {
    storeSetLinkedAccounts(params: Array<any>): void;
    linkedAccounts: Array<any>;
}

function InGameAccountsAdd(props: Props) {
    const snackbar = useContext(SnackbarContext);
    const modal = useContext(ModalContext);
    const componentRef = useRef<HTMLDivElement>(null!);
    const [buttonLoading, setButtonLoading] = useState(false);
    const submitForm = async (result: FormParams) => {
        const data = {
            game_id: result.gameID,
            username: result.username
        }
        try {
            setButtonLoading(true);
            const response = await axios.post('/account/linked_accounts', data);
            setTimeout(() => {
                props.storeSetLinkedAccounts(
                    props.linkedAccounts.concat(
                        {
                            id: response.data.id,
                            game_id: response.data.game_id,
                            username: response.data.username
                        }
                    )
                )
                modal.hide();
                snackbar.show({text: 'Account successfully added'});
            }, 1000)
        } catch(error) {
            console.log(error.response)
        } finally {
            setTimeout(() => {
                if (componentRef.current) setButtonLoading(false);
            }, 1000)
        }
    }

    return (
        <div className="InGameAccountsAdd" ref={componentRef}>
            <InGameAccountsForm formSubmitHandler={submitForm} loading={buttonLoading} />
        </div>
    )
}

export default compose(
    withStoreConnection({dispatchProps: [storeSetLinkedAccounts], stateProps: [linkedAccounts]})
)(InGameAccountsAdd)
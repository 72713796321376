import {ActionInterface} from './interfaces';

type LinkedAccounts = Array<any>;

const initialState: LinkedAccounts = [];

const actions = {
    SET_LINKED_ACCOUNTS: 'SET_LINKED_ACCOUNTS',
    REMOVE_LINKED_ACCOUNTS: 'REMOVE_LINKED_ACCOUNTS'
}

function reducer(state = initialState, action: ActionInterface<Array<any>>) {
    switch(action.type) {
        case actions.SET_LINKED_ACCOUNTS:
            return [
                ...action.payload
            ];
        case actions.REMOVE_LINKED_ACCOUNTS:
            return [];
        default: {
            return state;
        }
    }
}

export default reducer;
import React, {useRef, useContext, useState} from 'react';
import './MemberNoteForm.scss';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';
import {Note} from '../OwnerMembersViewMember';
import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
interface Props {
    noteRequestType: 'add' | 'edit';
    note?: Note;
    callback(note: Note): void;
    memberID: string;
}

function MemberNoteForm({note, callback, noteRequestType, memberID}: Props) {
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);
    const textAreaRef = useRef<HTMLTextAreaElement>(null!);
    const [buttonLoading, setButtonLoading] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null!);
    const clickHandler = async () => {
        switch(noteRequestType) {
            case 'add': {
                    setButtonLoading(true);
                    const response = await axios.post(`/members/${memberID}/player_notes`, {notes: textAreaRef.current.value});
                    setTimeout(() => {
                        snackbar.show({text: 'The note has been added'});
                        callback(response.data);
                    }, 2000);
                    setTimeout(() => {
                        setButtonLoading(false);
                    }, 2400)
                }
                break;
            case 'edit': 
                    try {
                        setButtonLoading(true);
                        const response = await axios.patch(
                            `/members/${memberID}/player_notes/${note?.id}`,
                            {notes: textAreaRef.current.value}
                        );
                        setTimeout(() => {
                            snackbar.show({text: 'The note has been edited'});
                            callback(response.data);
                        }, 2000)
                        setTimeout(() => {
                            setButtonLoading(false);
                        }, 2400)
                    } catch(error) {
                        modal.hide();
                        setTimeout(() => {
                            modal.setData({
                                header: 'Error',
                                content: {
                                    template: 'error',
                                    text: 'There was an error editing the note.'
                                }                            
                             });
                             modal.show();
                        }, 500) ;
                    }
                break;
            default: 
                throw new Error(`${noteRequestType} is not a valid note request type`);
        }
    }

    return (
        <div className="MemberNoteForm" ref={componentRef}>
            <div className="content">
                <textarea
                    key={note?.id}
                    className="margin-top-10 padding-10"
                    ref={textAreaRef}
                    defaultValue={note ? note.notes : ''}>                        
                </textarea>

                <Button
                    text="Submit"
                    backgroundColor="var(--accent-one-shade-two)"
                    width="80px"
                    clickHandler={clickHandler}
                    margin="margin-top-10"
                    loading={buttonLoading}
                />
            </div>
        </div>
    )
}

export default MemberNoteForm;
import React, {useRef, useState, useEffect} from 'react';
import './OwnerSettings.scss';

import {compose} from 'redux';
import {user} from 'auxiliary/state';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import withStoreConnection from 'hoc/withStoreConnection';

import {NavLink, Switch, Route, useLocation} from 'react-router-dom';

import PasswordSettings from './PasswordSettings/PasswordSettings';
import ReferralSettings from './ReferralSettings/ReferralSettings';
import ClubSettings from './ClubSettings/ClubSettings';

import {
    Security as SecurityIcon,
    AccountTree as AccountTreeIcon,
    Notes as NotesIcon
} from '@material-ui/icons';

interface Props {
    user: UserStateInterface;
}

function OwnerSettings({user}: Props) {
    const [navigatorShown, setNavigatorShown] = useState(false);
    const overlayRef = useRef<HTMLDivElement>(null!);
    const navigatorRef = useRef<HTMLElement>(null!);
    const location = useLocation();

    const overlayClickHandler = () => {
        setNavigatorShown(false);
    }

    const mobileHeaderHandler = () => {
        setNavigatorShown(true);
    }

    useEffect(function toggleNavigator() {
        if (navigatorShown) {
            overlayRef.current.style.cssText = 'z-index: 5; background-color: rgba(0, 0, 0, 0.2)';
            navigatorRef.current.style.cssText = 'left: 0';
        } else {
            overlayRef.current.style.cssText = 'z-index: -1; background-color: transparent;';
            navigatorRef.current.style.cssText = 'left: -300px';
        }
    }, [navigatorShown])

    useEffect(function locationMonitor() {
        setNavigatorShown(false);
    }, [location.pathname])

    return (
        <div className="OwnerSettings">
            <div className="overlay" onClick={overlayClickHandler} ref={overlayRef}></div>
            <div className="mobile--header">
                <button onClick={mobileHeaderHandler}>
                    <NotesIcon height={30} />
                    <span>Settings</span>
                </button>
            </div>
            <div className="content">
                <section className="navigation--section" ref={navigatorRef}>
                    <div className="owner--name">{`${user.firstName} ${user.lastName}`}</div>
                    <div className="owner--label">Club Owner</div>

                    <div className="contact--information">
                        <div className="key--value--pair">
                            <div className="key">Email:</div>
                            <div className="value">{user.email}</div>
                        </div>
                        <div className="key--value--pair">
                            <div className="key">Number:</div>
                            <div className="value">{user.mobileNumber}</div>
                        </div>

                        <div className="key--value--pair">
                            <div className="key">Referral Code:</div>
                            <div className="value">{user.referralCode}</div>
                        </div>
                    </div>

                    <ul className="navigator">
                        <li>
                            <NavLink exact to="/club/settings">
                                <SecurityIcon />
                                <span>Password Settings</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink exact to="/club/settings/general">
                                <SecurityIcon />
                                <span>Club Settings</span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/club/settings/referral-settings">
                                <AccountTreeIcon />
                                <span>Referral Settings</span>
                            </NavLink>
                        </li>
                    </ul>
                </section>
                <section className="navigation--content">
                    <Switch>
                        <Route exact path="/club/settings" component={PasswordSettings} />
                        <Route exact path="/club/settings/general" component={ClubSettings} />
                        <Route path="/club/settings/referral-settings" component={ReferralSettings} />
                    </Switch>
                </section>
            </div>
        </div>
    )
}


export default compose(
    withStoreConnection({stateProps: [user]})
)(OwnerSettings);
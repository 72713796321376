import React from 'react';
import './OwnerMembers.scss';

import {Switch, Route} from 'react-router-dom';
import OwnerMembersList from './OwnerMembersList/OwnerMembersList';
import OwnerMembersViewMember from './OwnerMembersViewMember/OwnerMembersViewMember';

interface Props {
    retrieveMembers(params: string): void
}

function OwnerMembers(props: Props) {
    return (
        <div className="OwnerMembers" style={{height: 'inherit'}}>
            <Switch>
                <Route exact path="/club/members" render={() => <OwnerMembersList retrieveMembers={props.retrieveMembers} />} />
                <Route exact path="/club/members/profile/:member_id" component={OwnerMembersViewMember} />
            </Switch>
        </div>
    )
}

export default OwnerMembers;
import React, {useContext} from 'react';
import './MembershipPrompt.scss';
import {ModalContext} from 'providers/ModalProvider';
import JoinClub from '../JoinClub/JoinClub';

interface Props {
    email: string;
    password: string;
}

function MembershipPrompt({email, password}: Props) {
    const modal = useContext(ModalContext);

    const cancelHandler = () => {
        modal.hide();
    }

    const confirmHandler = () => {
        modal.hide();
        setTimeout(() => {
            modal.setData({
                header: 'Membership Details',
                content: <JoinClub email={email} password={password} />
            })
            modal.show();
        }, 400)
    }

    return (
        <div className="MembershipPrompt">
            <div className="modal--data--content">
                <p>You aren't a member of this club yet. Would you like to join?</p>
                <div className="confirmation--buttons margin-top-20">
                    <button className="cancel--button button ripple" onClick={cancelHandler}>Cancel</button>
                    <button className="confirm--button button ripple" onClick={confirmHandler}>Yes</button>
                </div>
            </div>
        </div>
    )
}

export default MembershipPrompt;
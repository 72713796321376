import React from 'react';
import './Heading.scss';

interface Props {
    alignment: 'left' | 'center';
    color?: string;
    fontSize?: string;
    level: 'one' | 'two';
    margin?: string;
    text: string;
}

function Heading(props: Props) {
    const headingClass = ['Heading', props.alignment, 'level-' + props.level, props.margin];
    return (
        <h1 className={headingClass.join(' ')} style={{
            color: props.color,
            fontSize: props.fontSize
        }}>{props.text}</h1>
    )
}

export default Heading;
import React, {useEffect} from 'react';
import './Error.scss';
import {Link} from 'react-router-dom';

import LogOut from 'elements/LogOut/LogOut';

function Error(props: any) {
    const message = {
        notFound: "The page you're looking for does not exist."
    }

    useEffect(() => {
        let timeout: number;
        if (props.location.state && props.location.state.errorCode === 403) {
            timeout = window.setTimeout(() => {
                if (props.location.state.externalRedirectLink) {
                    window.location = props.location.state.externalRedirectLink
                }
            }, 5000);
        }
        return () => {
            window.clearTimeout(timeout);
        }
    }, [props.location]);

    return (
        <div className="Error">
            <div className="error--code">{props.location.state ? props.location.state.errorCode || 404 : 404}</div>
            <div className="error--message">{props.location.state ? props.location.state.errorMessage || message.notFound : message.notFound}</div>
            {props.location.state ? props.location.state.externalRedirectLink ? <a className="margin-top-20" href={props.location.state.externalRedirectLink.toString()}>
                {props.location.state.externalRedirectLink}
            </a> : null : null }
            {props.location.state ? props.location.state.redirectAutomatically ? "You will be redirected shortly." : null : null}

            <Link to="/" className="margin-top-20">Go back</Link>
            <div className="log--out--button margin-top-20">
                <LogOut>Log Out</LogOut>
            </div>
        </div>
    )
}

export default Error;
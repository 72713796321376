import React from 'react';
import './FormHeader.scss';

import {CircularProgress} from '@material-ui/core';

interface Props {
    buttonLoading: boolean;
    label: string;
    description: string;
}

function FormHeader({buttonLoading, label, description}: Props) {
    return (
        <div className="FormHeader">
            <div className="header" style={{width: "84%"}}>
                <div className="label" style={{margin: "0 0 5px 0"}}>{label} </div>
                <div className="description">{description}</div>
            </div>

            <button
                className="save--button"
                data-loading={buttonLoading}
                disabled={buttonLoading}>
                {
                    buttonLoading ? <CircularProgress size={18} /> : <span>Save Changes</span>
                }
            </button>
        </div>
    )
}

export default FormHeader;
import React, {useCallback} from 'react';
import axios from 'auxiliary/axios';

interface Props {
    accessToken: string | null;
    clientID: string | null;
    uid: string | null;
}

function withTokenValidation(Component: any) {
    return function EnhancedComponent(props: Props) {
        const validateToken = useCallback(async (callback = undefined, errorCallback = undefined) => {
            try {
                await axios.get('/validate_token');
                return callback ? callback() : true;
            }
            catch (error) {
                console.log(error.response);
                return errorCallback ? errorCallback() : false;
            }
        }, []);
        return (
            <Component validateToken={validateToken} {...props} />
        )
    }
}

export default withTokenValidation;
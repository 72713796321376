import React, {useState, useContext, useEffect, useCallback} from 'react';
import './LogIn.scss';
import CheckBox from 'elements/CheckBox/CheckBox';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Heading from 'elements/Heading/Heading';
import Button from 'elements/Button/Button';
import {Link} from 'react-router-dom';
import axios from 'auxiliary/axios';
import {useHistory} from 'react-router-dom';
import {compose} from 'redux';
import withTokenValidation from 'hoc/withTokenValidation';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeSetToken, storeSetUser} from 'auxiliary/dispatch';
import {user, pageConfiguration} from 'auxiliary/state';
import Notice from 'elements/Notice/Notice';
import MembershipPrompt from './MembershipPrompt/MembershipPrompt';

import {ModalContext} from 'providers/ModalProvider';

import {LoadingScreenContext} from 'elements/LoadingScreen/LoadingScreenProvider';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

interface Props {
    location: {
        state: {
            message: string;
        }
    }
    pageConfiguration: PageConfigurationState;
    validateToken(params: Function): Promise<boolean>;
    storeUserInformation(params: {data: UserStateInterface}): void;
    user: UserStateInterface;
}

function LogIn(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [email, setEmail] = useState({...initialState});
    const [password, setPassword] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const history = useHistory();
    const loadingScreen = useContext(LoadingScreenContext);
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);

    const callback = useCallback(
        () => {
            props.user.role === 'member' ? history.push('/member') : history.push('/club')
        }, [props.user.role, history]
    );
    const validateToken = props.validateToken;
    
    useEffect(() => {
        validateToken(callback);
    }, [validateToken, callback]);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error,
        }
        
        switch (result.origin) {
            case 'email':
                    setEmail(newState);
                break;
            case 'password':
                    setPassword(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    };

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        const data = {email: email.value, password: password.value};
        
        try {
            setButtonLoading(true);
            const response: {data: UserStateInterface} = await axios.post('/members/sign_in', data);

            if (!['member', 'owner'].includes(response.data.role)) {
                history.push('/error-page', 
                {
                    errorCode: 403, 
                    errorMessage: "You cannot view this page, please sign in via ",
                    externalRedirectLink: "http://dashboard.admindesk.club",
                    redirectAutomatically: true
                });
            } else {
                loadingScreen.show();
                setTimeout(() => {
                    loadingScreen.hide();
                    props.storeUserInformation(response);
                    response.data.role === 'member' ? history.push('/member') : history.push('/club');
                    snackbar.show({text: 'Logged-in successfully'});
                }, 3000)
            }
        }
        catch (error) {
            setTimeout(() => {
                setButtonLoading(false);
                setTimeStamp(Date.now());
                if (error.response.status === 403) {
                    modal.setData({
                        header: 'Confirm',
                        content: <MembershipPrompt email={email.value} password={password.value} />
                    });
                    modal.show();
                    setEmail({...email, valid: false, error: 'You are not a member of this club'});
                } else {
                    setEmail({...email, valid: false, error: 'Email or password is incorrect'});
                }
            }, 2000)
        }
    }

    return (
        <div className="LogIn">
            <div className="login--window padding-top-bottom-40 padding-left-right-20">
                {props.location.state ? <Notice text={props.location.state.message} margin="margin-bottom-20" /> : null}
                <img src={props.pageConfiguration.header.logo as string} style={{height: '70px'}} alt="logo" className="margin-bottom-20"/>
                <Heading
                    level="two"
                    alignment="center"
                    text="Log in to your account"
                    margin="margin-bottom-20"
                    color="var(--accent-four-shade-two)" 
                />
                <form onSubmit={submitForm}>
                    <InputGroup
                        autofocus={true}
                        change={handleChange}
                        error={email.error}
                        icon="ti-email"
                        id="email"
                        placeholder="Email"
                        type="text"
                        margin="margin-top-30"
                        valid={email.valid}
                        validatedProps={{email: true}}
                        timeStamp={timeStamp}
                    />

                    <InputGroup
                        change={handleChange}
                        icon="ti-lock"
                        id="password"
                        placeholder="Password"
                        type="password"
                        margin="margin-top-20"
                        validatedProps={{}}
                    />
                    <div className="block margin-top-20">
                        <CheckBox id="remember-me" text="Remember me" />
                        <Link to="/forgot-password" className="forgot--password--link">Forgot your password?</Link>
                    </div>

                    <Button
                        margin="margin-top-10"
                        text="Log In"
                        buttonType="block"
                        loading={buttonLoading}
                    />
                </form>

                <div className="block text-align-center margin-top-20">
                    <span className="no--account--text">Don&apos;t have an account yet?</span>
                    <Link to="/signup" className="forgot--password--link">Sign Up</Link>
                </div>
            </div>
        </div>
    )
}

export default compose(
    withStoreConnection({dispatchProps: [storeSetUser, storeSetToken], stateProps: [user, pageConfiguration]}),
    withTokenValidation,
    withUserInformationStorage
)(LogIn);

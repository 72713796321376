import React, {useState, useContext, useRef} from 'react';
import './ProfileInformation.scss';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import MobileNumberInput from 'elements/MobileNumberInput/MobileNumberInput';
import Button from 'elements/Button/Button';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import {user} from 'auxiliary/state';
import {storeSetUser, storeSetToken} from 'auxiliary/dispatch';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

import axios from 'auxiliary/axios';

interface Props {
    user: UserStateInterface;
    storeUserInformation(params: UserStateInterface): void;
}

function ProfileInformation({user, storeUserInformation}: Props) {
    const initialState = {value: '', valid: true, error: ''};
    const [buttonLoading, setButtonLoading] = useState(false);

    const [email, setEmail] = useState({...initialState, value: user.email});
    const [firstName, setFirstName] = useState({...initialState, value: user.firstName});
    const [lastName, setLastName] = useState({...initialState, value: user.lastName});
    const [mobileNumber, setMobileNumber] = useState({...initialState, value: user.mobileNumber});
    const [timeStamp, setTimeStamp] = useState(0);

    const componentRef = useRef<HTMLDivElement>(null!);
    const snackbar = useContext(SnackbarContext);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        switch (result.origin) {
            case 'email':
                    setEmail(newState);
                break;
            case 'first-name':
                    setFirstName(newState);
                break;
            case 'last-name':
                    setLastName(newState);
                break;
            case 'mobile-number':
                    setMobileNumber(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        setEmail({...email, error: email.valid ? '' : 'Email is invalid'});
        setFirstName({...firstName, error: firstName.valid ? '' : firstName.error || 'First Name is invalid'});
        setLastName({...lastName, error: lastName.valid ? '' : lastName.error || 'Last Name is invalid'});
        setMobileNumber({...mobileNumber, error: mobileNumber.valid ? '' : mobileNumber.error || 'The number is invalid.'});

        const result = [
            email,
            firstName,
            lastName,
            mobileNumber
        ].map(e => e.valid);
        
        if (result.every(valid => valid)) {
            const data = {
                email: email.value,
                first_name: firstName.value,
                last_name: lastName.value,
                mobile_number: mobileNumber.value
            };
            try {
                setButtonLoading(true);
                const response: UserStateInterface = await axios.patch('/account', data);
                setTimeout(() => snackbar.show({text: 'Successfully updated profile information.'}), 1000);
                storeUserInformation(response);
            } catch(error) {
                const emailError = /index_users_on_email/;
                if (emailError.test(error.response.data.error)) setEmail({...email, valid: false, error: 'Email is already taken'});
            } finally {
                setTimeout(() => {
                    if (componentRef.current) setButtonLoading(false);
                }, 1000)
            }
        }
    }

    return (
        <div className="ProfileInformation" ref={componentRef}>
            <Heading text="Member Information" level="two" alignment="center" />
            <form onSubmit={submitForm} id="profile-form">

                <div className="input--group--container margin-top-20">
                    <span className="input--group--icon--label">Email</span>
                    <InputGroup
                        autofocus={true}
                        change={handleChange}
                        error={email.error}
                        icon="ti-email"
                        id="email"
                        placeholder="Email"
                        type="text"
                        valid={email.valid}
                        validatedProps={{email: true}}
                        timeStamp={timeStamp}
                        initialValue={email.value!}
                    />
                </div>

                <div className="input--group--container">
                    <span className="input--group--icon--label">First Name</span>
                    <InputGroup
                        change={handleChange}
                        error={firstName.error}
                        icon="ti-user"
                        id="first-name"
                        placeholder="First Name"
                        type="text"
                        valid={firstName.valid}
                        validatedProps={{english: true, minLength: 2}}
                        timeStamp={timeStamp}
                        initialValue={firstName.value!}
                    />
                </div>

                <div className="input--group--container">
                    <span className="input--group--icon--label">Last Name</span>
                    <InputGroup
                        change={handleChange}
                        error={lastName.error}
                        icon="ti-notepad"
                        id="last-name"
                        placeholder="Last Name"
                        type="text"
                        valid={lastName.valid}
                        validatedProps={{english: true, minLength: 2}}
                        timeStamp={timeStamp}
                        initialValue={lastName.value!}
                    />
                </div>
                
                <div className="input--group--container">
                    <span className="input--group--icon--label">Mobile Number</span>
                    <MobileNumberInput 
                        change={handleChange}
                        error={mobileNumber.error}
                        id="mobile-number"
                        timeStamp={timeStamp}
                        valid={mobileNumber.valid}
                        value={mobileNumber.value!}
                    />
                </div>
                
                <Button text="Update" width="80px" margin="margin-top-20" loading={buttonLoading} />
            </form>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetUser, storeSetToken]}),
    withUserInformationStorage
)(ProfileInformation)
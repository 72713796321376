import React, {useState, useEffect} from 'react';

import Table from 'elements/Table/Table';
import axios from 'auxiliary/axios';
import {compose} from 'redux';
import {user} from 'auxiliary/state';
import {storeSetUser, storeSetToken} from 'auxiliary/dispatch';
import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import {UserStateInterface} from 'redux/reducers/user-reducer';


interface Props {
    user: UserStateInterface;
    storeUserInformation(params: {data: UserStateInterface}): void;
}

function Referrals({user, storeUserInformation}: Props) {

    const [requestLoading, setRequestLoading] = useState(true);
    const [referrals, setReferrals] = useState<any[]>([])

    useEffect(function getMemberships() {
        const init = async () => {
            const response = await axios.get('/account/referrals');
            console.log(response.data)
            setRequestLoading(false);
            setReferrals(response.data.referrals)
        }
        init();
    }, [])

    return (
        <div className="Referrals">
            <Table
                headers={
                    ['Game ID', 'Full Name', 'Email', 'Number', 'Referrer', 'Referral Date']
                }
                content={
                    referrals.length > 0 ?

                    referrals?.map(el => 
                        [
                            el.in_game_id || 'N/A',
                            `${el.name}`,
                            el.email,
                            el.mobile_number,
                            el.referrer,
                            el.created_at
                        ]
                    )
                    :
                    [['You have no referrals']]
                }
            />
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetUser, storeSetToken]}),
    withUserInformationStorage
)(Referrals);
import React, {useState} from 'react';
import './ForgotPassword.scss';
import Button from 'elements/Button/Button';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import {Link} from 'react-router-dom';
import axios from 'auxiliary/axios';

function ForgotPassword() {
    const initialState = {value: '', valid: false, error: ''};
    const [passwordResetConfirmed, setPasswordResetConfirmed] = useState(false);
    const [email, setEmail] = useState({...initialState});
    const [buttonLoading, setButtonLoading] = useState(false);

    const [timeStamp, setTimeStamp] = useState(0);

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());
        setEmail({...email, error: email.valid ? '' : email.error || 'Email is invalid'});

        const result = [
            email
        ].map(e => e.valid);

        if (result.every(valid => valid)) {
            try {
                setButtonLoading(true);
                await axios.post('/account/request-password-reset', {email: email.value});
                setTimeout(() => {
                    setButtonLoading(false);
                    setPasswordResetConfirmed(true);
                }, 2000)

            } catch(error) {
                setTimeout(() => {
                    setButtonLoading(false);
                    const errorData = error.response.data
                    if (errorData.email) setEmail({...email, valid: false, error: errorData.email});
                }, 2000)
            }
        }
    }

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'email':
                    setEmail(newState);
                break;
            default:
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const resetPasswordForm = <React.Fragment>
        <p>Enter a valid email address below and we&apos;ll send you a password reset link.</p>
        <form onSubmit={submitForm}>
            <InputGroup
                autofocus={true}
                change={handleChange}
                error={email.error}
                icon="ti-email"
                id="email"
                placeholder="Email"
                type="text"
                margin="margin-top-30"
                valid={email.valid}
                validatedProps={{email: true}}
                timeStamp={timeStamp}
            />

            <Button
                margin="margin-top-20"
                text="Reset Password"
                size="medium"
                loading={buttonLoading}
                width="135px"
            />
        </form>
    </React.Fragment>

    const resetPasswordConfirmation = <div className="password--confirmation margin-bottom-40">
        Check your inbox for further instructions. If you don&apos;t receive an email, it could be in your spam folder.
    </div>

    return (
        <div className="ForgotPassword">
            <div className="login--window forgot--password--window">
                <Heading level="two" alignment="center" text="Reset Password" margin="margin-bottom-40" />
                    {passwordResetConfirmed ? resetPasswordConfirmation : resetPasswordForm}
                <div className="margin-top-20 text-align-center links">
                    <Link to='/'>Log In</Link>
                    <span>or</span>
                    <Link to="/signup">Sign Up</Link>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword;
import React, {useEffect} from 'react';
import Container from 'elements/Container/Container';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import VerifyMobileNumber from './VerifyMobileNumber/VerifyMobileNumber';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';
import {useHistory} from 'react-router-dom';

import axios from 'auxiliary/axios';

import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    user: UserStateInterface;
}

function MemberVerification(props: Props) {
    const {path} = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        const retrieveUser = async () => {
            try {
                const response = await axios.get(`/members/${props.user.id}`);
                if (response.data.verified) {
                    history.push('/member');
                }
            } catch(error) {
                console.log(error.response)
            }
        }
        retrieveUser();
    }, [props.user, history])

    return (
        <div className="MemberVerification">
            <Container constrain={500}>
                <Switch>
                    <Route exact path={path} component={VerifyMobileNumber} />
                    <Route exact path={`${path}/:verification_code`} component={VerifyMobileNumber} />
                    <Route path="*" render={() => <Redirect to={path} />} />
                </Switch>
            </Container>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user]})
)(MemberVerification);
import React, {useEffect, useState, useContext} from 'react';
import './OwnerMembersViewMember.scss';
import Container from 'elements/Container/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'auxiliary/axios';
import chain from 'assets/images/chain.svg';
import network from 'assets/images/network.svg';
import controller from 'assets/images/controller.svg';
import Table from 'elements/Table/Table';
import note from 'assets/images/note.svg';
import IconButton from 'elements/IconButton/IconButton';
import {ModalContext} from 'providers/ModalProvider';
import DeleteNote from './DeleteNote/DeleteNote';

import MemberNoteForm from './MemberNoteForm/MemberNoteForm';
import {
    VerifiedUser as VerifiedUserIcon,
    Email as EmailIcon,
    PhoneAndroid as PhoneAndroidIcon,
    Block as BlockIcon,
    FlashOn as FlashOnIcon,
    CreditCard as CreditCardIcon,
    AccountBalance as AccountBalanceIcon,

    Edit as EditIcon,
    Delete as DeleteIcon,
    Transform as TransformIcon,
    ReportProblem as ReportProblemIcon,

    AddBox as AddBoxIcon 
} from '@material-ui/icons';

import {useHistory, useParams} from 'react-router-dom';

interface Member {
    blocked: boolean;
    blocked_on: null | string;
    created_at: string;
    email: string;
    name: string;
    first_name: string;
    id: number;
    last_name: string;
    mobile_number: null | string;
    role: string;
    verification_code: string;
    verified: boolean;
    verified_on: null | string;
    referral_code: string;

    transaction_history: {
        success: {
            total: number;
        }
        failed: {
            total: number;
        }
    }

    total_cash_outs: number;
    total_purchases: number;

    referrals: {
        refs: Array<Partial<Member>>;
    }

    referrer: null | {
        date_registered: string;
        email: string;
        first_name: string;
        last_name: string;
        mobile_number: string;
    }
}

function OwnerMembersViewMember() {
    const [template, setTemplate] = useState<JSX.Element>(<LoadingScreen />);
    const {member_id} = useParams();

    useEffect(function getMemberInformation() {
        const init = async () => {
            try {
                const result = await axios.get(`/members/${member_id}`);
                setTemplate(<MemberInformation member={result.data} member_id={member_id} />);
            } catch(error) {
                setTemplate(<MemberError />);
            }
        }
        init();
    }, [member_id]);

    return (
        <Container paddingOnly={true}>
            <div className="OwnerMembersViewMember">
                {template}
            </div>
        </Container>
    )
}


function LoadingScreen() {
    return (
        <div className="LoadingScreen">
            <CircularProgress />   
        </div>
    )
}

export interface Note {
    id: number;
    notes: string;
    created_at: string;
}

interface LinkedAccount {
    game_id: string;
    username: string;
}

interface Props {
    member: Member;
    member_id: string;
}

function MemberInformation({member, member_id}: Props) {
    const [linkedAccounts, setLinkedAccounts] = useState<Array<LinkedAccount>>([]);
    const [memberNotes, setMemberNotes] = useState<Array<Note>>([]);
    const [referrals, setReferrals] = useState<Array<Partial<Member>>>([]);
    const modal = useContext(ModalContext);

    const addNoteClickHandler = () => {
        modal.setData({
            header: 'Add New Note',
            content:(
                <div className="modal--data--content">
                    <MemberNoteForm 
                        noteRequestType="add"
                        memberID={member_id}
                        callback={(note: Note) => {
                            modal.hide();
                            setMemberNotes(previousNotes => [note, ...previousNotes]);
                        }}
                    />
                </div>
            )
        })
        modal.show();
    }

    const editNoteClickHandler = (note: Note) => {
        modal.setData({
            header: 'Edit Note',
            content: <div className="modal--data--content">
                <MemberNoteForm 
                    noteRequestType="edit"
                    memberID={member_id}
                    note={note}
                    callback={(note: Note) => {
                        modal.hide();
                        const notesClone = [...memberNotes];
                        const targetIndex = notesClone.findIndex((noteEl) => noteEl.id === note.id);
                        notesClone.splice(targetIndex, 1, note);
                        setMemberNotes([...notesClone]);
                    }}
                />
            </div>
        })
        modal.show();
    }

    const deleteNoteClickHandler = (note: Note) => {
        modal.setData({
            header: 'Delete Note',
            content: (
                <DeleteNote
                    memberID={member_id}
                    note={note}
                    callback={() => {
                        const notesClone = [...memberNotes];
                        const targetIndex = notesClone.findIndex((noteEl) => noteEl.id === note.id);
                        notesClone.splice(targetIndex, 1);
                        setMemberNotes([...notesClone]);
                    }}
                />
            )
        })
        modal.show();
    }

    useEffect(function getPlayerNotes() {
        const init = async () => {
            const response = await axios.get(`/members/${member_id}/player_notes?page=${1}`);
            setMemberNotes(response.data.notes);
        }
        init();
    }, [member_id])

    useEffect(function getReferrals() {
        const init = async () => {
            const response = await axios.get(`members/${member_id}/referrals`);
            setReferrals(response.data.referrals);
        }
        init();
    }, [member_id])

    useEffect(function getLinkedAccounts() {
        const init = async () => {
            const response = await axios.get(`/members/${member_id}/linked_accounts`);
            const {linked_accounts} = response.data;
            setLinkedAccounts(linked_accounts);
        }
        init();
    }, [member_id])

    return (
        <div className="MemberInformation">
            <div className="data--block">
                <div className="full--name">
                    {member?.first_name} {member?.last_name} <VerifiedUserIcon />
                </div>
                <div className="contact--information margin-top-10">
                    <div className="icon--data--pair">
                        <EmailIcon />
                        <span className="text">{member.email}</span>
                    </div>
                    <div className="icon--data--pair">
                        <PhoneAndroidIcon />
                        <span className="text">{member.mobile_number || 'N/A'}</span>
                    </div>
                    <div className="icon--data--pair">
                        {member.blocked ? <BlockIcon /> : <FlashOnIcon />}
                        <span className="text">{member.blocked ? 'Blocked' : 'Active'}</span>
                    </div>
                    <div className="icon--data--pair">
                        <span className="margin-right-5">Referral Code:</span>
                        <span>{member.referral_code}</span>
                    </div>
                </div>
                <div className="border"></div>
                {member.role === 'member' ? 
                    <>
                        <section className="transactions">
                            <div className="data--item">
                                <CreditCardIcon />
                                <div className="text">
                                    <div className="label">Total Purchases</div>
                                    <div className="description">{member.total_purchases}</div>
                                </div>
                            </div>

                            <div className="data--item">
                                <AccountBalanceIcon />
                                <div className="text">
                                    <div className="label">Total Withdrawals</div>
                                    <div className="description">{member.total_cash_outs}</div>
                                </div>
                            </div>

                            <div className="data--item">
                                <TransformIcon />
                                <div className="text">
                                    <div className="label">Sucessful Transactions</div>
                                    <div className="description">{member.transaction_history.success.total}</div>
                                </div>
                            </div>

                            <div className="data--item">
                                <ReportProblemIcon />
                                <div className="text">
                                    <div className="label">Failed Transactions</div>
                                    <div className="description">{member.transaction_history.failed.total}</div>
                                </div>
                            </div>
                        </section>
                        <section className="referrer">
                            <p className="title">Referrer Information <img src={chain} alt="chain"/></p>
                            {
                                member.referrer ? 
                                    <div className="details">
                                        <div className="data">
                                            <div className="label">Full Name</div>
                                            <div className="information">{member.referrer.first_name + ' ' + member.referrer.last_name}</div>
                                        </div>
                                        <div className="data">
                                            <div className="label">Email</div>
                                            <div className="information">{member.referrer.email}</div>
                                        </div>

                                        <div className="data">
                                            <div className="label">Mobile Number</div>
                                            <div className="information">{member.referrer.mobile_number}</div>
                                        </div>

                                        <div className="data">
                                            <div className="label">Date Registered</div>
                                            <div className="information">{member.referrer.date_registered + ' UTC'}</div>
                                        </div>
                                    </div> 
                                    :
                                    <p>This user has no referrer.</p>
                            }
                        </section>
                        <section className="linked--accounts">
                            <p className="title">In-game Accounts <img src={controller} alt="chain"/></p>
                            <div className="accounts--container">
                                {
                                    linkedAccounts.map((account, index) => (
                                        <div className="account" key={index}>
                                            <div className="key--value--pair">
                                                <div className="key">User ID: </div>
                                                <div className="value">{account.game_id}</div>
                                            </div>
                                            <div className="key--value--pair">
                                                <div className="key">Username: </div>
                                                <div className="value">{account.username}</div>
                                            </div>
                                        </div> 
                                    ))
                                }
                            </div>
                        </section>
                    </>
                    : ""
                }
            </div>

            <section className="referrals">
                <div className={`table--section ${member.role === 'member' ? "" : "full"}`} >
                    <Table
                        tableName={
                            <p className="table--name">Referrals <img src={network} alt="network"/></p>
                        }
                        headers={['Name', 'Email', 'Number', 'Referrer', 'Registered']}
                        content={
                            referrals.length > 0 ?
                                referrals.map(referral => (
                                    [
                                        referral.name,
                                        referral.email,
                                        referral.mobile_number,
                                        referral.referrer,
                                        referral.created_at
                                    ]
                                ))
                                :
                                [[<p>This member has no referrals.</p>]]
                        }
                    />
                </div>
                {member.role === "member" ? 
                    <div className="notes--section margin-bottom-20">
                        <Table
                            tableName={
                                <p className="title">
                                    Member Notes
                                    <img src={note} alt="Note"/>
                                    <IconButton 
                                        iconElement={<AddBoxIcon />}
                                        className="add--note--button"
                                        clickHandler={addNoteClickHandler}
                                    />
                                </p>
                            }
                            headers={['Note', 'Created', 'Actions']}
                            content={
                                memberNotes.length > 0 ?
                                    memberNotes.map(note => 
                                        [
                                            note.notes,
                                            note.created_at + ' UTC',
                                            <div className="action--buttons">
                                                <IconButton
                                                    iconElement={<EditIcon />}
                                                    className="edit--button"
                                                    clickHandler={() => editNoteClickHandler(note)}
                                                />
                                                <IconButton
                                                    iconElement={<DeleteIcon />}
                                                    className="delete--button"
                                                    clickHandler={() => deleteNoteClickHandler(note)}
                                                />
                                            </div>
                                        ]   
                                    )
                                    :
                                    [[<p>There are no notes for this player.</p>]]
                            }
                        />
                    </div>
                 : ""}
            </section>
        </div>
    )
}

function MemberError() {
    const history = useHistory();
    const clickHandler = () => {
        history.replace('/club/members');
    }

    return (
        <div className="MemberError">
            <p className="main--header">404</p>
            <p className="error--information margin-top-20">The Member's information could not be found</p>
            <button className="go--back--button ripple margin-top-20" onClick={clickHandler}>Go back</button>
        </div>
    )
}

export default OwnerMembersViewMember;
import React, {useRef, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Menu from 'elements/Menu/Menu';
import Logout from 'elements/LogOut/LogOut';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import withTokenValidation from 'hoc/withTokenValidation';
import {user, pageConfiguration} from 'auxiliary/state';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {useHistory} from 'react-router-dom';

import {LoadingScreenContext} from 'elements/LoadingScreen/LoadingScreenProvider';

import {
    Airplay as AirplayIcon,
    LockOpen as LockOpenIcon,
} from '@material-ui/icons';

interface Props {
    isMember: boolean;
    hasSessionTokens: boolean;
    editedNode: string;
    editMode: boolean;
    backgroundColor: string;
    fontColor: string;
    logo: string | File;
    organizationIdentifier: string;
    outline?: string;
    user: UserStateInterface;
    pageConfiguration: PageConfigurationState;
    validateToken(): Promise<boolean>;
}

function LandingPageHeader(props: Props) {
    console.log("props-landing page header: ",  props)
    const componentRef = useRef<HTMLImageElement>(null!);
    const loadingScreen = useContext(LoadingScreenContext);
    const history = useHistory();

    useEffect(() => {
        if (!componentRef.current) return;
        componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [props.editedNode])


    const menuOptions = [
        <Link key="0" to={`${props.editMode ? '#' : '/login'}`}>
            <div className="link--content">
                <LockOpenIcon /> 
                <span>Sign In</span>
            </div>
        </Link>,
        <Link key="1" to={`${props.editMode ? '#' : '/signup'}`}>
            <div className="link--content">
                <AirplayIcon /> 
                <span>Register</span>
            </div>
        </Link>
    ];
    
    const loggedInMenuOptions = [
        <Link key="0" to={`${props.editMode ? '#' : '/login'}`}>
            <div className="link--content">
                <span>Dashboard</span>
            </div>
        </Link>,
        <Link key="1" to={`${props.editMode ? '#' : '/signup'}`}>
            <div className="link--content">
                <Logout />
            </div>
        </Link>
    ];
    const outline = '3px dashed #FFEB3B';

    const dashboardClickHandler = async () => {
        if (props.editMode) return;
        loadingScreen.show();
        const tokenValid = await props.validateToken();
        if (tokenValid) {
            props.user.role === 'member' ? history.push('/member') : history.push('/club');
        } else {
            setTimeout(() => {
                history.push({
                    pathname: '/login'
                });
            }, 2000)
        }
        setTimeout(() => loadingScreen.hide(), 2000);
    }

    const signUpClickHandler = () => {
        if (props.editMode) return;
        if (history.location.pathname === '/signup') return;
        loadingScreen.show();
        setTimeout(() => {
            history.push('/signup');
            loadingScreen.hide();
        }, 2000)
    }

    return (
        <header className="landing--page--header padding-top-bottom-10" ref={props.editMode ? props.editedNode === 'header__backgroundColor' ? componentRef : null : null}
            style={{
                backgroundColor: props.backgroundColor,
                outline: `${props.editMode ? props.editedNode === 'header__backgroundColor' ? outline : '' : null}`,
                outlineOffset: "-3px"
            }}>
            <div className="content">
                <div className="club--identification" style={{color: props.pageConfiguration.header.fontColor}}>
                    <div className="club--name">
                        {props.pageConfiguration.organizationName}
                    </div>
                    <div className="club--information">
                        Club ID# {props.organizationIdentifier}
                    </div>
                    <div className="club--information">
                        Platform: {props.pageConfiguration.organizationGamePlatform?.name}
                    </div>
                </div>
                <img src={props.logo as string} alt="Logo" className="club--logo"
                    ref={props.editedNode === 'header__logo' ? componentRef : null}
                    style={{
                        outline: `${props.editMode ? props.editedNode === 'header__logo' ? outline : '' : null}`
                    }}
                />
                <div className="header--links padding">
                    <div className="standard--menu padding-right-10" ref={props.editedNode === 'header__fontColor' ? componentRef : null}
                        style={{
                            outline: `${props.editMode ? props.editedNode === 'header__fontColor' ? outline : '' : null}`,
                            color: props.fontColor
                        }}
                        >
                        <div className="links">
                            <button className="dashboard--button ripple" onClick={dashboardClickHandler}>
                                {
                                    !props.isMember && props.hasSessionTokens ? null : (
                                        props.user?.email || (props.isMember && props.hasSessionTokens) ? 
                                        <span style={{display: 'flex', alignItems: 'center'}}>
                                            <span className="margin-left-5">Dashboard</span>
                                        </span> 
                                        : 
                                        <span>Log In</span>
                                    )
                                }
                            </button>
                            {
                                props.isMember ? 
                                <Logout />
                                : (
                                  !props.hasSessionTokens ? <button className="dashboard--button ripple" onClick={signUpClickHandler}>Sign Up</button> : null
                                )
                            }
                        </div>
                    </div>
                    <div className="mobile--menu">
                        <Menu buttonBackgroundColor="#fff" menuOptions={(props.user?.email || props.isMember) ? loggedInMenuOptions : menuOptions} />
                    </div>
                </div>
            </div>
        </header>
    )
}

export default compose(
    withStoreConnection({stateProps: [user, pageConfiguration]}),
    withTokenValidation
)(LandingPageHeader);
import React, {useState, useContext, useRef} from 'react';
import './AccountSettings.scss';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';
import {usePasswordEqualityChecker} from 'hooks/usePasswordEqualityChecker';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

function AccountSettings() {
    const initialState = {value: '', valid: true, error: ''};
    const [currentPassword, setCurrentPassword] = useState({...initialState});
    const [newPassword, setNewPassword] = useState({...initialState, valid: false});
    const [passwordConfirmation, setPasswordConfirmation] = useState({...initialState, valid: false, error: ''});
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);

    const componentRef = useRef<HTMLDivElement>(null!);
    const snackbar = useContext(SnackbarContext);

    usePasswordEqualityChecker({
        password: newPassword,
        passwordConfirmation: passwordConfirmation,
        setPasswordConfirmation: setPasswordConfirmation
    });

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        switch (result.origin) {
            case 'current-password':
                    setCurrentPassword(newState);
                break;
            case 'new-password':
                    setNewPassword(newState);
                break;
            case 'password-confirmation':
                    setPasswordConfirmation(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        setCurrentPassword({...currentPassword, error: currentPassword.valid ? '' : currentPassword.error || 'Current password is invalid.'});
        setNewPassword({...newPassword, error: newPassword.valid ? '' : newPassword.error || 'New password is invalid'});
        setPasswordConfirmation({...passwordConfirmation, error: passwordConfirmation.valid ? '' : passwordConfirmation.error || 'Password Confirmation is invalid.'});

        const result = [
            currentPassword,
            newPassword,
            passwordConfirmation,
        ].map(e => e.valid);
        
        if (result.every(valid => valid)) {
            const data = {
                password: newPassword.value,
                current_password: currentPassword.value
            }
            try {
                setButtonLoading(true);
                await axios.patch('/account', data);
                setTimeout(() => snackbar.show({text: 'Successfully updated password'}), 1000);
            } catch(error) {
                const errorMessage = error.response.data.error;
                setCurrentPassword({...currentPassword, valid: false, error: errorMessage});
            } finally {
                setTimeout(() => {
                    if (componentRef.current) {
                        setButtonLoading(false);
                    };
                }, 1000);
            }
        }
    }

    return (
        <div className="AccountSettings" ref={componentRef}>
            <Heading text="Account Settings" level="two" alignment="center" />
            <form onSubmit={submitForm} id="account-settings-form">
                <div className="input--group--container margin-top-20">
                    <span className="input--group--icon--label">Current Password</span>
                    <InputGroup
                        change={handleChange}
                        error={currentPassword.error}
                        icon="ti-lock"
                        id="current-password"
                        type="password"
                        valid={currentPassword.valid!}
                        validatedProps={{minLength: 8}}
                        timeStamp={timeStamp}
                    />
                </div>

                <div className="input--group--container margin-top-20">
                    <span className="input--group--icon--label">New Password</span>
                    <InputGroup 
                        change={handleChange}
                        error={newPassword.error}
                        icon="ti-shield"
                        id="new-password"
                        placeholder="New Password"
                        type="password"
                        valid={newPassword.valid}
                        validatedProps={{minLength: 8}}
                        timeStamp={timeStamp}
                    />
                </div>
                
                <div className="input--group--container margin-top-20">    
                    <span className="input--group--icon--label">Confirm Password</span>
                    <InputGroup
                        change={handleChange}
                        error={passwordConfirmation.error}
                        icon="ti-shield"
                        id="password-confirmation"
                        placeholder="Confirm Password"
                        type="password"
                        valid={passwordConfirmation.valid}
                        validatedProps={{}}
                        timeStamp={timeStamp}
                    />
                </div>

                <Button text="Update" width="80px" margin="margin-top-20" loading={buttonLoading}/>
            </form>
        </div>
    )
}

export default AccountSettings;
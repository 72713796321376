import React, {useContext} from 'react';
import './OwnerHeader.scss';
import IconButton from 'elements/IconButton/IconButton';
import LogOut from 'elements/LogOut/LogOut';
import {OwnerMenuContext} from 'components/Owner/OwnerMenuProvider';
import {Link} from 'react-router-dom';

import {
    Menu as MenuIcon,
    Home as HomeIcon
} from '@material-ui/icons';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';
import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    user: UserStateInterface;
}

function OwnerHeader({user}: Props) {
    const context = useContext(OwnerMenuContext);

    const handleClick = () => {
        context.toggleMenu();
    }

    return (
        <header className="OwnerHeader padding-left-right-20 padding-top-bottom-15">
            <IconButton iconElement={<MenuIcon/>} margin="margin-right-10" clickHandler={handleClick} />
            <div className="header--text">
                <span className="label">Logged-in as:</span>
                <span>{`${user.firstName} ${user.lastName}`}</span>
            </div>
            <div className="button--container">
                <Link to="/">
                    <HomeIcon />
                    <span>Home</span>
                </Link>
                <LogOut />
            </div>
        </header>
    )
}

export default compose(
    withStoreConnection({stateProps: [user]})
)(OwnerHeader);
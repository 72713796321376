import React, {useEffect, useCallback, useState} from 'react';
import Table from 'elements/Table/Table';
import Container from 'elements/Container/Container';
import axios from 'auxiliary/axios';

import {
  MonetizationOn as MonetizationOnIcon,
  AttachMoney as AttachMoneyIcon
} from '@material-ui/icons';
import bankImage from 'assets/images/bank.png';
import deckImage from 'assets/images/deck.png';

interface TableData {
  purchase_requests: Array<any>;
  pagination: {
    pages: number;
    currentPage: number;
    totalCount: number;
    progressIndicatorVisible: boolean;
  };
}


function PurchaseRequests(props: any) {
  const [purchase_requests_data, setPurchaseRequestsData] = useState<TableData>({ purchase_requests: [], pagination: {pages: 0, currentPage: 0, totalCount: 0, progressIndicatorVisible: true} });
  const getPurchaseRequests = async (url: any) => {

    try {
        
      const response = await axios.get(url);
      const purchaseRequests = response.data.purchase_requests.map((el: any) => {
          return {
              id: el.id,
              referenceNumber: el.reference_number,
              createdAt: el.created_at,
              amount: el.amount,
              bankAccount: {
                  id: el.bank_account.id,
                  accountName: el.bank_account.account_name,
                  accountNumber: el.bank_account.account_number,
                  bankName: el.bank_account.bank_name
              },
              imageUrl: el.image_url,
              linkedAccount: {
                  id: el.linked_account.id,
                  gameID: el.linked_account.game_id,
                  username: el.linked_account.username
              },
              status: el.status
          }
      });
      
      setPurchaseRequestsData({
        purchase_requests: purchaseRequests, 
        pagination: {
          pages: response.data.pagination.pages, currentPage: response.data.pagination.current, totalCount: response.data.pagination.count, progressIndicatorVisible: false
        }});
    } catch(error) {
      console.log('owner purchase_requests retrieval failure')
    }
  }

  useEffect(() => {
    getPurchaseRequests('/account/purchase_requests');
  }, []);

  const previousPageClickHandler = () => {
    getPurchaseRequests(`/account/purchase_requests?page=${purchase_requests_data.pagination.currentPage - 1}`);
      
  }
  const nextPageClickHandler = () => {
    getPurchaseRequests(`/account/purchase_requests?page=${purchase_requests_data.pagination.currentPage + 1}`);
  }

  return (
    <Container paddingOnly={true}>
        <Table
        headers={['Reference #', 'Request Date', 'Amount', 'Status', 'Bank Account', 'Linked Account']}
        content={
            purchase_requests_data.purchase_requests.map((el, index) => [
                <div key={index} className="font-weight-600 ">{el.referenceNumber}</div>,
                el.createdAt,
                <div key={index} className="text--icon">
                    <div className="icon">
                        <AttachMoneyIcon />
                    </div>
                    <div className="text">
                        {el.amount}
                    </div>
                </div>,
                <div key={index} className={`status ${el.status}`}>
                    {el.status}
                </div>,
                <div key={index} className="text--image">
                    <img src={bankImage} alt="bank"/>
                    <div className="text">
                        <div>{el.bankAccount.accountName}</div>
                        <div>{el.bankAccount.accountNumber}</div>
                    </div>
                </div>,
                <div key={index} className="text--image">
                    <img src={deckImage} alt="deck"/>
                    <div className="text">
                        <div>{el.linkedAccount.gameID}</div>
                        <div>{el.linkedAccount.username}</div>
                    </div>
                </div>
            ])
        }
        pagination={{
            pages: purchase_requests_data.pagination.pages,
            currentPage: purchase_requests_data.pagination.currentPage,
            totalCount: purchase_requests_data.pagination.totalCount
        }}
        previousPageClickHandler={previousPageClickHandler}
        nextPageClickHandler={nextPageClickHandler}
        progressIndicatorVisible={purchase_requests_data.pagination.progressIndicatorVisible}
      />
    </Container>
  )
}
export default PurchaseRequests;
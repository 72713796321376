import React, {useState, useEffect, useRef, useContext, useCallback} from 'react';
import './OwnerPageEditor.scss';
import Container from 'elements/Container/Container';
import Tree, {seekTree, NodeClickResult} from 'elements/Tree/Tree';
import LandingPage from 'components/LandingPage/LandingPage';
import axios from 'auxiliary/axios';
import {SketchPicker} from 'react-color';
import Button from 'elements/Button/Button';
import {OwnerMenuContext} from 'components/Owner/OwnerMenuProvider';
import Loading from 'elements/Loading/Loading';
import Notice from 'elements/Notice/Notice';
import {quillModule} from 'auxiliary/quill-module';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {debounce} from 'lodash';

import {storeSetPageConfiguration as dispatchStoreSetPageConfiguration} from 'auxiliary/dispatch';
import {pageConfiguration as statePageConfiguration} from 'auxiliary/state';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';

import initialNodesState from './initialNodesState';

import {UserStateInterface} from 'redux/reducers/user-reducer';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';

import { 
    PhotoSizeSelectActual as PhotoSizeSelectActualIcon
} from '@material-ui/icons';

interface Props {
    user: UserStateInterface;
    pageConfiguration: PageConfigurationState;
    storeSetPageConfiguration(params: PageConfigurationState): void;
}

function OwnerPageEditor(props: Props) {

    const pageEditorRef = useRef<HTMLDivElement>(null!);
    const landingPageRef = useRef(null);
    const textEditorRef = useRef(null);
    const menuContext = useContext(OwnerMenuContext);

    const [nodes, setNodes] = useState([...initialNodesState]);
    
    const [previewHeight, setPreviewHeight] = useState(0);
    const [carouselItemWidthUpdate, setCarouselItemWidthUpdate] = useState(0);
    const [editedNode, setEditedNode] = useState<string>(null!);

    const [noticeState, setNoticeState] = useState('hidden');
    const [noticeText,  setNoticeText] = useState('');
    const [timeStamp, setTimeStamp] = useState(0);

    const [textEditorValue, setTextEditorValue] = useState('');
    const [sketchPickerColor, setSketchPickerColor] = useState('');

    const [loadingScreenVisible, setLoadingScreenVisible] = useState(false);

    const nodeClick = (result: NodeClickResult) => {
        const state = [...nodes];
        const activeNode = seekTree(state[0], result.path);
        activeNode.active = !activeNode.active
        setEditedNode(result.nodeName);
        setNodes(state);
    }

    const colorChange = (color: {hex: string}) => {
        setSketchPickerColor(color.hex);

        const {header} = props.pageConfiguration;
        switch(editedNode) {
            case 'header__backgroundColor':
                    header.backgroundColor = color.hex;
                break;
            case 'header__fontColor':
                    header.fontColor = color.hex;
                break;
            default:
        }

        props.storeSetPageConfiguration({
            ...props.pageConfiguration,
            header
        })
    }

    useEffect(() => {
        setPreviewHeight(pageEditorRef.current.getBoundingClientRect().height);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (landingPageRef.current) {
                setCarouselItemWidthUpdate(Date.now());
            }
        }, 300)
    }, [menuContext, props.pageConfiguration]);

    const {storeSetPageConfiguration, pageConfiguration} = props;

    useEffect(() => {
        if (!textEditorRef.current) return;
        if (!editedNode) return;
        const match = editedNode.match('detailsSection__item__text');
        if (editedNode !== 'gameImages__header' && !match) return;
        switch(editedNode) {
            case 'gameImages__header': {
                    const config = {...pageConfiguration};
                    setTextEditorValue(config.gameImages.header.content)
                }
                break;
            case (match ? match.input : false): {
                    const ar = editedNode.split('__');
                    const index = parseInt(ar[ar.length - 1]) - 1;
                    const clone = {...pageConfiguration.detailsSection}
                    setTextEditorValue(clone.detailItems[index].text)
                }
                break;
            default:
        }
    }, [editedNode, pageConfiguration, storeSetPageConfiguration]);
    
    const saveChanges = async () => {
        const {siteConfigurationID} = props.user.organization;
        const formData = new FormData();

        const appendFile = (key: string, array: Array<File>) => {
            array.forEach((file, index) => {
                formData.append(`${key}_${index}`, file);
            })
        }

        appendFile('carousel_image', [...props.pageConfiguration.carouselSection.images]);
        appendFile('game_images_image', [...props.pageConfiguration.gameImages.images]);
        appendFile('details_section__details_item__image', [...props.pageConfiguration.detailsSection.detailItems.map(el => el.image)]);

        formData.append("header_background_color", props.pageConfiguration.header.backgroundColor);
        formData.append("header_font_color", props.pageConfiguration.header.fontColor);
        formData.append("header_logo", props.pageConfiguration.header.logo)
        formData.append("game_images_header_content", props.pageConfiguration.gameImages.header.content);
        formData.append("element_order", [...props.pageConfiguration.elementOrder] as any);
        formData.append("page", "landing_page_configuration");
        formData.append("details_section_detail_items_text", JSON.stringify(props.pageConfiguration.detailsSection.detailItems.map(e => e.text)))
        
        setLoadingScreenVisible(true);

        try {
            await axios.patch(`/site_configuration/${siteConfigurationID}`, formData)
            setLoadingScreenVisible(false);
            setNoticeState('success');
            setNoticeText('Your changes have been saved successfully');
            setTimeStamp(Date.now())
        } catch(error) {
            setLoadingScreenVisible(false);
            console.log(error.response)
        }
    }

    const changeImages = async (event: React.ChangeEvent) => {
        switch (editedNode) {
            case 'carouselSection':
                    if ((event.target as HTMLInputElement).files!.length > 5) {
                        setNoticeState("error");
                        setNoticeText("Please select only up to 5 images.");
                        setTimeStamp(Date.now());
                        return;
                    }
                break;
            case 'gameImages__images--section':
                    if ((event.target as HTMLInputElement).files!.length > 9) {
                        setNoticeState("error");
                        setNoticeText("Please select only up to 9 images.");
                        setTimeStamp(Date.now());
                        return;
                    }
                break;
            default:
        }

        const filesArray: Array<File> = [];
        const promiseArray: Array<Promise<string>> = [];

        Array.prototype.forEach.call((event.target as HTMLInputElement).files, (file) => {
            filesArray.push(file);
            const promise: Promise<string> = new Promise((resolve) =>  {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(file);
                fileReader.onload = function() {
                    resolve(fileReader.result as string);
                }
            })
            promiseArray.push(promise);
        });

        const dataUrlArray = await Promise.all(promiseArray);
        const storeConfig = {...props.pageConfiguration}
        switch(editedNode) {
            case 'carouselSection':
                storeConfig.carouselSection.images = [...filesArray];
                storeConfig.carouselSectionEditMode.images = [...dataUrlArray];
            break;
            case 'gameImages__images--section':
                storeConfig.gameImages.images = [...filesArray];
                storeConfig.gameImagesEditMode.images = [...dataUrlArray];
            break;
            case 'header__logo':
                storeConfig.header.logo = [...filesArray][0];
                storeConfig.headerEditMode.logo = [...dataUrlArray][0];
            break;
            case editedNode.match('detailsSection__item__image')!.input: {
                const ar = editedNode.split('__');
                const index = parseInt(ar[ar.length - 1]) - 1;
                
                storeConfig.detailsSection.detailItems[index].image = [...filesArray][0];
                storeConfig.detailsSectionEditMode.detailItems[index].image = [...dataUrlArray][0];
            }
            break;
            default:
        }
        props.storeSetPageConfiguration(storeConfig);
    }

    const debouncedStateUpdate =  useCallback(debounce((config: PageConfigurationState) => props.storeSetPageConfiguration(config), 100), [])
    
    const changeTextEditorValue = (value: string) => {
        if(!textEditorRef.current) return;
        setTextEditorValue(value);
        
        if (!editedNode) return;
        const match = editedNode.match('detailsSection__item__text');
        const config = {...pageConfiguration};
        switch(editedNode) {
            case 'gameImages__header': 
                    config.gameImages.header.content = value;
                break;
            case (match ? match.input : false): {
                const ar = editedNode.split('__');
                // const index = parseInt(ar[ar.length - 1] - 1);
                const index = parseInt(ar[ar.length - 1]) - 1;
                config.detailsSection.detailItems[index].text = value;
                config.detailsSectionEditMode.detailItems[index].text = value;
            }
            break;
            default: 
        }
        debouncedStateUpdate(config)
    }

    return (
        <Container paddingOnly={true}>
            <div className="OwnerPageEditor" ref={pageEditorRef}>
                <div className="loading--view" style={{display: loadingScreenVisible ? 'flex' : 'none'}}>
                    <Loading size={50}/>
                    <p className="margin-top-10">Loading Please Wait</p>
                </div>
                <Notice margin="margin-bottom-10" text={noticeText} noticeState={noticeState} timeStamp={timeStamp}/>
                
                <div className="page--editor">
                    <div className="editor--tree">
                        <Tree nodes={nodes} nodeClick={nodeClick} />
                        <div className="editing--tool margin-top-10">
                            {['header__backgroundColor', 'header__fontColor'].find(el => editedNode ? editedNode.match(el) : false) ?
                                <SketchPicker
                                    width="280px" 
                                    color={sketchPickerColor}
                                    onChange={colorChange}
                                /> : null
                            }

                            {
                                [
                                    'carouselSection',
                                    'gameImages__images--section',
                                    'detailsSection__item__image__',
                                    'header__logo'
                                ].find(el => editedNode ? editedNode.match(el) : false) ? 
                                <label htmlFor="carousel--image--picker">
                                    <input type="file" id="carousel--image--picker" 
                                    multiple={!editedNode.match('detailsSection__item__image') && !editedNode.match('header__logo')} 
                                    onChange={changeImages}/>
                                    <div className="select--files padding-top-bottom-10 padding-left-right-15">
                                        <span>Select Images</span>
                                        <PhotoSizeSelectActualIcon />
                                    </div>
                                </label> : null
                            }

                            {
                                [
                                    'gameImages__header',
                                    'detailsSection__item__text'
                                ].find(el => editedNode ? editedNode.match(el) : false) ? 
                                <div >
                                    <ReactQuill theme="snow"
                                        ref={textEditorRef}
                                        style={{maxWidth: '300px'}} 
                                        value={textEditorValue}
                                        onChange={changeTextEditorValue}
                                        modules={quillModule}
                                    /> 
                                </div>: null
                            }

                            <Button
                                text="Save"
                                margin="margin-top-10"
                                buttonType="button"
                                clickHandler={saveChanges}
                                width="80px"
                            />

                            {
                                editedNode === 'carouselSection' ? 
                                    <div className="editing--notes">
                                        <p>
                                            Note: When customizing the carousel, select images that have the same dimensions. 
                                            For example: a set of 5 <strong>1280 pixels wide * 800 pixels high</strong> images.
                                        </p>
                                    </div>
                                    :
                                    null
                            }   
                            
                        </div>
                    </div>
                    <div className="page--preview" style={{height: previewHeight + 'px'}} ref={landingPageRef}>
                        <LandingPage
                            editedNode={editedNode}
                            editMode={true} 
                            carouselItemWidthUpdate={carouselItemWidthUpdate}
                        />
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [user, statePageConfiguration], dispatchProps: [dispatchStoreSetPageConfiguration]})
)(OwnerPageEditor);
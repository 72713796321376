export const landingPageConfiguration = {
    carouselSection: {
        "images": []
    },
    header: {
        backgroundColor: null,
        logo: '',
        fontColor: ''
    },
    gameImages: {
        header: {
            content: ""
        },
        images: []
    },
    detailsSection: {
        detailItems: []
    },
    elementOrder: [
        "header",
        "carouselSection",
        "gameImages",
        "detailsSection"
    ]
};

import React from 'react';
import './Owner.scss';
import Header from '../Header/Header';
import OwnerMenu from './OwnerMenu/OwnerMenu';
import OwnerContent from './OwnerContent/OwnerContent';

import OwnerMenuProvider from './OwnerMenuProvider';
import ModalProvider, {ModalContext} from 'providers/ModalProvider';
import Modal from 'elements/Modal/Modal';

function Owner() {
   return (
        <OwnerMenuProvider>
            <ModalProvider>
                <Modal 
                    context={ModalContext}
                />
                <Header />
                <div className="Owner padding-top-80">
                    <OwnerMenu />
                    <OwnerContent />
                </div>
            </ModalProvider>
        </OwnerMenuProvider>
    )
}

export default Owner;
import {useEffect, useState} from 'react';
import axios from 'auxiliary/axios';

function useSetPageConfiguration() {
    const [config, setConfig] = useState({});
    useEffect(() => {
        const setPageConfig = async function() {
            try {
                const response = await axios.get('/site_configuration/?page=landing_page_configuration');
                const {configuration, organization_identifier, organization_name} = response.data;
                setConfig({...configuration, organizationIdentifier: organization_identifier, organizationName: organization_name});
            } catch(error) {
                console.log(error.response);
            }
        }
        setPageConfig();
    }, [])
    return config
}
export default useSetPageConfiguration;
import React, {useEffect, useRef, useCallback, useState} from 'react';
import './LandingPageStyle.scss';
import Carousel from 'elements/Carousel/Carousel';
import LandingPageHeader from './components/LandingPageHeader';
import LandingPageGameImages from './components/LandingPageGameImages';
import LandingPageDetailsSection from './components/LandingPageDetailsSection';
import axios from 'auxiliary/axios';
import {storageAvailable} from 'auxiliary/storage-available';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import {compose} from 'redux';
import {pageConfiguration} from 'auxiliary/state';
import {user} from 'auxiliary/state';
import {storeSetPageConfiguration} from 'auxiliary/dispatch';
import withStoreConnection from 'hoc/withStoreConnection';
import withTokenValidation from 'hoc/withTokenValidation';

import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import Cookies from 'js-cookie';

interface Props {
    editedNode: string;
    carouselItemWidthUpdate: any;
    editMode: boolean;
    pageConfiguration: PageConfigurationState;
    storeSetPageConfiguration(params: PageConfigurationState): void;
    user: UserStateInterface;
    validateToken(): Promise<boolean>;
}

type indexSignature = {
    [key: string]: any
};

let hasSessionTokens = Cookies.get('access-token') !== undefined;
function LandingPage(props: Props) {
    const [isMember, setisMember] = useState(false);
    const refMap: indexSignature = {
        carouselSection: useRef<HTMLDivElement>(null!),
        header: useRef<any>(null!)
    }

    const {storeSetPageConfiguration, pageConfiguration} = props;
    const savePageConfiguration = useCallback((config) => {
        const callback = () => {
            const clone = {...config};
            [
                'carouselSectionEditMode', 'gameImagesEditMode', 
                'detailsSectionEditMode', 'headerEditMode'
            ].forEach(el => delete clone[el])
            localStorage.setItem('landingPageConfiguration', JSON.stringify(clone));
        }
        try {
            storageAvailable(callback)
        } catch(error) {
            console.log('Local storage quota has been exceeded')
        }
    }, [])

    useEffect(() => {
        const initializePageConfiguration = async function() {
            try {
                const response = await axios.get('/site_configuration/?page=landing_page_configuration');
                const {
                    configuration,
                    organization_identifier,
                    organization_name,
                    organization_game_platform,
                    strict_referrals
                } = response.data;
                const updatedConfiguration = {
                    ...configuration,
                    organizationIdentifier: organization_identifier,
                    organizationName: organization_name,
                    organizationGamePlatform: organization_game_platform,
                    organizationStrictReferrals: strict_referrals,
                    carouselSectionEditMode: {...configuration.carouselSection},
                    gameImagesEditMode: {...configuration.gameImages},
                    detailsSectionEditMode: JSON.parse(JSON.stringify(configuration.detailsSection)),
                    headerEditMode: {
                        logo: {...configuration.header}['logo']
                    }
                }
                savePageConfiguration(configuration);
                storeSetPageConfiguration(updatedConfiguration);
            } catch(error) {
                console.log(error)
                console.log(error.response);
            }
        }
        initializePageConfiguration();

        const checkMember = async function() {
          try {
              const response = await axios.get('/account');
              setisMember(response.status === 200)
              console.log(response)
          } catch(error) {
              console.log(error)
              console.log(error.response);
          }
      }
      checkMember();
    }, [])

    useEffect(() => {
        if (!props.editedNode) return;
        if (!refMap[props.editedNode]) return;
        if (refMap[props.editedNode].current) {
            refMap[props.editedNode].current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [props.editedNode, refMap]);


    const landingPageElements: indexSignature = {
        header: 
            <LandingPageHeader 
                editMode={props.editMode}
                editedNode={props.editedNode}
                backgroundColor={pageConfiguration.header.backgroundColor}
                fontColor={pageConfiguration.header.fontColor}
                logo={props.editMode ? pageConfiguration.headerEditMode.logo : pageConfiguration.header.logo}
                organizationIdentifier={pageConfiguration.organizationIdentifier}
                isMember={isMember}
                hasSessionTokens={hasSessionTokens}
                />,
        carouselSection: 
            <div ref={refMap.carouselSection}>
                <Carousel
                    styleAttribute={{
                            border:  `${props.editedNode === 'carouselSection' ? '3px dashed #FFEB3B' : ''}`,
                            outlineOffset: "-3px"
                    }}
                    carouselItemWidthUpdate={props.carouselItemWidthUpdate}
                    images={props.editMode ? pageConfiguration.carouselSectionEditMode.images : pageConfiguration.carouselSection.images}
                />
            </div>,
        gameImages: 
            <LandingPageGameImages
                editMode={props.editMode}
                editedNode={props.editedNode}
                gameImages={props.editMode ? pageConfiguration.gameImagesEditMode : pageConfiguration.gameImages}
                isMember={isMember}
                hasSessionTokens={hasSessionTokens}
            />,
        detailsSection:
            <LandingPageDetailsSection 
                editMode={props.editMode}
                editedNode={props.editedNode}
                detailsSection={props.editMode ? pageConfiguration.detailsSectionEditMode : pageConfiguration.detailsSection}
            />
    }
    
    const dragEnd = (result: any) => {
        const {destination, source} = result;
        if (!destination) return;
        const clone = {...pageConfiguration};
        const {elementOrder} = clone;
        const [removed] = elementOrder.splice(source.index, 1)
        elementOrder.splice(destination.index, 0, removed);
        clone.elementOrder = [...elementOrder]
        storeSetPageConfiguration({...clone});
    }

    return (
        <div className="LandingPage">
            {landingPageElements['header']}
            <DragDropContext onDragEnd={dragEnd}>
                <Droppable droppableId="1">
                    {(provided) => (
                        <div ref={provided.innerRef} className="drop--container">
                            {
                                pageConfiguration.elementOrder.map((elementName, index) => {
                                    return (
                                        elementName === 'header' ? null : 
                                        <Draggable draggableId={index.toString()} index={index} key={index} isDragDisabled={!props.editMode}>
                                            {(draggableProvided) => (
                                                <div className="drag--container"
                                                    ref={draggableProvided.innerRef}
                                                    {...draggableProvided.draggableProps}
                                                    {...draggableProvided.dragHandleProps}>
                                                    {landingPageElements[elementName]}
                                                </div>
                                            )}
                                        </Draggable> 
                                    )
                                })
                            }
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [pageConfiguration, user], dispatchProps: [storeSetPageConfiguration]}),
    withTokenValidation
)(LandingPage);
import React, {useState} from 'react';
import './TreeStyle.scss';

import { 
    SubdirectoryArrowRight as SubdirectoryArrowRightIcon
} from '@material-ui/icons';

type ChildNode = {
    collapseIcon: JSX.Element;
    expandIcon: JSX.Element;
    text: string;
    active: boolean;
    nodeName: string;
}

interface Node extends ChildNode {
    children?: Array<ChildNode>
}

interface Template extends Node {
    path: string;
    indexProp: number;
    innerIndex?: number;
    color?: string;
}

interface Props {
    nodeClick(params: NodeClickResult): void;
    nodes: Array<Node>
}

function Tree(props: Props) {
    const [activeNode, setActiveNode] = useState<string | null>(null);

    const clickHandler = (result: NodeClickResult) => {
        props.nodeClick(result)
    }

    function Template(el: Template) {
        const levelIndicator = el.indexProp + 10;
        return (
            <div
                className="tree--node"
                onClick={(e) => {
                    e.stopPropagation();
                    setActiveNode(el.nodeName)
                    clickHandler({nodeName: el.nodeName, innerIndex: el.innerIndex, path: el.path})}
                }
                style={{
                    color: el.color
                }}>
                    <div className={`node--label ${activeNode === el.nodeName ? 'active' : ''}`}>
                        {el.active ? el.expandIcon : el.collapseIcon}
                        <span className="node--text">{el.text}</span>
                    </div>
                    {
                        el.active ? 
                            <div className="node--children">
                                {
                                    el.children ? el.children.map((childElement, innerIndex) => {
                                        return (
                                            <div key={levelIndicator + innerIndex} className="node--label">
                                                <SubdirectoryArrowRightIcon className="sub--directory--icon margin-left-10" style={{color: 'var(--medium-grey)'}} />
                                                <Template path={el.path + '/' + childElement.nodeName} indexProp={levelIndicator + innerIndex} innerIndex={innerIndex} key={innerIndex} {...childElement} />
                                            </div>
                                        )
                                    }) : null
                                }
                            </div> : null
                    }
            </div>
        )
    }

    return (
        <div className="Tree padding-10">
            {props.nodes.map((el, index) => {
               return <Template path="" {...el} indexProp={index} key={index} />
            })}
        </div>
    )
}

export interface NodeClickResult {
    nodeName: string;
    innerIndex?: number;
    path: string;
}

export default Tree;
export function seekTree(tree: Node, path: string) {
    return path.split('/').reduce((acc, curr) => {
        const pointer = acc.children?.find(el => el.nodeName === curr);
        return pointer ? pointer : acc;
    }, tree);
}


import React from 'react';
import {storeSetToken, storeSetUser} from 'auxiliary/dispatch';
import Cookies from 'js-cookie';


function withUserInformationStorage(Component: any) {
    return function EnhancedComponent(props: any) {
        const storeUserInformation = (response: any) => {
            props[storeSetToken]({
                accessToken: response.headers['access-token'],
                clientID: response.headers['client'],
                uid: response.headers['uid']
            });
            console.log(response);
            props[storeSetUser]({
                id: response.data.id,
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                email: response.data.email,
                mobileNumber: response.data.mobile_number,
                role: response.data.role,
                verified: response.data.verified,
                referralCode: response.data.referral_code,
                organization: {
                    id: response.data.organization?.id,
                    name: response.data.organization?.name,
                    identifier: response.data.organization?.identifier,
                    ownerID: response.data.organization?.owner_id,
                    url: response.data.organization?.url,
                    siteConfigurationID: response.data.organization?.site_configuration_id,
                    clubTasks: response.data.organization?.club_tasks,
                    organizationGamePlatform: {
                        name: response.data.organization?.organization_game_platform?.name
                    }
                },
                referrals: response.data.referrals
            });
            const split_host = window.location.host.split(".");
            split_host.shift();
            const host = split_host.length === 1 ? "" : split_host.join(".");
            console.log(host)
            if (response.data.role === 'member'){
              Cookies.set('access-token', response.headers['access-token'], {domain: host})
              Cookies.set('client', response.headers['client'], {domain: host})
              Cookies.set('uid', response.headers['uid'], {domain: host})
            }else{
              Cookies.remove('access-token', {domain: host})
              Cookies.remove('client',  {domain: host})
              Cookies.remove('uid',  {domain: host})
            }
        };

        return (
            <Component storeUserInformation={storeUserInformation} {...props} />
        )
    }
}

export default withUserInformationStorage;
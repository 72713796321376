import React from 'react';
import './OwnerAccounts.scss';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {ownerBankAccounts} from 'auxiliary/state';
import {Switch, Route, Redirect, useRouteMatch} from 'react-router-dom';
import OwnerAccountsList from './OwnerAccountsList/OwnerAccountsList';
import Container from 'elements/Container/Container';

import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';

interface Props {
    retrieveBankAccounts(params: OwnerBankAccountsState): void; 
}

function OwnerAccounts(props: Props) {
    const {path} = useRouteMatch();
    return (
        <Container paddingOnly={true}>
            <Switch>
                <Route exact path={path} render={(routeProps) => <OwnerAccountsList {...routeProps} retrieveBankAccounts={props.retrieveBankAccounts} />} />
                <Route path="*" render={() => <Redirect to={path} />} />
            </Switch>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [ownerBankAccounts]})
)(OwnerAccounts);
import React from 'react';
import './MemberProfile.scss';
import TabElement from 'elements/TabElement/TabElement';
import {useRouteMatch} from 'react-router-dom';

import InGameAccounts from './InGameAccounts/InGameAccounts';
import AccountSettings from './AccountSettings/AccountSettings';
import ProfileInformation from './ProfleInformation/ProfileInformation';
import ClubMemberships from './ClubMemberships/ClubMemberships';
import Referrals from './Referrals/Referrals';

import {
    Person as PersonIcon,
    Settings as SettingsIcon,
    Smartphone as SmartphoneIcon,
    AccountTree as AccountTreeIcon,
    NaturePeople as NaturePeopleIcon
} from '@material-ui/icons';

function MemberProfile() {
    const {path} = useRouteMatch();

    const links = [
        {path: `${path}/information`, icon: <PersonIcon />, text: 'profile', element: <ProfileInformation />},
        {path: `${path}/account-settings`, icon: <SettingsIcon />, text: 'account settings', element: <AccountSettings />},
        {path: `${path}/in-game-accounts`, icon: <SmartphoneIcon />, text: 'In-Game Accounts', element: <InGameAccounts />},
        {path: `${path}/club-memberships`, icon: <AccountTreeIcon />, text: 'Club Memberships', element: <ClubMemberships />},
        {path: `${path}/referrals`, icon: <NaturePeopleIcon />, text: 'My Referrals', element: <Referrals />}
    ];

    return (
        <div className="MemberProfile" style={{minHeight: 'calc(100vh - 120px)'}}>
            <div className="member--profile--content">
                <div className="tab--element--container">
                    <TabElement navigators={links} contentWidthConstrain={1200}/>
                </div>
            </div>
        </div>
    );
}

export default MemberProfile;
import React, {useState, useContext, useRef} from 'react';
import './JoinClub.scss';
import Input, {ResultInterface} from 'elements/Input/Input';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';
import {useHistory} from 'react-router-dom';
import {storeSetToken, storeSetUser} from 'auxiliary/dispatch';
import {user} from 'auxiliary/state';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import {LoadingScreenContext} from 'elements/LoadingScreen/LoadingScreenProvider';
import {ModalContext} from 'providers/ModalProvider';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';

interface Props {
    storeUserInformation(params: {data: UserStateInterface}): void;
    user: UserStateInterface;
    email: string;
    password: string;
}

function JoinClub(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [inGameID, setInGameID] = useState({...initialState});
    const [inGameUsername, setInGameUsername] = useState({...initialState});
    const [referralCode, setReferralCode] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);

    const history = useHistory();
    const loadingScreen = useContext(LoadingScreenContext);
    const modal = useContext(ModalContext);
    const emailErrorRef = useRef<HTMLParagraphElement>(null!);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error,
        }
        
        switch (result.origin) {
            case 'in-game-id':
                    setInGameID(newState);
                break;
            case 'in-game-username':
                    setInGameUsername(newState);
                break;
            case 'referral-code':
                    setReferralCode(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    };

    const formSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        setInGameID({...inGameID, error: inGameID.valid ? '' : inGameID.error || 'Game ID is invalid'});
        setInGameUsername({...inGameUsername, error: inGameUsername.valid ? '' : inGameUsername.error || 'Username is invalid' });
        setReferralCode({...referralCode, error: referralCode.valid ? '' : referralCode.error || 'Referral code can\'t be blank' });

        const result = [
            inGameID,
            inGameUsername
        ].map(e => e.valid);

        if (result.every(valid => valid)) {
            try {
                loadingScreen.show();
                if (props.email === "") {
                    const data = {
                        in_game_id: inGameID.value,
                        in_game_username: inGameUsername.value,
                        referral_code: referralCode.value
                    }
                    console.log(data)
                    const response = await axios.post('/join', data);
                    setTimeout(() => {
                        modal.hide();
                        loadingScreen.hide();
                        props.storeUserInformation(response);
                        response.data.role === 'member' ? history.push('/member') : history.push('/club')
                    }, 1800)
                }else{
                    const data = {
                        email: props.email,
                        password: props.password,
                        in_game_id: inGameID.value,
                        in_game_username: inGameUsername.value,
                        referral_code: referralCode.value
                    }
                    console.log(data)
                    
                    const response = await axios.post('/members/memberships', data);
                    setTimeout(() => {
                        modal.hide();
                        loadingScreen.hide();
                        props.storeUserInformation(response);
                        response.data.role === 'member' ? history.push('/member') : history.push('/club')
                    }, 1800)
                }
            } catch(error) {
                console.log(error.response);
                emailErrorRef.current.style.opacity = '1';
                loadingScreen.hide();
            }
        }
    }

    return (
        <div className="JoinClub">
            <div className="modal--data--content">
                <form onSubmit={formSubmitHandler}>
                    <Input
                        id="in-game-id"
                        inputBorderColor="var(--accent-one-shade-three)"
                        placeholder="In-game ID"
                        changeCallback={handleChange}
                        valid={inGameID.valid}
                        validatedProps={{minLength: 2, numbersOnly: true, maxLength: 50}}
                        error={inGameID.error}
                        timeStamp={timeStamp}
                        value={inGameID.value}
                    />

                    <Input
                        id="in-game-username"
                        inputBorderColor="var(--accent-one-shade-three)"
                        placeholder="In-game Username"
                        changeCallback={handleChange}
                        valid={inGameUsername.valid}
                        validatedProps={{minLength: 2, maxLength: 50}}
                        error={inGameUsername.error}
                        timeStamp={timeStamp}
                        value={inGameUsername.value}
                    />

                    <Input
                        id="referral-code"
                        inputBorderColor="var(--accent-one-shade-three)"
                        placeholder="Referral Code"
                        changeCallback={handleChange}
                        valid={referralCode.valid}
                        error={referralCode.error}
                        timeStamp={timeStamp}
                        value={referralCode.value}
                    />

                    <p 
                        style={{color: 'var(--dark-red)', opacity: 0}}
                        ref={emailErrorRef}>
                            We're sorry but something went wrong. Please double-check your referral code.
                    </p>

                    <Button
                        text="Join"
                        width="80px"
                        margin="margin-top-10"
                    />
                </form>
            </div>
        </div>
    )
}

export default compose(
    withStoreConnection({dispatchProps: [storeSetUser, storeSetToken], stateProps: [user]}),
    withUserInformationStorage
)(JoinClub);
import React, {useEffect} from 'react';
import {Switch, Redirect, Route, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import LogIn from 'components/LogIn/LogIn';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import Member from 'components/Member/Member';

import MemberRegistration from 'components/MemberRegistration/MemberRegistration';
import MemberVerification from 'components/Member/MemberVerification/MemberVerification';
import MemberProfile from 'components/Member/MemberProfile/MemberProfile';
import ResetPassword from 'components/ResetPassword/ResetPassword';
import Error from 'components/Error/Error';
import LandingPage from 'components/LandingPage/LandingPage';
import ModalProvider, {ModalContext} from 'providers/ModalProvider';
import Modal from 'elements/Modal/Modal';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeSetPageConfiguration} from 'auxiliary/dispatch';
import {user} from 'auxiliary/state';

import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';


import withTokenValidation from 'hoc/withTokenValidation';

import useSetPageConfiguration from 'hooks/useSetPageConfiguration';

import Header from 'components/Header/Header';

import MessengerCustomerChat from 'react-messenger-customer-chat';

interface Props {
    storeSetPageConfiguration(params: Partial<PageConfigurationState>): void;
    validateToken(): Promise<boolean>;
    user: UserStateInterface;
}

function MemberTemplate(props: Props) {
    const route = useRouteMatch();
    const history = useHistory();
    const location = useLocation();
    const redirect = <Redirect to={{pathname: '/member/profile', state: {message: "Your account is already verified.", noticeState: 'info'}}} />;
    const memberRoute = location.pathname === '/member-verification' ? redirect : <MemberProfile />;

    const {validateToken, storeSetPageConfiguration} = props;
    storeSetPageConfiguration(useSetPageConfiguration());

    // useEffect(() => {
    //     window.Tawk_API= window.Tawk_API || {};
    //     window.Tawk_LoadStart = new Date();

    //     const scriptSource = process.env.REACT_TAWK_URL || 'https://embed.tawk.to/5f137baa7258dc118bee8162/default';
    //     const s1 = document.querySelector(`script[src='${scriptSource}']`) || document.createElement("script") as any;
    //     const s0 = document.getElementsByTagName("script")[0] as any;

    //     s1.async = true;
    //     s1.src = scriptSource;
    //     s1.charset='UTF-8';
    //     s1.setAttribute('crossorigin','*');
    //     s0.parentNode.insertBefore(s1, s0);

    //     const chatWidget = document.querySelector("iframe[title='chat widget']") as HTMLIFrameElement;
    //     if (chatWidget) {
    //         chatWidget.parentElement!.classList.add('chat--widget');
    //         chatWidget.parentElement!.style.display = 'block';
    //         chatWidget.closest('div')!.style.zIndex = '10';
    //     }

    //     return () => {
    //         const chatWidget = document.querySelector("iframe[title='chat widget']");
    //         if (chatWidget) {
    //             chatWidget.parentElement!.setAttribute('id', '');
    //             chatWidget.parentElement!.style.display = 'none';
    //         }    
    //     }
    // }, []);

    useEffect(() => {
        validateToken().then((authenticated: boolean) => {
            if (!authenticated && route.path === '/member' ) {
                // need verification 
                history.push('/login');
            }
        });
    }, [validateToken, history, location, route.path])
    
    return (
        <ModalProvider>
            <Modal context={ModalContext} />
            <div className="App">
                {location.pathname.includes('/member-verification') ? <Header headerType="member" /> : null } 
                <Switch>
                    <Route exact path="/">
                        <LandingPage />
                    </Route>
                    <Route path="/login" component={LogIn} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/member" component={Member} />
                    <Route exact path="/login" component={LogIn} />
                    <Route exact path="/signup" component={MemberRegistration} />
                    <Route path="/signup/:referral_code" component={MemberRegistration} />
                    <Route path="/member-verification" render={() => props.user.verified ? memberRoute : <MemberVerification />} />
                    <Route path="/reset-password" component={ResetPassword} />
                    
                    <Route path="*" component={Error} />
                </Switch>
            </div>
            <MessengerCustomerChat
              pageId="106581811222290"
              appId="<APP_ID>"
              htmlRef="<REF_STRING>"
            />
        </ModalProvider>
    );
}

export default compose(
    withStoreConnection({
        stateProps: [user],
        dispatchProps: [storeSetPageConfiguration, storeSetPageConfiguration]
    }),
    withTokenValidation
)(MemberTemplate);
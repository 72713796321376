import React, {useState} from 'react';

import {OwnerMenuContextInterface} from 'components/Owner/OwnerMenu/OwnerMenu';

interface Props {
    children: any
}

function OwnerMenuProvider(props: Props) {
    const [menuVisible, setMenuVisible] = useState(window.innerWidth > 768 ? true : false);
    
    const toggleMenu = () => {
        setMenuVisible(!menuVisible);
    }

    const state = {
        menuVisible: menuVisible,
        toggleMenu: toggleMenu,
    }

    return (
        <OwnerMenuContext.Provider value={state}>
            {props.children}
        </OwnerMenuContext.Provider>
    )
}

export const OwnerMenuContext = React.createContext<OwnerMenuContextInterface>(null!);
export default OwnerMenuProvider;
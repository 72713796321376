export const token = 'token';
export const user = 'user';

export const linkedAccounts = 'linkedAccounts';

export const ownerMembers = 'ownerMembers';
export const ownerBankAccounts = 'ownerBankAccounts';

export const memberPurchaseRequests = 'memberPurchaseRequests';
export const memberCashOuts = 'memberCashOuts';

export const pageConfiguration = 'pageConfiguration';
import React, {useState, useContext} from 'react';
import OwnerAccountForm, {FormSubmitResult} from '../owner-accounts-elements/OwnerAccountForm/OwnerAccountForm';
import {ownerBankAccounts} from 'auxiliary/state';
import {storeSetOwnerBankAccounts} from 'auxiliary/dispatch';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import axios from 'auxiliary/axios';

import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import {ModalContext} from 'providers/ModalProvider';

interface Props {
    bankAccount: BankAccountInterface;
    ownerBankAccounts: OwnerBankAccountsState;
    storeSetOwnerBankAccounts(params: Partial<OwnerBankAccountsState>): void;
}

function OwnerAccountsEditAccount(props: Props) {
    const snackbar = useContext(SnackbarContext);
    const modal = useContext(ModalContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const submitForm = async (result: FormSubmitResult) => {
        try {
            setButtonLoading(true);
            const response = await axios.put(`/bank_accounts/${result.id}`, result);
            setTimeout(() => {
                const bankAccounts = [...props.ownerBankAccounts.bankAccounts];
                const index = bankAccounts.findIndex(account => account.id === result.id);
                
                bankAccounts[index] = {
                    id: response.data.id,
                    country:  response.data.country,
                    accountName: response.data.account_name,
                    accountNumber: response.data.account_number,
                    bankName: response.data.bank_name
                }

                props.storeSetOwnerBankAccounts({
                    bankAccounts: bankAccounts
                });
                setButtonLoading(false);
                modal.hide();
                snackbar.show({text: 'Bank information updated'});
            }, 2000)
        } catch(error) {
            modal.setData({
                header: 'Error',
                content: {
                    template: 'error',
                    text: 'There was an error updating the bank account'
                }
            })
            modal.show();
            console.log(error)
            console.log('failed to update bank account')
        }
    }

    return (
        <OwnerAccountForm
            key={props.bankAccount.id}
            id={props.bankAccount.id}
            countryInitialValue={props.bankAccount.country}
            bankNameInitialValue={props.bankAccount.bankName}
            accountNameInitialValue={props.bankAccount.accountName}
            accountNumberInitialValue={props.bankAccount.accountNumber}

            formSubmitHandler={submitForm}
            buttonLoading={buttonLoading}
        />
    )
}

export interface BankAccountInterface {
    id: number;
    country: string;
    bankName: string;
    accountName: string;
    accountNumber: string;
}

export default compose(
    withStoreConnection({stateProps: [ownerBankAccounts], dispatchProps: [storeSetOwnerBankAccounts]})
)(OwnerAccountsEditAccount)
import React, {useRef, useLayoutEffect, useEffect, useState} from 'react';
import './InputGroup.scss';

import {
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons';

interface Props {
    autofocus?: boolean;
    change(result: ResultInterface): void;
    error?: string;
    icon?: string;
    iconElement?: JSX.Element;
    id: string;
    initialValue?: string;
    margin?: string;
    placeholder?: string;
    readonly?: boolean;
    timeStamp?: number;
    type?: 'email' | 'password' | 'text';
    valid?: boolean;
    validatedProps: {
        alphaNumeric?: boolean;
        email?: boolean;
        english?: boolean;
        maxLength?: number;
        minLength?: number;
        numbersOnly?: boolean;
    };
    value?: string;
}

function InputGroup(props: Props) {
    const inputError = useRef<HTMLDivElement>(null!);
    const inputRef = useRef<HTMLInputElement>(null!);
    const [passwordVisible, setPasswordVisible] = useState(false);

    useLayoutEffect(() => {
        inputError.current.style.opacity = '0';
        const timeout = setTimeout(() => {
            inputError.current.style.opacity = '1';
        }, 200)
        return () => {
            clearTimeout(timeout);
        }
    }, [props.timeStamp]);

    useEffect(function togglePasswordVisibility() {
        if (props.type === 'password') {
            passwordVisible ?  inputRef.current.type = 'text' : inputRef.current.type = 'password';
        }
    }, [passwordVisible, props.type]);

    const validator = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.validatedProps) return {valid: true, error: null};
        type indexSignature = {
            [key: string]: any
        };
        const result: indexSignature = {
            alphaNumeric: {
                valid: true,
                errorMessage: 'Please enter only letters and numbers.'
            },
            email: {
                valid: true,
                errorMessage: "The email is invalid."
            },
            english: {
                valid: true,
                errorMessage: 'Please enter only valid english letters.'
            },
            maxLength: {
                valid: true,
                errorMessage: `${props.placeholder?.toLowerCase()} is too long. Enter at most ${props.validatedProps.maxLength} characters`
            },
            minLength: {
                valid: true,
                errorMessage: `${props.placeholder?.toLowerCase() || 'value'} is too short. Enter at least ${props.validatedProps.minLength} characters`
            },
            numbersOnly: {
                valid: true,
                errorMessage: 'Please enter only numbers.'
            }
        };
        const predicate: indexSignature = {
            alphaNumeric: () => {
                const alphaNumericRegex = /^[a-z0-9]+$/i;
                return result['alphaNumeric'] = alphaNumericRegex.test(event.target.value);
            },
            email: () => {
                const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
                return result['email'] = regex.test(event.target.value);
            },
            english: () => {
                const regex = /^[a-zA-Z\s]*$/;
                return result['english'] = regex.test(event.target.value);
            },
            maxLength: () => {
                return event.target.value.length <= props.validatedProps.maxLength!;
            },
            minLength: () => {
                return event.target.value.length >= props.validatedProps.minLength!;
            },
            numbersOnly: () => {
                const regex = /^\d+$/;
                return result['numbersOnly'] = regex.test(event.target.value);
            }
        }

        Object.entries(props.validatedProps).forEach(el => {
            result[el[0]] = {...result[el[0]], valid: predicate[el[0]]()}
        });
        
        return {
            valid: Object.entries(result).map(e => e[1]['valid']).every(valid => valid),
            error: (function() {
                const error = Object.entries(result).map(e => e[1]).find(el => !el['valid']);
                return error ? error['errorMessage'] : null;
            })()
        }
    }

    const change = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {valid, error} = validator(event);
        return {
            origin: props.id,
            valid: valid,
            value: event.target.value,
            error: error
        }
    }

    return (
        <div className={`InputGroup ${props.margin}`}>
            <label htmlFor={props.id} data-read-only={props.readonly}>
                <span className="input--group__icon">
                    {props.iconElement ? props.iconElement : <i className={props.icon}></i>}
                </span>
                <input className="input--group__input"
                    id={props.id}
                    type={props.type} 
                    placeholder={props.placeholder} 
                    autoFocus={props.autofocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.change(change(e))}
                    autoComplete="off"
                    defaultValue={props.initialValue}
                    value={props.value}
                    disabled={props.readonly}
                    ref={inputRef}
                />
                {
                    props.type === 'password' ?
                        <span className="visibility--toggle" tabIndex={-1} onClick={(e: React.MouseEvent) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setPasswordVisible(previousValue => !previousValue);
                        }}>
                            {passwordVisible ? <VisibilityIcon/> : <VisibilityOffIcon />}
                        </span>
                    :
                    null
                }
            </label>
            <div className={`margin-6 input--group--error ${!props.valid ? 'visible' : ''}`} ref={inputError}>{props.error}</div>
        </div>
    )
}

export interface ResultInterface {
    origin: string;
    valid: boolean;
    value: string;
    error: string;
}

export default InputGroup;
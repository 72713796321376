import React, {useContext, useState, useCallback, useEffect} from 'react';
import './PasswordSettings.scss';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import axios from 'auxiliary/axios';
import FormHeader from '../components/FormHeader/FormHeader';

import { 
    VerifiedUser as VerifiedUserIcon,
    Policy as PolicyIcon
} from '@material-ui/icons';

function PasswordSettings() {
    const initialState = {value: '', valid: false, error: ''};
    const [password, setPassword] = useState({...initialState});
    const [passwordConfirmation, setPasswordConfirmation] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const snackbar = useContext(SnackbarContext);

    const checkPasswordEquality = useCallback(() => {
        if (password.value !== passwordConfirmation.value) {
            setPasswordConfirmation(previousValue => ({...previousValue, valid: false, error: 'The passwords do not match.'}));
        } else {
            setPasswordConfirmation(previousValue => ({...previousValue, valid: true, error: ''}));
        }
    }, [password.value, passwordConfirmation.value])

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'password':
                    setPassword(newState);
                break;
            case 'password-confirmation':
                    setPasswordConfirmation(newState);
                break;
            default:
                throw new Error(`${result.origin} origin is not defined`);
        }
    }
    const formSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        setPassword({...password, error: password.valid ? '' : password.error || 'Password is invalid'});
        setPasswordConfirmation({
            ...passwordConfirmation,
            error: passwordConfirmation.valid ? '' : passwordConfirmation.error || 'Password Confirmation is invalid'
        });

        const result = [
            password,
            passwordConfirmation
        ].map(e => e.valid);

        const requestData = {
            password: password.value,
            password_confirmation: passwordConfirmation.value
        }

        if (result.every(valid => valid)) {
            try {
                setButtonLoading(true);
                await axios.patch(`/account/update_password`, requestData);
                setTimeout(() => {
                    setButtonLoading(false);
                    snackbar.show({text: 'The settings have been saved'});
                }, 2500)
            } catch(error) {
                setButtonLoading(false);
                console.log(error.response);
            }
        }
    }

    useEffect(() => {
        checkPasswordEquality();
    }, [password.value, passwordConfirmation.value, checkPasswordEquality]);

    return (
        <div className="PasswordSettings">
            <form onSubmit={formSubmit} className="navigation--content--form">
                <FormHeader
                    buttonLoading={buttonLoading}
                    label="Change Password"
                    description="Change your account password"/>
                <div className="form--input">
                    <div className="password--update">
                        <InputGroup
                            autofocus={true}
                            change={handleChange}
                            error={password.error}
                            iconElement={<VerifiedUserIcon />}
                            id="password"
                            placeholder="Password"
                            type="password"
                            margin="margin-top-10"
                            valid={password.valid}
                            validatedProps={{minLength: 8}}
                            timeStamp={timeStamp}
                        />

                        <InputGroup
                            change={handleChange}
                            error={passwordConfirmation.error}
                            iconElement={<PolicyIcon />}
                            id="password-confirmation"
                            placeholder="Confirm Password"
                            type="password"
                            margin="margin-top-10"
                            valid={passwordConfirmation.valid}
                            validatedProps={{minLength: 8}}
                            timeStamp={timeStamp}
                        />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default PasswordSettings;
import React, {useEffect, useRef, useCallback, useContext} from 'react';

import axios from 'auxiliary/axios';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeSetOwnerMembers, storeSetOwnerBankAccounts} from 'auxiliary/dispatch';
import {user} from 'auxiliary/state';

import {UserStateInterface} from 'redux/reducers/user-reducer';
import {OwnerMembersState} from 'redux/reducers/owner/owner-members-reducer';
import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';
import {OwnerMenuContext} from 'components/Owner/OwnerMenuProvider';

import {Switch, Route, Redirect} from 'react-router-dom';
import OwnerGeneral from '../OwnerGeneral/OwnerGeneral';
import OwnerPromotions from '../OwnerPromotions/OwnerPromotions';
import OwnerClubActivity from '../OwnerClubActivity/OwnerClubActivity';
import OwnerMembers from '../OwnerMembers/OwnerMembers';
import OwnerSettings from '../OwnerSettings/OwnerSettings';
import OwnerAccounts from '../OwnerAccounts/OwnerAccounts';
import PurchaseRequests from "../Transactions/PurchaseRequests";
import CashOuts from "../Transactions/CashOuts";
import OwnerPageEditor from '../OwnerPageEditor/OwnerPageEditor';

interface Props {
  user: UserStateInterface;
  storeSetOwnerMembers(params: Partial<OwnerMembersState>): void;
  storeSetOwnerBankAccounts(params: OwnerBankAccountsState): void; 
}

function OwnerContent(props: Props) {

  const {storeSetOwnerMembers, storeSetOwnerBankAccounts} = props

      const retrieveMembers = useCallback(
        async (url) => {
            try {
                storeSetOwnerMembers({
                    progressIndicatorVisible: true
                });
                const response: {data: {users: Array<any>; pagination: any}} = await axios.get(url);
                const members = response.data.users.map(el => {
                    return {
                        id: el.id,
                        firstName: el.first_name,
                        lastName: el.last_name,
                        date_registered: el.date_registered,
                        email: el.email,
                        mobileNumber: el.mobile_number,
                        referralCode: el.referral_code,
                        totalPurchases: el.total_purchases,
                        totalCashOuts: el.total_cash_outs
                    }
                });
                const pagination = {
                    nextUrl: response.data.pagination.next_url,
                    previousUrl: response.data.pagination.previous_url,
                    currentPage: response.data.pagination.current,
                    pages: response.data.pagination.pages,
                    totalCount: response.data.pagination.count
                }
                storeSetOwnerMembers({
                    members: members,
                    pagination: pagination
                })
                setTimeout(() => {
                    storeSetOwnerMembers({
                        progressIndicatorVisible: false
                    })
                }, 500)
            } catch(error) {
                console.log('owner members retrieval failure')
            }
        }, [storeSetOwnerMembers]
    )

    const retrieveBankAccounts = useCallback(
        async (url) => {
            try {
                const response: {data: {accounts: Array<any>; pagination: any}} = await axios.get(url);
                const accounts = response.data.accounts.map(el => {
                    return {
                        id: el.id,
                        country: el.country,
                        accountName: el.account_name,
                        accountNumber: el.account_number,
                        bankName: el.bank_name
                    }
                });
                const pagination = {
                    nextUrl: response.data.pagination.next_url,
                    previousUrl: response.data.pagination.previous_url,
                    currentPage: response.data.pagination.current,
                    pages: response.data.pagination.pages,
                    totalCount: response.data.pagination.count
                }
                storeSetOwnerBankAccounts({
                    bankAccounts: accounts,
                    pagination: pagination
                })
            } catch(error) {
                console.log('owner bank accounts retrieval failure')
            }
        }, [storeSetOwnerBankAccounts]
    )
    
    useEffect(() => {
        retrieveMembers('/members');
        retrieveBankAccounts('/bank_accounts');
    }, [retrieveMembers, retrieveBankAccounts]);

    const context = useContext(OwnerMenuContext);

    const handleClick = () => {
      if (window.innerWidth <= 768 && context.menuVisible) {
        context.toggleMenu();
      }
    }

    const containerRef = useRef<HTMLDivElement>(null!);

    useEffect(() => {
        const height = window.innerHeight - 80;
        containerRef.current.style.height = height + 'px';

        document.documentElement.style.setProperty('--accent-one-shade-one', 'var(--owner-accent-one-shade-three)');
        return () => {
            document.documentElement.style.setProperty('--accent-one-shade-one', '#ffc164');
        }
    }, []);


  return(
    <div className="owner--content" ref={containerRef} onClick={handleClick}>
        <Switch>
            <Route exact path="/club" component={OwnerGeneral} />
            <Route path="/club/promotions" component={OwnerPromotions} />
            <Route path="/club/activity" component={OwnerClubActivity} />
            <Route path="/club/members" render={() => <OwnerMembers retrieveMembers={retrieveMembers} />}/>
            <Route path="/club/settings" render={() => <OwnerSettings/>}/>
            <Route path="/club/accounts" render={() => <OwnerAccounts retrieveBankAccounts={retrieveBankAccounts} />} />
            <Route path="/club/page-editor" component={OwnerPageEditor} />
            <Route path={'/club/purchase-requests'} component={PurchaseRequests} />
            <Route path={'/club/cash-outs'} component={CashOuts} />
            <Route path="*" render={() => <Redirect to={{pathname: '/club'}} />} />
        </Switch>
    </div>
  )
}

export default compose(
  withStoreConnection({stateProps: [user], dispatchProps: [storeSetOwnerMembers, storeSetOwnerBankAccounts]})
)(OwnerContent);
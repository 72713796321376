import React, {useContext} from 'react';
import Button from 'elements/Button/Button';
import {ModalContext} from 'providers/ModalProvider';
import {useHistory} from 'react-router-dom';

interface Props {
    text: string;
}

function LogInModalContent({text}: Props) {
    const modal = useContext(ModalContext);
    const history = useHistory();

    return (
        <div className="modal--data--content">
            <p>{text}</p>
            <div className="confirmation--buttons margin-top-20">
                <Button
                    size="small" 
                    buttonType="text" 
                    text="Cancel" 
                    color="dark-red"
                    className="cancel"
                    clickHandler={
                        () => {
                            modal.hide();
                        }
                    }
                    margin="margin-right-20"
                    />
                <Button 
                    width="80px"
                    size="small"
                    text="Yes" 
                    clickHandler={
                        async () => {
                            history.push('/login');
                            modal.hide();
                        }
                    }
                />
            </div>
        </div>
    )
}

export default LogInModalContent;
import React, {useState} from 'react';

interface Props {
    children: JSX.Element | Array<JSX.Element>
}

interface ShowInterface {
    text: string;
}
function SnackbarProvider({children}: Props) {
    const [timeStamp, setTimeStamp] = useState(0);
    const [text, setText] = useState('');

    function show({text}: ShowInterface) {
        setText(text);
        setTimeStamp(Date.now());
    }

    const state = {
        text: text,
        timeStamp: timeStamp,
        show: show
    }

    return (
        <SnackbarContext.Provider value={state}>
            {children}
        </SnackbarContext.Provider>
    )
}

export interface SnackbarContextInterface {
    text: string;
    timeStamp: number;
    show(params: ShowInterface): void;
}

export const SnackbarContext = React.createContext<SnackbarContextInterface>(null!);
export default SnackbarProvider;
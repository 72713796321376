import React, {useState, useEffect, useCallback, useContext} from 'react';
import './MemberRegistration.scss';
import 'components/LandingPage/LandingPageStyle.scss';
import {usePasswordEqualityChecker} from 'hooks/usePasswordEqualityChecker';
import Button from 'elements/Button/Button';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import MobileNumberInput from 'elements/MobileNumberInput/MobileNumberInput';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'auxiliary/axios';
import {pageConfiguration} from 'auxiliary/state';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {compose} from 'redux';
import {useHistory, useParams} from 'react-router-dom';
import {ModalContext} from 'providers/ModalProvider';
import LandingPageHeader from 'components/LandingPage/components/LandingPageHeader';
import LogInModalContent from './LogInModalContent/LogInModalContent';

import back from 'assets/images/back.svg';
import {Link} from 'react-router-dom';

import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import {storeSetUser, storeSetToken} from 'auxiliary/dispatch';
import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    storeUserInformation(params: {data: UserStateInterface}): void;
    pageConfiguration: PageConfigurationState;
}

function MemberRegistration(props: Props) {
    const [pageLoading, setPageLoading] = useState(false);
    const modal = useContext(ModalContext);
    const host = window.location.host;
    const {referral_code} = useParams<{referral_code: string}>();

    const initialState = {value: '', valid: false, error: ''};
    const [email, setEmail] = useState({...initialState});
    const [password, setPassword] = useState({...initialState});
    const [passwordConfirmation, setPasswordConfirmation] = useState({...initialState});
    const [firstName, setFirstName] = useState({...initialState});
    const [lastName, setLastName] = useState({...initialState});
    const [mobileNumber, setMobileNumber] = useState({...initialState});
    const [referralCode, setReferralCode] = useState({...initialState});
    const [inGameID, setInGameID] = useState({...initialState});
    const [inGameUsername, setInGameUsername] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);
    const history = useHistory();

    
    usePasswordEqualityChecker({
        password: password,
        passwordConfirmation: passwordConfirmation,
        setPasswordConfirmation: setPasswordConfirmation
    });

    const storeFormData = () => {
        localStorage.setItem(host + '__email', email.value);
        localStorage.setItem(host + '__firstName', firstName.value);
        localStorage.setItem(host + '__lastName', lastName.value);
        localStorage.setItem(host + '__mobileNumber', mobileNumber.value);
        localStorage.setItem(host + '__referralCode', referralCode.value);
        localStorage.setItem(host + '__inGameID', inGameID.value);
        localStorage.setItem(host + '__inGameUsername', inGameUsername.value);
    }

    const getItem = useCallback((value) => {
        const data = localStorage.getItem(host + value);
        return data ? {value: data, valid: true, error: ''} : {value: '', valid: false, error: ''};
    }, [host]);

    useEffect(function setFormSavedData() {
        setEmail(prev => ({...prev, ...getItem('__email')}));
        setFirstName(prev => ({...prev, ...getItem('__firstName')}));
        setLastName(prev => ({...prev, ...getItem('__lastName')}));
        setMobileNumber(prev => ({...prev, ...getItem('__mobileNumber')}));
        setReferralCode(prev => ({...prev, ...getItem('__referralCode')}));
        setInGameID(prev => ({...prev, ...getItem('__inGameID')}));
        setInGameUsername(prev => ({...prev, ...getItem('__inGameUsername')}));
    }, [getItem]);


    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        
        switch (result.origin) {
            case 'email':
                    setEmail({...newState, value: newState.value.toLowerCase()});
                break;
            case 'password':
                    setPassword(newState);
                break;
            case 'password-confirmation':
                    setPasswordConfirmation(newState);
                break;
            case 'first-name':
                    setFirstName(newState);
                break;
            case 'last-name':
                    setLastName(newState);
                break;
            case 'mobile-number':
                    setMobileNumber(newState);
                break;
            case 'referral-code':
                    setReferralCode(newState);
                break;
            case 'in-game-id':
                    setInGameID(newState)
                break;
            case 'in-game-username':
                    setInGameUsername(newState)
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault();
        setTimeStamp(Date.now());
        setEmail({...email, error: email.valid ? '' : email.error || 'Email is invalid'});
        setPassword({...password, error: password.valid ? '' : 'Password must be 8 or more characters'});
        setPasswordConfirmation({...passwordConfirmation, error: passwordConfirmation.valid ? '' : 'The passwords do not match'});
        setFirstName({...firstName, error: firstName.valid ? '' : firstName.error || 'First name is invalid'});
        setLastName({...lastName, error: lastName.valid ? '' : lastName.error || 'Last Name is invalid'});
        setMobileNumber({...mobileNumber, error: mobileNumber.valid ? '' : mobileNumber.error || 'Mobile number is invalid.'});
        setReferralCode({...referralCode, value: (!props.pageConfiguration.organizationStrictReferrals && !referralCode.valid ? "" : referralCode.value), error: ( referralCode.valid ||  !props.pageConfiguration.organizationStrictReferrals) ? '' : referralCode.error || 'Referral code is invalid.', });
        setInGameID({...inGameID, error: inGameID.valid ? '' : inGameID.error || 'In Game ID is invalid.'});
        setInGameUsername({...inGameUsername, error: inGameUsername.valid ? '' : inGameID.error || 'In Game Username is not valid'});

        const result = [
            email,
            password,
            passwordConfirmation,
            firstName,
            lastName,
            mobileNumber,
            referralCode,
            inGameID,
            inGameUsername
        ].map(e => e.valid);

        storeFormData();
        
        if (result.every(valid => valid)) {
            const data = {
                first_name: firstName.value,
                last_name: lastName.value,
                email: email.value,
                mobile_number: mobileNumber.value,
                password: password.value,
                password_confirmation: passwordConfirmation.value,
                referral_code: referralCode.value,
                linked_account: {
                    game_id: inGameID.value,
                    username: inGameUsername.value
                }
            };
            
            try {
                setPageLoading(true);
                const response: {data: UserStateInterface} = await axios.post('/members/sign_up', data);
                props.storeUserInformation(response);
                history.push({pathname: '/member-verification', state: {message: "Please verify your account.", noticeState: 'info'}});
            } catch(error) {
                if (error.response.status === 404) {
                    setReferralCode({...referralCode, valid: false, error: error.response.data.error});
                    modal.setData({
                        header: '',
                        content: {
                            template: 'error',
                            text: error.response.data.error
                        }
                    })
                    modal.show();
                }

                const errors = error.response.data.errors;
                if (errors?.email) {
                    setEmail({...email, valid: false, error: 'email ' + errors.email[0]});
                    setPassword({...password, valid: false, error: 'Please enter a valid password'});
                    setPasswordConfirmation({...passwordConfirmation, valid: false, error: 'Please confirm your password.'});
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    modal.setData({
                        header: 'Data entry error',
                        content: (
                            <LogInModalContent
                                text="The email you entered has already been taken. Would like to log-in with this e-mail instead?"
                            />
                        )
                    });
                    modal.show();
                }
                if (errors?.mobile_number) {
                    setMobileNumber({...mobileNumber, valid: false, error: 'Mobile number ' + errors.mobile_number[0]});
                    modal.setData({
                        header: 'Data entry error',
                        content: (
                            <LogInModalContent
                                text="Mobile number has already been taken. Would like to log-in instead?"
                            />
                        )
                    });
                    modal.show();
                    setPassword({...password, valid: false, error: 'Please enter a valid password'});
                    setPasswordConfirmation({...passwordConfirmation, valid: false, error: 'Please confirm your password.'});
                }
            } finally {
                setPageLoading(false);
            }
        } else {
            if (email.error === 'email has already been taken') {
                modal.show();
            }
        }
    }

    useEffect(() => {
        if (referral_code) {
            setReferralCode({value: referral_code, valid: true, error: ''});
        }
    }, [referral_code])

    useEffect(function showWelcomeMessage() {
        if (referral_code !== undefined) {
            modal.setData({
                ...modal.data,
                header: 'Success',
                content: <div className="modal--data--content">
                    <p>Welcome! Thank you for participating in our referral program. Please fill in the form below to complete your registration.</p>
                    <div className="confirmation--buttons margin-top-20">
                        <Button 
                            width="80px"
                            size="small"
                            text="Ok" 
                            clickHandler={
                                async () => {
                                    modal.hide();
                                }
                            }
                        />
                    </div>
                </div>
            });
            setTimeout(() => {
                modal.show();
            }, 300)
        }
    }, [referral_code]) // eslint-disable-line react-hooks/exhaustive-deps


    return (
        pageLoading ? 
        <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} />
        :
        <React.Fragment>
            <div className="LandingPage">
                <LandingPageHeader
                    organizationIdentifier={props.pageConfiguration.organizationIdentifier}
                    backgroundColor={props.pageConfiguration.header.backgroundColor}
                    fontColor={props.pageConfiguration.header.fontColor}
                    logo={props.pageConfiguration.header.logo}
                />
            </div>
            <div className="MemberRegistration" style={{height: 'calc(100vh - 118px )'}}>
                <div className="member--registration--content">
                    {
                        window.innerWidth <= 600 ? <div className="back--button--container">
                            <div className="back--button">
                                <Link to="/">
                                    <img src={back} alt="Back icon" width="35px" height="35px"/>
                                </Link>
                            </div>
                            <Heading text="Registration" alignment="center" level="one" />
                        </div>
                        :
                        <Heading text="Registration" alignment="center" level="one" />
                    }
                    <form onSubmit={submitForm}>
                        
                        <InputGroup
                            autofocus={true}
                            change={handleChange}
                            error={email.error}
                            icon="ti-email"
                            id="email"
                            placeholder="Email"
                            type="text"
                            margin="margin-top-20"
                            valid={email.valid}
                            validatedProps={{email: true}}
                            timeStamp={timeStamp}
                            value={email.value}
                        />

                        <InputGroup
                            change={handleChange}
                            error={password.error}
                            icon="ti-lock"
                            id="password"
                            placeholder="Password"
                            type="password"
                            margin="margin-top-20"
                            valid={password.valid}
                            validatedProps={{minLength: 8}}
                            timeStamp={timeStamp}
                        />

                        <InputGroup
                            change={handleChange}
                            error={passwordConfirmation.error}
                            icon="ti-shield"
                            id="password-confirmation"
                            placeholder="Confirm Password"
                            type="password"
                            margin="margin-top-20"
                            valid={passwordConfirmation.valid}
                            validatedProps={{}}
                            timeStamp={timeStamp}
                        />

                        <InputGroup
                            change={handleChange}
                            error={firstName.error}
                            icon="ti-user"
                            id="first-name"
                            placeholder="First Name"
                            type="text"
                            margin="margin-top-20"
                            valid={firstName.valid}
                            validatedProps={{english: true, minLength: 2}}
                            timeStamp={timeStamp}
                            value={firstName.value}
                        />

                        <InputGroup
                            change={handleChange}
                            error={lastName.error}
                            icon="ti-notepad"
                            id="last-name"
                            placeholder="Last Name"
                            type="text"
                            margin="margin-top-20"
                            valid={lastName.valid}
                            validatedProps={{english: true, minLength: 2}}
                            timeStamp={timeStamp}
                            value={lastName.value}
                        />
                        
                        <MobileNumberInput 
                            change={handleChange}
                            error={mobileNumber.error}
                            margin="margin-top-20"
                            id="mobile-number"
                            timeStamp={timeStamp}
                            valid={mobileNumber.valid}
                            value={mobileNumber.value}
                        />

                        <InputGroup
                            change={handleChange}
                            error={referralCode.error}
                            icon="ti-layers"
                            id="referral-code"
                            placeholder="Referral Code"
                            type="text"
                            margin="margin-top-20"
                            valid={referralCode.valid}
                            validatedProps={{}}
                            value={referralCode.value}
                            readonly={referral_code !== undefined}
                            timeStamp={timeStamp}
                        />

                        <div 
                            className="border" 
                            style={{
                                height: '1px',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)'
                            }}>
                        </div>

                        <p>In Game Account</p>

                        <InputGroup
                            change={handleChange}
                            error={inGameID.error}
                            icon="ti-id-badge"
                            id="in-game-id"
                            placeholder="User ID"
                            type="text"
                            margin="margin-top-20"
                            valid={inGameID.valid}
                            validatedProps={{minLength: 1, numbersOnly: true}}
                            timeStamp={timeStamp}
                            value={inGameID.value}
                        />

                        <InputGroup
                            change={handleChange}
                            error={inGameUsername.error}
                            icon="ti-user"
                            id="in-game-username"
                            placeholder="Username"
                            type="text"
                            margin="margin-top-20"
                            valid={inGameUsername.valid}
                            validatedProps={{minLength: 1}}
                            timeStamp={timeStamp}
                            value={inGameUsername.value}
                        />

                        <Button text={'Join Now'} margin="margin-top-20" />
                    </form>
                </div>
            </div>
        </React.Fragment>
    )
}

export default compose(
    withStoreConnection({dispatchProps: [storeSetUser, storeSetToken], stateProps: [pageConfiguration]}),
    withUserInformationStorage
)(MemberRegistration)
import React, {useContext, useState} from 'react';
import Button from 'elements/Button/Button';
import {Note} from '../OwnerMembersViewMember';
import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import axios from 'auxiliary/axios';

interface Props {
    memberID: string;
    note: Note;
    callback(): void;
}

function DeleteNote({memberID, note, callback}: Props) {
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    return (
        <div className="modal--data--content">
            <p>Are you sure you want to delete this note?</p>
            <div className="confirmation--buttons margin-top-20">
                <Button
                    size="small" 
                    buttonType="text" 
                    text="Cancel" 
                    color="dark-red"
                    className="cancel"
                    clickHandler={
                        () => {
                            modal.hide();
                        }
                    }
                    margin="margin-right-20"
                />
                <Button 
                    width="80px"
                    size="small"
                    text="Yes" 
                    backgroundColor="dark--red"
                    loading={buttonLoading}
                    clickHandler={
                        async () => {
                            try {
                                setButtonLoading(true);
                                await axios.delete(`/members/${memberID}/player_notes/${note.id}`);
                                setTimeout(() => {
                                    snackbar.show({text: 'The note has been deleted successfully'});
                                    callback();
                                    modal.hide();
                                }, 2000)

                                setTimeout(() => {
                                    setButtonLoading(false);
                                }, 2400)
                            } catch(error) {
                                modal.hide();
                                setTimeout(() => {
                                    modal.setData({
                                        header: 'Error',
                                        content: {
                                            template: 'error',
                                            text: 'There was an error deleting the note.'
                                        }
                                    });
                                    modal.show();
                                }, 500) ;
                            }
                        }
                    }
                />
            </div>
        </div>
    )
}

export default DeleteNote;
import {ActionInterface} from './interfaces';

const initialState: TokenStateInterface = {
    accessToken: localStorage.getItem('admin--desk--member__accessToken'),
    clientID: localStorage.getItem('admin--desk--member__clientID'),
    uid: localStorage.getItem('admin--desk--member__uid')
}

const actions = {
    REMOVE_TOKEN: 'REMOVE_TOKEN',
    SET_TOKEN: 'SET_TOKEN'
}

function reducer(state = initialState, action: ActionInterface<TokenStateInterface>) {
    switch (action.type) {
        case actions.REMOVE_TOKEN:
            localStorage.removeItem('admin--desk--member__accessToken');
            localStorage.removeItem('admin--desk--member__clientID');
            localStorage.removeItem('admin--desk--member__uid');
            return {
                ...state,
                accessToken: null,
                clientID: null,
                uid: null
            }

        case actions.SET_TOKEN:
            localStorage.setItem('admin--desk--member__accessToken', action.payload.accessToken as string);
            localStorage.setItem('admin--desk--member__clientID', action.payload.clientID as string);
            localStorage.setItem('admin--desk--member__uid', action.payload.uid as string);
            return {
                ...state,
                ...action.payload
            }
        default: {
            return state;
        }
    }
}

export interface TokenStateInterface {
    accessToken: string | null;
    clientID: string | null;
    uid: string | null;
}

export default reducer;
import {ActionInterface} from './interfaces';
import {landingPageConfiguration} from 'auxiliary/page-structure';

const storedState = localStorage.getItem('landingPageConfiguration');
const initialState = storedState ? {...JSON.parse(storedState)} : {...landingPageConfiguration};
const extendedState = {
    ...initialState,
    organizationIdentifier: '',
    organizationName: '',
    organizationStrictReferrals: true,
    carouselSectionEditMode: {
        "images": []
    },
    gameImagesEditMode: {
        header: {
            content: ""
        },
        images: []
    },
    detailsSectionEditMode: {
        detailItems: []
    },
    headerEditMode: {
        logo: ''
    }
}

const actions = {
    SET_PAGE_CONFIGURATION: 'SET_PAGE_CONFIGURATION'
}

function reducer(state = extendedState, action: ActionInterface<PageConfigurationState>) {
    switch(action.type) {
        case actions.SET_PAGE_CONFIGURATION:
            return {
                ...state,
                ...action.payload
            };
        default: {
            return state;
        }
    }
}

export interface PageConfigurationState {
    carouselSection: {
        images: Array<any>
    }
    header: {
        backgroundColor: string;
        logo: File | string;
        fontColor: string;
    }
    gameImages: {
        header: {
            content: string;
        }
        images: Array<any>;
    }
    detailsSection: {
        detailItems: Array<any>
    }
    elementOrder: Array<string>;

    organizationIdentifier: string;
    organizationName: string;
    organizationStrictReferrals: boolean;
    organizationGamePlatform: {
        name: string | null;
    }
    carouselSectionEditMode: {
        images: Array<any>
    }
    gameImagesEditMode: {
        header: {
            content: string;
        }
        images: Array<any>
    }
    detailsSectionEditMode: {
        detailItems: Array<any>
    }
    headerEditMode: {
        logo: File | string;
    }
}

export default reducer;
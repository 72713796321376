import React from 'react';

import { 
    Colorize as ColorizeIcon,
    Folder as FolderIcon,
    FolderOpen as FolderOpenIcon,
    FormatColorFill as FormatColorFillIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Image as ImageIcon,
    ListAlt as ListAltIcon,
    PhotoCamera as PhotoCameraIcon,
    PhotoLibrary as PhotoLibraryIcon,
    TextFields as TextFieldsIcon,
    ViewCarousel as ViewCarouselIcon

} from '@material-ui/icons';

export default [
    {
        collapseIcon: <FolderIcon />,
        expandIcon: <FolderOpenIcon />,
        text: 'Landing Page',
        color: 'var(--owner-accent-one-shade-two)',
        active: true,
        nodeName: 'landingPage',
        children: [
            {
                collapseIcon: <FolderIcon />,
                expandIcon: <FolderOpenIcon />,
                text: 'Header',
                color: 'var(--owner-accent-one-shade-two)',
                active: false,
                nodeName: 'header',
                children: [
                    {
                        collapseIcon: <FormatColorFillIcon />,
                        expandIcon: <FormatColorFillIcon />,
                        text: 'Background Color',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'header__backgroundColor'
                    },
                    {
                        collapseIcon: <ColorizeIcon />,
                        expandIcon: <ColorizeIcon />,
                        text: 'Font Color',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'header__fontColor'
                    },
                    {
                        collapseIcon: <PhotoCameraIcon />,
                        expandIcon: <PhotoCameraIcon />,
                        text: 'Logo',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'header__logo'
                    }
                ]
            },
            {
                collapseIcon: <ViewCarouselIcon />,
                expandIcon: <ViewCarouselIcon />,
                text: 'Carousel',
                color: 'var(--owner-accent-one-shade-two)',
                active: false,
                nodeName: 'carouselSection'
            },
            {
                collapseIcon: <FolderIcon />,
                expandIcon: <FolderOpenIcon />,
                text: 'Game Images',
                color: 'var(--owner-accent-one-shade-two)',
                active: true,
                nodeName: 'gameImages',
                children: [
                    {
                        collapseIcon: <TextFieldsIcon />,
                        expandIcon: <TextFieldsIcon />,
                        text: 'Header',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'gameImages__header'
                    },
                    {
                        collapseIcon: <PhotoLibraryIcon />,
                        expandIcon: <PhotoLibraryIcon />,
                        text: 'Images',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'gameImages__images--section'
                    }
                ]
            },
            {
                collapseIcon: <FolderIcon />,
                expandIcon: <FolderOpenIcon />,
                text: 'Details',
                color: 'var(--owner-accent-one-shade-two)',
                active: false,
                nodeName: 'detailsSection',
                children: [
                    {
                        collapseIcon: <FormatListBulletedIcon />,
                        expandIcon: <ListAltIcon />,
                        text: 'Item One',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'detailsSection__itemContainer__1',
                        children: [
                            {
                                collapseIcon: <ImageIcon />,
                                expandIcon: <ImageIcon />,
                                text: 'Image',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__image__1'
                            },
                            {
                                collapseIcon: <TextFieldsIcon />,
                                expandIcon: <TextFieldsIcon />,
                                text: 'Text',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__text__1'
                            }
                        ]
                    },
                    {
                        collapseIcon: <FormatListBulletedIcon />,
                        expandIcon: <ListAltIcon />,
                        text: 'Item Two',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'detailsSection__itemContainer__2',
                        children: [
                            {
                                collapseIcon: <ImageIcon />,
                                expandIcon: <ImageIcon />,
                                text: 'Image',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__image__2'
                            },
                            {
                                collapseIcon: <TextFieldsIcon />,
                                expandIcon: <TextFieldsIcon />,
                                text: 'Text',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__text__2'
                            }
                        ]
                    },
                    {
                        collapseIcon: <FormatListBulletedIcon />,
                        expandIcon: <ListAltIcon />,
                        text: 'Item Three',
                        color: 'var(--owner-accent-one-shade-two)',
                        active: false,
                        nodeName: 'detailsSection__itemContainer__3',
                        children: [
                            {
                                collapseIcon: <ImageIcon />,
                                expandIcon: <ImageIcon />,
                                text: 'Image',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__image__3'
                            },
                            {
                                collapseIcon: <TextFieldsIcon />,
                                expandIcon: <TextFieldsIcon />,
                                text: 'Text',
                                color: 'var(--owner-accent-one-shade-two)',
                                active: false,
                                nodeName: 'detailsSection__item__text__3'
                            }
                        ]
                    }
                ]
            }
        ]
    }    
]
import React, {useState, useContext, useEffect} from 'react';
import './CashOut.scss';
import Container from 'elements/Container/Container';
import Heading from 'elements/Heading/Heading';
import Select from 'elements/Select/Select';
import Button from 'elements/Button/Button';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import FormattedNumberInput from 'elements/FormattedNumberInput/FormattedNumberInput';
import axios from 'auxiliary/axios';
import {useHistory} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {linkedAccounts, user} from 'auxiliary/state';

import {ModalContext} from 'providers/ModalProvider';

import {UserStateInterface} from 'redux/reducers/user-reducer';

import {
    AccountBalance as AccountBalanceIcon,
    AccountBalanceWallet as AccountBalanceWalletIcon,
    FormatListNumbered as FormatListNumberedIcon,
    Stars as StarsIcon
} from '@material-ui/icons';

interface InitialState {
    value: string;
    valid: boolean;
    error: string;
}

interface ClubConfig {
    chipValue: number;
    currency: string;
}

interface Props {
    setCashOuts(params: string): void;
    linkedAccounts: Array<any>;
    user: UserStateInterface;
}

function CashOut(props: Props) {
    const [pageLoading, setPageLoading] = useState(false);
    const initialState = {value: '', valid: false, error: ''};
    const [pokerAccount, setPokerAccount] = useState<Partial<InitialState>>({...initialState});
    const [cashOutMethod, setCashOutMethod] = useState({...initialState, valid: true, value: 'Bank Transfer'});
    const [amountToCashOut, setAmountToCashOut] = useState({...initialState});
    const [bankName, setBankName] = useState({...initialState});
    const [accountName, setAccountName] = useState({...initialState});
    const [accountNumber, setAccountNumber] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);
    const [clubConfig, setClubConfig] = useState<ClubConfig>({ chipValue: 1.0, currency: '' });

    const context = useContext(ModalContext);
    const history = useHistory();

    const [initialPokerAccount, setInitialPokerAccount] = useState<{text: string; value: string}>(null!);

    useEffect(() => {
        const initClubConfig = async function() {
            try {
                const response = await axios.get('/');

                setClubConfig(() => {
                    return {
                        chipValue: Number(response.data.chip_value),
                        currency: response.data.currency
                    }
                });
            } catch(error) {
                console.log(error.response)
            }
        }

        initClubConfig();
    }, []);

    useEffect(() => {
        if (props.linkedAccounts[0]) {
            setPokerAccount({valid: true, value: props.linkedAccounts[0].id.toString()});
            setInitialPokerAccount({text: props.linkedAccounts[0].username, value: props.linkedAccounts[0].id.toString()});
        }
    }, [props.linkedAccounts])

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'amount-to-cash-out':
                    setAmountToCashOut(newState);
                break;
            case 'bank-name':
                    setBankName(newState);
                break;
            case 'account-name':
                    setAccountName(newState)
                break;
            case 'account-number':
                    setAccountNumber(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const handleSelect = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        
        switch (result.origin) {
            case 'poker-account':
                    setPokerAccount(newState);
                break;
            case 'cash-out-method':
                    setCashOutMethod(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const confirmationHandler = async () => {
        try {
            const data = {
                bank_name: bankName.value,
                account_number: accountNumber.value,
                bank_account_name: accountName.value,
                linked_account_id: pokerAccount.value,
                chips: amountToCashOut.value.replace(',',''),
                cash_out_method: cashOutMethod.value
            };

            context.hide();
            setPageLoading(true);
            await axios.post('/account/withdrawal_requests', data);
            setPageLoading(false);
            props.setCashOuts('/account/withdrawal_requests');
            context.setData({
                header: 'Success',
                content: (
                    <div className="success--content">
                        <p>
                            Thanks, {props.user.firstName}! We are 
                            currently processing your order. 
                            We'll contact you when we're done. Requests are typically processed no more than a day. 
                            If you have questions, please feel free to contact us.
                        </p>
                        <div className="confirmation--buttons margin-top-20">
                            <Button width="80px" text="Ok" clickHandler={
                                () => {
                                    context.hide();
                                    history.push('/member/transactions/cash-outs');
                                }
                            } />
                        </div>
                    </div>
                )
            });
            context.show();
        } catch(error) {
            console.log(error.response);
            console.log('Withdrawal request failed. Error: '+ error);
            setPageLoading(false);
            context.hide();
            
            context.setData({
                header: 'Error',
                content: (
                    <div className="success--content">
                        <p>
                            Sorry, {props.user.firstName}! We cannot process your order. 
                        </p>
                        <p>
                            If you have a pending cashout request, please wait for us to process it before making a new one.
                        </p>
                        <div className="confirmation--buttons margin-top-20">
                            <Button width="80px" text="Ok" clickHandler={
                                () => {
                                    context.hide();
                                    history.push('/member/transactions/cash-outs');
                                }
                            } />
                        </div>
                    </div>
                )
            });
            setTimeout(() => {
              context.show();
            }, 500)
            
        }
    }

    const submitForm =  async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());
        
        setPokerAccount({...pokerAccount, error: pokerAccount.valid ? '' : pokerAccount.error || 'Poker account is invalid'});
        setCashOutMethod({...cashOutMethod, error: cashOutMethod.valid ? '' : cashOutMethod.error || 'Cash out methid is invalid'});
        setAmountToCashOut({...amountToCashOut, error: amountToCashOut.valid ? '' : amountToCashOut.error || 'Amount to cash out is invalid'});
        setBankName({...bankName, error: bankName.valid ? '' : bankName.error || 'Bank name is invalid'});
        setAccountName({...accountName, error: accountName.valid ? '' : accountName.error || 'Account name is invalid'});
        setAccountNumber({...accountNumber, error: accountNumber.valid ? '' : accountNumber.error || 'Account number is invalid'});

        const result = [
            pokerAccount,
            amountToCashOut,
            cashOutMethod,
            accountName,
            accountNumber,
        ].map(e => e.valid);

        const modalData = (
            <div style={{width: '100%'}}>
                <div className="row">
                    <div className="key">Chips to be transferred:</div>
                    <div className="value">{amountToCashOut.value}</div>
                </div>
                <div className="row">
                    <div className="key">Bank name:</div>
                    <div className="value">{bankName.value}</div>
                </div>
                <div className="row">
                    <div className="key">Account name:</div>
                    <div className="value">{accountName.value}</div>
                </div>
                <div className="row">
                    <div className="key">Account number:</div>
                    <div className="value">{accountNumber.value}</div>
                </div>
                <p className="margin-top-10" style={{color: 'var(--dark-red)', lineHeight: '18px'}}>
                    Please review the transaction details carefully.
                </p>
                <p className="margin-top-10">Woud you like to proceed?</p>
                <div className="confirmation--buttons margin-top-20">
                    <Button
                        size="small" 
                        buttonType="text" 
                        text="Cancel" 
                        color="dark-red"
                        className="cancel"
                        clickHandler={
                            () => {
                                context.hide();
                            }
                        }
                        margin="margin-right-20"
                        />
                    <Button 
                        width="80px"
                        size="small"
                        text="Yes" 
                        backgroundColor="dark--red"
                        clickHandler={confirmationHandler}
                    />
                </div>
            </div>
        )

        if (result.every(valid => valid)) {
            context.setData({
                ...context.data,
                header: 'Bank Transfer',
                content: modalData,
            });
            context.toggle();
        } else {
            if ([
                pokerAccount,
                cashOutMethod,
                amountToCashOut,
            ].map(e => e.valid).includes(false)) {
                window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            }
        }
    };

    return (
        pageLoading ? 
        <CircularProgress style={{position: 'absolute', top: '50%', left: '50%'}} /> 
        :
        <Container constrain={500}>
            <Heading text="Cash Out" level="two" alignment="left" margin="margin-bottom-20" />
            <div className="CashOut padding-20">
                <form onSubmit={submitForm}>
                    <div className="margin-top-30 max-width-500">
                        <Select 
                            id="poker-account" 
                            options={props.linkedAccounts.map(account => {
                                return {
                                    text: account.username,
                                    value: account.id.toString()
                                }
                            })}
                            initialSelectedOption={
                                initialPokerAccount ? initialPokerAccount : null
                            }
                            selectText="Player Account"
                            select={handleSelect}
                            error={pokerAccount.error!}
                        />
                    </div>
                    
                    <div className="margin-top-30 max-width-500">
                        <Select
                            id="cash-out-method"
                            options={[{
                                text: 'Bank Transfer',
                                value: 'Bank Transfer'
                            }]}
                            selectText="Cash out Method"
                            select={handleSelect}
                            error={cashOutMethod.error}
                            initialValue="Bank Transfer"                       
                        />
                    </div>

                    <div className="max-width-500 margin-top-30">
                        <FormattedNumberInput
                            change={handleChange}
                            id="amount-to-cash-out"
                            error={amountToCashOut.error}
                            placeholder="Chips to Cash Out"
                            iconElement={<StarsIcon />}
                        />
                        <p>1 chip : {clubConfig.chipValue} {clubConfig.currency}</p>
                        <p>This is equivalent to { Number(amountToCashOut.value ? amountToCashOut.value.replace(',','') : 0 ) * clubConfig.chipValue } { clubConfig.currency }.</p>
                    </div>

                    <div className="max-width-500 margin-top-30">
                        <InputGroup
                            change={handleChange}
                            error={bankName.error}
                            iconElement={<AccountBalanceIcon />}
                            id="bank-name"
                            type="text"
                            valid={bankName.valid}
                            validatedProps={{}}
                            timeStamp={timeStamp}
                            placeholder="Bank Name"
                        />
                    </div>

                    <div className="max-width-500 margin-top-30">
                        <InputGroup
                            change={handleChange}
                            error={accountName.error}
                            iconElement={<AccountBalanceWalletIcon />}
                            id="account-name"
                            type="text"
                            valid={accountName.valid}
                            validatedProps={{}}
                            timeStamp={timeStamp}
                            placeholder="Account Name"
                        />
                    </div>

                    <div className="max-width-500 margin-top-30">
                        <InputGroup
                            change={handleChange}
                            error={accountNumber.error}
                            iconElement={<FormatListNumberedIcon />}
                            id="account-number"
                            type="text"
                            valid={accountNumber.valid}
                            validatedProps={{}}
                            timeStamp={timeStamp}
                            placeholder="Account Number"
                        />
                    </div>

                    <p className="margin-top-20 cash--out--disclaimer" style={{color: 'var(--dark-red)'}}>
                        Before clicking submit verify all information is correct. We are not liable for lost funds due to incorrect bank details.
                    </p>

                    <Button text={'Submit'} size="small" margin="margin-top-20" />
                </form>
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [linkedAccounts, user]})
)(CashOut);
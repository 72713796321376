import React from 'react';
import './DataCard.scss';

import {Line} from 'react-chartjs-2';



interface Props {
    icon: JSX.Element;
    label: string;
    count: number;
    updateDetails: JSX.Element;
    chartColor: string;
    chartData: Array<number>;
}

function DataCard(props: Props) {
    return (
        <div className="DataCard">
            <div className="count--row">
                <div className="icon--label">
                    {props.icon}
                    <span>{props.label}</span>
                </div>
                <span className="count">{props.count}</span>
            </div>
            <div className="update--row">
                {props.updateDetails}
            </div>
            <div className="chart">
                <LineChart backgroundColor={props.chartColor} data={props.chartData} />
            </div>
        </div>
    )
}

interface LineChartProps {
    backgroundColor: string;
    data: Array<number>;
}

function LineChart({backgroundColor, data}: LineChartProps) {
    const template = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                fill: true,
                backgroundColor: backgroundColor,
                borderColor: 'transparent',
                pointRadius: 0,
                data: data,
            }
        ]
    };

    const options = {
        layout: {
            padding: 0
        },
        scaleShowLabels: false,
        // maintainAspectRatio: false,
        responsive: true,
        legend: {
            display: false
        },
        tooltips: {
            enabled: false
        },
        scales: {
            xAxes: [{
                ticks: {
                  display: false,
                },
                gridLines: {
                    display:false
                }
            }],
            yAxes: [{
                ticks: {
                    display: false
                },
                gridLines: {
                    display:false
                }
            }]
        }
    }

    return (
        <Line
            data={template}
            options={options}
            height={70}
        />
    )
}

export default DataCard;
import React, {useRef, useEffect, useState} from 'react';
import './CarouselStyle.scss';
import useInterval from '@use-it/interval';

import { 
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@material-ui/icons';

interface Props {
    carouselItemWidthUpdate?: any;
    type?: string;
    images: Array<string>;
    margin?: string;
    styleAttribute?: Object;
}

function Carousel(props: Props) {
    const carouselRef = useRef<HTMLDivElement>(null!);
    const imageRef = useRef<HTMLImageElement>(null!);
    const itemContainerRef = useRef<HTMLUListElement>(null!);
    const listItemRef = useRef<HTMLLIElement>(null!);
    
    const [activeIndex, setActiveIndex] = useState(0);
    const [itemWidth, setItemWidth] = useState(0);

    useEffect(() => {
        const rect = imageRef.current.getBoundingClientRect();
        setItemWidth(rect.width);
    }, [props.images]);

    const setDimensions = () => {
        const imageRect = imageRef.current.getBoundingClientRect();
        itemContainerRef.current.style.height = imageRect.height + 'px';
    }

    const imageLoad = () => {
        setDimensions();
    }

    const navigateCarousel = (e: React.MouseEvent) => {
        const target = e.target as HTMLButtonElement;
        const direction = target.closest('button')!.dataset.direction 
        switch(direction) {
            case 'previous':
                    setActiveIndex(activeIndex - 1);
                break;
            case 'next':
                    setActiveIndex(activeIndex + 1);
                break;
            default:
                throw new Error(`${direction} is invalid`);
        }
    }

    useEffect(() => {
        const rect = listItemRef.current.getBoundingClientRect();
        setItemWidth(rect.width);
        setActiveIndex(0);

    }, [props.carouselItemWidthUpdate])
    
    useEffect(() => {
        if (activeIndex === props.images.length) {
            setActiveIndex(0);
        }
        const left = listItemRef.current.style.left;
        itemContainerRef.current.style.transform = `translateX(-${left})`;
        itemContainerRef.current.style.height = imageRef.current.getBoundingClientRect().height + 'px';
        setDimensions();
    }, [activeIndex, props.images.length, props.carouselItemWidthUpdate]);

    useInterval(() => {
        if (activeIndex < props.images.length) {
            setActiveIndex(activeIndex + 1);
        } else {
            setActiveIndex(0);
        }
    }, 5000)

    return (
        <div className={`Carousel ${props.margin}`} ref={carouselRef} style={props.styleAttribute}>
            <button
                data-direction="previous"
                className="navigation--button previous--button ripple"
                onClick={navigateCarousel}
                disabled={activeIndex === 0}>
                <ChevronLeftIcon/>
            </button>
            <button 
                data-direction="next"
                className="navigation--button next--button ripple" 
                onClick={navigateCarousel}
                disabled={props.images.length === 0}
                >
                <ChevronRightIcon/>
            </button>
            <ul className="item--container" ref={itemContainerRef}>
                {props.images.map((el, index) => (
                    <li 
                        ref={index === activeIndex ? listItemRef : null}
                        key={index}
                        style={{
                            left: `${index * itemWidth}px`,
                        }}>
                        <img src={el} alt="item" 
                            ref={index === activeIndex ? imageRef : null} onLoad={imageLoad}
                        />
                    </li>
                ))}
                <li
                ref={activeIndex === props.images.length ? listItemRef : null}
                style={{
                    left: `${itemWidth * props.images.length}px`
                }}>
                <img 
                src={props.images[0]}
                ref={props.images.length === activeIndex ? imageRef : null}
                alt="alternative"/>
                </li>
            </ul>
            <div className="slideControls">
                {props.images.map((el, index) => (
                    <button key={index}
                        className={`${index === activeIndex ? 'active' : ''}`}
                        onClick={() => setActiveIndex(index)}>
                        <span></span>
                    </button>
                ))}
            </div>
        </div>
    )
}

export default Carousel;
import React, {useState, useEffect} from 'react';
import './ClubMemberships.scss';
import Heading from 'elements/Heading/Heading';
import Table from 'elements/Table/Table';
import axios from 'auxiliary/axios';

interface Membership {
    name: string;
    identifier: string;
    platform: string;
    date_joined: string;
    url: string;
}

function ClubMemberships() {
    const [memberships, setMemberships] = useState<Array<Membership>>([]);
    const [requestLoading, setRequestLoading] = useState(true);

    useEffect(function getMemberships() {
        const init = async () => {
            const response = await axios.get('/members/memberships');
            setRequestLoading(false);
            setMemberships(response.data.memberships);
        }
        init();
    }, [])

    return (
        <div className="ClubMemberships">
            <Heading text="Memberships" level="two" alignment="center" />
            <Table
                progressIndicatorVisible={requestLoading}
                headers={['Name', 'ID', 'Platform', 'Joined', 'Url']}
                content={
                    memberships.map((mem) => [
                        mem.name, 
                        mem.identifier,
                        mem.platform,
                        mem.date_joined,
                        <a className="club--link" href={`http://${mem.url}`} target="_blank" rel="noreferrer noopener">{mem.url}</a>
                    ])
                }
                margin="margin-top-30"
            />
        </div>
    )
}

export default ClubMemberships;
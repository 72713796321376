import React, {useContext} from 'react';
import './InGameAccountsList.scss';
import Table from 'elements/Table/Table';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {linkedAccounts, user} from 'auxiliary/state';

import Button from 'elements/Button/Button';
import Heading from 'elements/Heading/Heading';
import IconButton from 'elements/IconButton/IconButton';
import InGameAccountAdd from '../InGameAccountsAdd/InGameAccountsAdd';
import InGameAccountEdit from '../InGameAccountEdit/InGameAccountEdit';
import RemoveAccount from './RemoveAccount/RemoveAccount';

import {ModalContext} from 'providers/ModalProvider';

import {UserStateInterface} from 'redux/reducers/user-reducer';

import {
    AddBox as AddBoxIcon,
    LinkOff as LinkOffIcon,
    Edit as EditIcon
} from '@material-ui/icons';

interface Props {
    linkedAccounts: Array<any>;
    location: {
        state: {
            message: string;
            inGameAccountNoticeState: string;
        }
    }
    user: UserStateInterface;
}

function InGameAccountsList(props: Props) {
    const modal = useContext(ModalContext);

    const unlinkAccountHandler = (accountID: string) => {
        modal.setData({
            ...modal.data,
            header: 'Confirm',
            content: <RemoveAccount accountID={accountID} />
        });
        modal.toggle();
    }

    const addAccountHandler = () => {
        modal.setData({
            header: 'Linked Account',
            content: <InGameAccountAdd />
        })
        modal.show();
    }

    const editAccountHandler = (inGameAccount: any) => {
        modal.setData({
            header: 'Edit Account',
            content: <InGameAccountEdit inGameAccount={inGameAccount} />
        })
        modal.show();
    }

    return (
        <div className="InGameAccountsList">
            <Heading text="Accounts List" level="two" alignment="center" />
            <IconButton
                className="add--in--game--account"
                iconElement={<AddBoxIcon />}
                clickHandler={addAccountHandler}
            />
            <Table
                headers={['ID', 'Username', 'Actions']}
                content={
                    props.linkedAccounts.map((element, index) => [
                        element.game_id, 
                        element.username, 
                        <div key={index} className="account--actions">
                            <Button 
                                buttonType="text" 
                                text="Edit" 
                                size="small"
                                color="var(--status--success--color)"
                                iconElement={<EditIcon />}
                                clickHandler={() => editAccountHandler(element)}
                            />

                            <Button 
                                buttonType="text" 
                                text="Unlink" 
                                size="small"
                                color="dark-red"
                                iconElement={<LinkOffIcon />}
                                clickHandler={() => unlinkAccountHandler(element.id)}
                            />
                        </div>
                    ])
                }
            />
        </div>
        
    )
}

export default compose(
    withStoreConnection({stateProps: [linkedAccounts, user]})
)(InGameAccountsList);
import React, {useState} from 'react';

interface Props {
    children: JSX.Element | Array<JSX.Element>
}

type visiblity = 'visible' | 'hidden';

function LoadingScreenProvider({children}: Props) {
    const [visibility, setVisibility] = useState<visiblity>('hidden');

    const state = {
        visibility: visibility,
        show: show,
        hide: hide
    }

    function show() {
        setVisibility('visible');
    }

    function hide() {
        setVisibility('hidden');
    }

    return (
        <LoadingScreenContext.Provider value={state}>
            {children}
        </LoadingScreenContext.Provider>
    )
}

export interface LoadingScreenContextInterface {
    visibility: visiblity;
    show(): void;
    hide(): void;
}
export const LoadingScreenContext = React.createContext<LoadingScreenContextInterface>(null!);
export default LoadingScreenProvider;
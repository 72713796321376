import {useCallback, useEffect} from 'react';

interface State {
    value: string;
    valid: boolean;
    error: string;
}

interface Params {
    password: State;
    passwordConfirmation: State;
    setPasswordConfirmation(value: React.SetStateAction<State>): void;
}

export function usePasswordEqualityChecker({password, passwordConfirmation, setPasswordConfirmation}: Params) {
    const checkPasswordEquality = useCallback(() => {
        if (password.value !== passwordConfirmation.value) {
            setPasswordConfirmation((previousValue: State) => ({...previousValue, valid: false, error: 'The passwords do not match.'}));
        } else {
            setPasswordConfirmation(previousValue => ({...previousValue, valid: true, error: ''}));
        }
    }, [password.value, passwordConfirmation.value, setPasswordConfirmation])

    useEffect(() => {
        checkPasswordEquality();
    }, [password.value, passwordConfirmation.value, checkPasswordEquality]);
}
import React from 'react';
import './App.css';

import {compose} from 'redux';
import withStoreConnection from './hoc/withStoreConnection';
import {user} from './auxiliary/state';

import {UserStateInterface} from './redux/reducers/user-reducer';
import LoadingScreenProvider, {LoadingScreenContext} from 'elements/LoadingScreen/LoadingScreenProvider';
import LoadingScreen from 'elements/LoadingScreen/LoadingScreen';

import SnackbarProvider, {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

import MemberTemplate from './templates/MemberTemplate';
import OwnerTemplate from './templates/OwnerTemplate';
import Snackbar from 'elements/Snackbar/Snackbar';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

declare global {
    interface Window { 
        Tawk_API: any; 
        Tawk_LoadStart: any;
    }
}

interface Props {
    user: UserStateInterface;
}

function App(props: Props) {
    const THEME = createMuiTheme({
      typography: {
        fontFamily: [
          'Muli',
          'sans-serif'
        ].join(','),
      }
    });

    return (
        <ThemeProvider theme={THEME}>
            <LoadingScreenProvider>
                <LoadingScreenContext.Consumer>
                    {(loadingScreenContext) =>
                    <SnackbarProvider>
                        <SnackbarContext.Consumer>
                            {(snackbarContext) => 
                                <React.Fragment>
                                    <LoadingScreen loadingScreenContext={loadingScreenContext} />
                                    <Snackbar context={snackbarContext} />
                                    {props.user.role === 'owner' ? <OwnerTemplate /> : <MemberTemplate {...props} />}
                                </React.Fragment>
                            }
                        </SnackbarContext.Consumer>
                    </SnackbarProvider>
                    }
                </LoadingScreenContext.Consumer>
            </LoadingScreenProvider>
        </ThemeProvider>
    )
}

export default compose(
    withStoreConnection({stateProps: [user]})
)(App);
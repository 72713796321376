import React, {useState, useContext} from 'react';
import OwnerAccountForm, {FormSubmitResult} from '../owner-accounts-elements/OwnerAccountForm/OwnerAccountForm';
import axios from 'auxiliary/axios';
import {storeSetOwnerBankAccounts} from 'auxiliary/dispatch';
import {ownerBankAccounts} from 'auxiliary/state';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';

import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import {ModalContext} from 'providers/ModalProvider';

interface Props {
    storeSetOwnerBankAccounts(params: OwnerBankAccountsState): void;
    ownerBankAccounts: OwnerBankAccountsState;
}

function OwnerAccountsAddAccount(props: Props) {
    const snackbar = useContext(SnackbarContext);
    const modal = useContext(ModalContext);
    const [buttonLoading, setButtonLoading] = useState(false);

    const submitForm = async (result: FormSubmitResult) => {
        try {
            setButtonLoading(true);
            const response = await axios.post('/bank_accounts', result);
            setTimeout(() => {
                setButtonLoading(false);
                modal.hide();
                snackbar.show({text: 'Bank account succesfully added'});
                props.storeSetOwnerBankAccounts({
                    bankAccounts: [
                        ...props.ownerBankAccounts.bankAccounts,
                        {
                            id: response.data.id,
                            country: response.data.country,
                            accountName: response.data.account_name,
                            accountNumber: response.data.account_number,
                            bankName: response.data.bank_name
                        }
                    ],
                    pagination: {
                        ...props.ownerBankAccounts.pagination,
                        totalCount: props.ownerBankAccounts.pagination.totalCount! + 1
                    }
                })
            }, 2000); 
        } catch(error) {
            setButtonLoading(false);
            console.log('failed to add bank account')
        }
    }
    return (
        <OwnerAccountForm
            formSubmitHandler={submitForm}
            buttonLoading={buttonLoading}
        />
    )
}

export default compose(
    withStoreConnection({dispatchProps: [storeSetOwnerBankAccounts], stateProps: [ownerBankAccounts]})
)(OwnerAccountsAddAccount)
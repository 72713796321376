import React from 'react';
import Container from 'elements/Container/Container';
function OwnerPromotions() {
    return (
        <Container>
            <div className="OwnerPromotions">
                Promotions
            </div>
        </Container>
    )
}

export default OwnerPromotions;
import React from 'react';
import './TabElement.scss';
import {NavLink, Switch, Route, useRouteMatch, useLocation} from 'react-router-dom';

interface Navigator {
    path: string;
    icon: JSX.Element;
    text: string;
    element: JSX.Element;
}

interface Props {
    contentWidthConstrain: number;
    navigators: Array<Navigator>
}

function TabElement(props: Props) {
    const {path} = useRouteMatch();
    const location = useLocation();
    return (
        <div className="TabElement">
            <ul className="tab--element__navigation">
                {props.navigators.map((element, index) => {
                    return (
                        <li key={index}>
                            <NavLink to={element.path}
                                className={`tab--element__navigator ${index === 0 ? location.pathname === path ? 'active' : '' : ''}`}>
                                {element.icon}
                                <span style={{textTransform: 'capitalize'}}>{element.text}</span>
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
            <div 
                className={`tab--element__content padding-top-bottom-30 margin-left-right-auto`}
                style={{maxWidth: `${props.contentWidthConstrain}px`}}>
                {props.navigators.map((element, index) => {
                    return (
                        <Switch key={index}>
                            <Route exact path={path}>
                                {index === 0 ? props.navigators[0].element : null}
                            </Route>
                            <Route path={element.path}>
                                {element.element}
                            </Route>
                        </Switch>
                    )
                })}
            </div>
        </div>
    )
}

export default TabElement;
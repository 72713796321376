import {PaginationInterface, ActionInterface} from '../interfaces';

const initialState = {
    cashOuts: [],
    pagination: {
        nextUrl: '',
        previousUrl: '',
        currentPage: null,
        pages: null,
        totalCount: null,
        searchQuery: null
    },
    progressIndicatorVisible: false
}

const actions = {
    SET_MEMBER_CASH_OUTS: 'SET_MEMBER_CASH_OUTS',
    REMOVE_MEMBER_CASH_OUTS: 'REMOVE_MEMBER_CASH_OUTS'
}

function reducer(state = initialState, action: ActionInterface<MemberCashOutState>) {
    switch(action.type) {
        case actions.SET_MEMBER_CASH_OUTS:
            return {
                ...state,
                ...action.payload
            };
        case actions.REMOVE_MEMBER_CASH_OUTS:
            return {
                ...initialState
            };
        default: {
            return state;
        }
    }
}

export interface MemberCashOutState {
    cashOuts: Array<any>,
    pagination: PaginationInterface;
    progressIndicatorVisible: boolean;
}

export default reducer;
import React, {useContext, useRef, useState} from 'react';
import './InGameAccountEdit.scss';
import InGameAccountsForm from '../in-game-accounts-elements/InGameAccountsForm';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeSetLinkedAccounts} from 'auxiliary/dispatch';
import {linkedAccounts} from 'auxiliary/state';

import {FormParams} from '../in-game-accounts-elements/InGameAccountsForm';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import {ModalContext} from 'providers/ModalProvider';

import axios from 'auxiliary/axios';

interface Props {
    accountID: string;
    linkedAccounts: Array<any>;
    storeSetLinkedAccounts(params: Array<any>): void;
    inGameAccount: any
}

function InGameAccountEdit(props: Props) {
    const snackbar = useContext(SnackbarContext);
    const modal = useContext(ModalContext);
    const componentRef = useRef<HTMLDivElement>(null!);
    const [buttonLoading, setButtonLoading] = useState(false);

    const submitForm = async (result: FormParams) => {
        const data = {
            game_id: result.gameID,
            username: result.username
        }
        try {
            setButtonLoading(true);
            const response = await axios.patch(`/account/linked_accounts/${result.id}`, data);
            const accounts = [...props.linkedAccounts];
            const index = accounts.findIndex(el => el.id === response.data.id);
            
            accounts[index] = {
                id: response.data.id,
                game_id: response.data.game_id,
                username: response.data.username
            }

            props.storeSetLinkedAccounts(accounts);
            setTimeout(() => {
                snackbar.show({text: 'In Game Account successfully updated'});
                modal.hide();
            }, 1000)
        } catch(error) {
            console.log('Failed to update linked account; error: '+ error);
        } finally {
            setTimeout(() => {
                if (componentRef.current) setButtonLoading(false);
            }, 1000)
        }
    }

    return (
        <div className="InGameAccountEdit" ref={componentRef}>
            <InGameAccountsForm
                formSubmitHandler={submitForm}
                inGameAccount={props.inGameAccount}
                loading={buttonLoading}
            />
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [linkedAccounts], dispatchProps: [storeSetLinkedAccounts]})
)(InGameAccountEdit);
import {PaginationInterface, ActionInterface} from './interfaces';

const initialState = {
    bankAccounts: [],
    pagination: {
        nextUrl: '',
        previousUrl: '',
        currentPage: null,
        pages: null,
        totalCount: null,
        searchQuery: null
    }
}

const actions = {
    SET_OWNER_BANK_ACCOUNTS: 'SET_OWNER_BANK_ACCOUNTS',
    REMOVE_OWNER_BANK_ACCOUNTS: 'REMOVE_OWNER_BANK_ACCOUNTS'
}

function reducer(state = initialState, action: ActionInterface<OwnerBankAccountsState>) {
    switch(action.type) {
        case actions.SET_OWNER_BANK_ACCOUNTS:
            return {
                ...state,
                ...action.payload
            };
        case actions.REMOVE_OWNER_BANK_ACCOUNTS:
            return {
                ...initialState
            };
        default: {
            return state;
        }
    }
}

export interface OwnerBankAccountsState {
    bankAccounts: Array<any>;
    pagination: PaginationInterface;
}

export default reducer;
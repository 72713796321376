import React, {useState, useContext} from 'react';
import './ReferralSettings.scss';
import FormHeader from '../components/FormHeader/FormHeader';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import axios from 'auxiliary/axios';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import {pageConfiguration} from 'auxiliary/state';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';


import {compose} from 'redux';
import {user} from 'auxiliary/state';
import {storeSetUser, storeSetToken, storeSetPageConfiguration} from 'auxiliary/dispatch';
import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import {UserStateInterface} from 'redux/reducers/user-reducer';

import {Code as CodeIcon} from '@material-ui/icons';

interface Props {
    user: UserStateInterface;
    storeUserInformation(params: {data: UserStateInterface}): void;
    storeSetPageConfiguration(params: PageConfigurationState): void;
    pageConfiguration: PageConfigurationState;
}


interface GamePlatform {
  id: number;
  name: string;
}

function ReferralSettings({user, pageConfiguration, storeUserInformation, storeSetPageConfiguration}: Props) {    
    console.log(pageConfiguration)
    const snackbar = useContext(SnackbarContext);
    const initialState = {value: user.referralCode, valid: true, error: ''};
    const [referralCode, setReferralCode] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonLoading2, setButtonLoading2] = useState(false);
    const [isStrictRefferals, setIsStrictRefferals] = React.useState(pageConfiguration.organizationStrictReferrals);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'referral-code':
                    setReferralCode(newState);
                break;
            default:
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

  
    const formSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        const result = [
            referralCode
        ].map(e => e.valid);

        const requestData = {
            code: referralCode.value,
        }

        if (result.every(valid => valid)) {
            try {
                setButtonLoading(true);
                const response = await axios.post(`/account/update_referral_code`, requestData);
                setTimeout(() => {
                    storeUserInformation(response);
                    setButtonLoading(false);
                    snackbar.show({text: 'Your referral code has been updated'});
                }, 2500)
            } catch(error) {
                setTimeout(() => {
                    setButtonLoading(false);
                    const errorMessage = error.response.data.error;
                    setReferralCode({...referralCode, valid: false, error: errorMessage});
                }, 2500)
            }
        }
    }

    const formStrictReferralsHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());


        try {
            setButtonLoading2(true);
            const response = await axios.patch(`/`, {organization: {strict_referrals: isStrictRefferals}});
            setTimeout(() => {
                console.log(response)
                const updatedConfiguration: PageConfigurationState = {
                  ...pageConfiguration,
                  organizationIdentifier: response.data.identifier,
                  organizationName: response.data.name,
                  organizationGamePlatform: {name: response.data.game_platform.name},
                  organizationStrictReferrals: response.data.strict_referrals,
                  carouselSectionEditMode: {...pageConfiguration.carouselSection},
                  gameImagesEditMode: {...pageConfiguration.gameImages},
                  detailsSectionEditMode: JSON.parse(JSON.stringify(pageConfiguration.detailsSection)),
                  headerEditMode: {
                      logo: {...pageConfiguration.header}['logo'] 
                  }
                }
                storeSetPageConfiguration(updatedConfiguration);
                setButtonLoading2(false);
                snackbar.show({text: 'Your strict referrals has been updated'});
            }, 2500)
        } catch(error) {
            setTimeout(() => {
                setButtonLoading2(false);
                const errorMessage = error.response.data.error;
            }, 2500)
        }
    }

    return (
        <div className="ReferralSettings">
            <form onSubmit={formSubmitHandler} className="navigation--content--form margin-bottom-20">
                <FormHeader
                        buttonLoading={buttonLoading}
                        label="Change Referral Code"
                        description="Change your account referral code"/>
                <div className="form--input">
                    <div className="referral--code--update">
                        <InputGroup
                            autofocus={true}
                            change={handleChange}
                            error={referralCode.error}
                            iconElement={<CodeIcon />}
                            id="referral-code"
                            placeholder="Referral Code"
                            margin="margin-top-10"
                            valid={referralCode.valid}
                            initialValue={referralCode.value!}
                            validatedProps={{minLength: 8}}
                            timeStamp={timeStamp}
                        />
                    </div>
                </div>
            </form>

            <form onSubmit={formStrictReferralsHandler} className="navigation--content--form">
                <FormHeader
                    buttonLoading={buttonLoading2}
                    label="Toggle Strict Referrals"
                    description="If enabled, all new members must be referred by existing members of your club. If disabled, new members can sign up without providing a referral code."/>
                <FormControl component="fieldset" className="toggle-switch">
                    <FormControlLabel
                        control={<Switch checked={isStrictRefferals} onChange={e => setIsStrictRefferals(!isStrictRefferals)} />}
                        label={isStrictRefferals ? "On" : "Off"}
                    />
                </FormControl>
            </form>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user, pageConfiguration], dispatchProps: [storeSetToken, storeSetUser, storeSetPageConfiguration]}),
    withUserInformationStorage
)(ReferralSettings);
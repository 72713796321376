import React, {useContext, useState, useRef} from 'react';
import './RemoveAccount.scss';
import Button from 'elements/Button/Button';
import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import axios from 'auxiliary/axios';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {linkedAccounts} from 'auxiliary/state';
import {storeSetLinkedAccounts} from 'auxiliary/dispatch';

interface Props {
    accountID: string;
    linkedAccounts: Array<any>;
    storeSetLinkedAccounts(params: Array<any>): void;
}

function RemoveAccount(props: Props) {
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);
    const [buttonLoading, setButtonLoading] = useState(false);
    const componentRef = useRef<HTMLDivElement>(null!);

    const removeAccountHandler = async () => {
        try {
            setButtonLoading(true);
            await axios.delete(`/account/linked_accounts/${props.accountID}`);  

            setTimeout(() => {
                const accounts = [...props.linkedAccounts];
                const index = accounts.findIndex(el => el.id === props.accountID);
                accounts.splice(index, 1);
                props.storeSetLinkedAccounts(accounts);
                modal.hide();
                snackbar.show({text: 'Account successfully unlinked'});
            }, 1000)
        } catch(error) {
            modal.hide();
            setTimeout(() => {
                modal.setData({
                    header: 'Error',
                    content: {
                        template: 'error',
                        text: 'You may not unlink the last of your in-game accounts'
                    }
                })
                modal.show();
            }, 350)
            console.log('Failed to unlink In Game Account; error: ' + error);
        } finally {
            setTimeout(() => {
                if (componentRef.current) setButtonLoading(false);
            }, 1000)
        }
    }
    
    return (
        <div className="RemoveAccount" ref={componentRef}>
            <div className="modal--data--content">
                <p>Are you sure you want to unlink this account?</p>
                <div className="confirmation--buttons margin-top-20">
                    <Button
                        size="small" 
                        buttonType="text" 
                        text="Cancel" 
                        color="dark-red"
                        className="cancel"
                        clickHandler={
                            () => {
                                modal.hide();
                            }
                        }
                        margin="margin-right-20"
                    />

                    <Button 
                        width="60px"
                        text="Yes" 
                        backgroundColor="dark--red"
                        clickHandler={removeAccountHandler}
                        loading={buttonLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [linkedAccounts], dispatchProps: [storeSetLinkedAccounts]})
)(RemoveAccount)
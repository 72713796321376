import React, {useEffect, useContext, useRef, useCallback} from 'react';
import './Modal.scss';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from 'elements/IconButton/IconButton';
import Button from 'elements/Button/Button';

interface Props {
    context: React.Context<ModalContextInterface>;
}

interface TemplateContent {
    template: 'error';
    text: string;
}

function Modal(props: Props) {
    const modalRef = useRef<HTMLDivElement>(null!);
    const windowRef = useRef<HTMLDivElement>(null!);
    const context = useContext(props.context);
    useEffect(() => { 
        if (context.visible) {
            modalRef.current.style.zIndex = '15';
            setTimeout(() => {
                if (modalRef.current) modalRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.3)';
            }, 100);
        } else {
            modalRef.current.style.backgroundColor = 'transparent';
            setTimeout(() => {
                if (modalRef.current) modalRef.current.style.zIndex = '-1';
            }, 280);
        }
    }, [context.visible]);

    useEffect(() => {
        if (context.visible) {
            windowRef.current.style.top = '50%';
            windowRef.current.style.opacity = '1';
        } else {
            windowRef.current.style.top = '43%';
            windowRef.current.style.opacity = '0';
        }
    }, [context.visible])

    const closeModal = useCallback((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            context.hide();
        }
    }, [context])

    useEffect(() => {
        window.addEventListener('keyup', closeModal);
        return () => {
            window.removeEventListener('keyup', closeModal);
        }
    }, [closeModal]);

    const modalClickHandler = (e: React.MouseEvent) => {
        if (e.target === modalRef.current) context.hide();
    }

    return (
        <div className="Modal" ref={modalRef} onClick={modalClickHandler}>
            <div className="modal--window" ref={windowRef}>
                <div className="modal--header">
                    <span>{context.data ? context.data.header || 'Confirm' : 'Confirm'}</span>
                    <IconButton 
                        color="var(--dark-text)"
                        iconElement={<CloseIcon />} 
                        clickHandler={() => {context.hide()}}
                    />
                </div>
                <div className="modal--content">
                    {
                        context.data.content.hasOwnProperty('template') ? 
                        <div className="modal--data--content">
                            <p>{(context.data.content as TemplateContent).text}</p>
                            <div className="confirmation--buttons margin-top-20">
                                <Button 
                                    width="80px"
                                    text="Ok"
                                    backgroundColor="accent--three"
                                    clickHandler={
                                    () => {
                                        context.hide();
                                    }
                                } />
                            </div>
                        </div>
                        :
                        context.data.content
                    }
                </div>
            </div>
        </div>
    )
}

export interface ModalDataInterface {
    header: string;
    content: JSX.Element | TemplateContent;
    confirmationText?: string;
}

export interface ModalContextInterface {
    visible: boolean;
    toggle(): void;
    data: ModalDataInterface;
    setData(params: ModalDataInterface): void;
    hide(): void;
    show(): void;
}

export default Modal;
export const storeSetToken = 'storeSetToken';
export const storeRemoveToken = 'storeRemoveToken';
export const storeSetUser = 'storeSetUser';
export const storeRemoveUser = 'storeRemoveUser';
export const storeVerifyUser = 'storeVerifyUser';

export const storeSetLinkedAccounts = 'storeSetLinkedAccounts';
export const storeRemoveLinkedAccounts = 'storeRemoveLinkedAccounts';

export const storeSetOwnerMembers = 'storeSetOwnerMembers';
export const storeRemoveOwnerMembers = 'storeRemoveOwnerMembers';

export const storeSetOwnerBankAccounts = 'storeSetOwnerBankAccounts';
export const storeRemoveOwnerBankAccounts = 'storeRemoveOwnerBankAccounts';

export const storeSetMemberPurchaseRequests = 'storeSetMemberPurchaseRequests';
export const storeSetMemberCashOuts = 'storeSetMemberCashOuts';

export const storeSetPageConfiguration = 'storeSetPageConfiguration';
import React, {useState} from 'react';
import {ModalContextInterface, ModalDataInterface} from 'elements/Modal/Modal';

interface Props {
    children: JSX.Element | Array<JSX.Element>
}

function ModalProvider(props: Props) {   
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState<ModalDataInterface>({header: 'Success', content: <div></div>});

    function toggle() {
        setVisible(!visible);
    }

    function hide() {
        setVisible(false);
    }

    function show() {
        setVisible(true);
    }

    const state = {
        visible: visible,
        toggle: toggle,
        data: data,
        setData: setData,
        hide: hide,
        show: show
    }

    return (
        <ModalContext.Provider value={state}>
            {props.children}
        </ModalContext.Provider>
    )
}

export const ModalContext = React.createContext<ModalContextInterface>(null!);
export default ModalProvider;
import React, {useState, useEffect} from 'react';
import './OwnerGeneral.scss';
import Container from 'elements/Container/Container';
import DataCard from './DataCard/DataCard';
import ClubData from './ClubData/ClubData';
import {initialCardData} from './initial-card-data';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';

import {UserStateInterface} from 'redux/reducers/user-reducer';
import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';

import {headerInterceptor, errorInterceptor} from 'auxiliary/axios';
import axiosMain from 'axios';

import {
    MenuBook as MenuBookIcon,
    ViewAgenda as ViewAgendaIcon,
    Smartphone as SmartphoneIcon,
    Stars as StarIcon,
    AttachMoney as CurrencyIcon
} from '@material-ui/icons';
 
interface Props {
    pageConfiguration: PageConfigurationState;
    user: UserStateInterface;
    storeSetPageConfiguration(params: PageConfigurationState): void; 
}

interface Club {
    id: number;
    name: string;
    identifier: string;
    currency: string;
    chip_value: number;
    game_platform: {
        name: string;
    }
    url: string;
}

function OwnerGeneral({user}: Props) {
    const [cardData, setCardData] = useState([...initialCardData]);
    const [currentClub, setCurrentClub] = useState<Partial<Club>>(null!);
    const [clubs, setClubs] = useState<Array<Club>>([]);

    const serverUrl = process.env.REACT_APP_BASE_URL!.split('/organizations')[0];
    const axios = axiosMain.create({baseURL: serverUrl});
    axios.interceptors.request.use(headerInterceptor, errorInterceptor);

    useEffect(function getClubs() {
        const init = async () => {
            const response = await axios.get('/organizations');
            setClubs(response.data.organizations);
        }
        init();
    }, [])

    useEffect(function getCurrentClub() {
        const init = async () => {
            const response = await axios.get(process.env.REACT_APP_BASE_URL!);
            setCurrentClub(response.data);
        }
        init();
    }, [])

    return (
        <Container backgroundColor="transparent" paddingOnly={true}>
            <div className="OwnerGeneral">
                <div className="data--card--container">
                    {cardData.map((el, index) => 
                        <DataCard
                            key={index}
                            icon={el.icon}
                            label={el.label}
                            count={el.count}
                            updateDetails={el.updateDetails}
                            chartColor={el.chartColor}
                            chartData={el.chartData}
                        />
                    )}
                </div>
                <section className="clubs">
                    <div className="item">
                        <h2 className="item--header">Current Club</h2>
                        <div className="club--data">
                            <div className="icon--container">
                                <MenuBookIcon />
                            </div>
                            <div className="club--detail">
                                <span className="label">Name</span>
                                <span className="value">{currentClub?.name}</span>
                            </div>
                        </div>

                        <div className="club--data">
                            <div className="icon--container">
                                <ViewAgendaIcon />
                            </div>
                            <div className="club--detail">
                                <span className="label">Identifier</span>
                                <span className="value">{currentClub?.identifier}</span>
                            </div>
                        </div>

                        <div className="club--data">
                            <div className="icon--container">
                                <SmartphoneIcon />
                            </div>
                            <div className="club--detail">
                                <span className="label">Platform</span>
                                <span className="value">{currentClub?.game_platform?.name}</span>
                            </div>
                        </div>

                        <div className="club--data">
                            <div className="icon--container">
                                <CurrencyIcon />
                            </div>
                            <div className="club--detail">
                                <span className="label">Currency</span>
                                <span className="value">{currentClub?.currency}</span>
                            </div>
                        </div>

                        <div className="club--data">
                            <div className="icon--container">
                                <StarIcon />
                            </div>
                            <div className="club--detail">
                                <span className="label">Chip Value</span>
                                <span className="value">{currentClub?.chip_value}</span>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <h2 className="item--header">My Clubs</h2>
                        {clubs.map((el, index) => 
                            <ClubData
                                key={index}
                                name={el.name}
                                identifier={el.identifier}
                                game_platform={el.game_platform.name}
                                url={el.url}
                            />
                        )}
                    </div>
                    <div className="item" style={{opacity: 0}}>
                        <h2 className="item--header">Club Tasks</h2>
                    </div>
                </section>
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [user]})
)(OwnerGeneral);
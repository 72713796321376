import React from 'react';
import './MemberHeader.scss';
import {
    AttachMoney as AttachMoneyIcon,
    Person as PersonIcon, 
    Shop as ShopIcon,
    ExitToApp as ExitToAppIcon,
    Timeline as TimelineIcon,
    SettingsEthernet as SettingsEthernetIcon,
    Home as HomeIcon,
    NotificationImportant as NotificationImportantIcon
} from '@material-ui/icons';

import {Link, NavLink} from 'react-router-dom';
import LogOut from 'elements/LogOut/LogOut';
import Menu from 'elements/Menu/Menu';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {pageConfiguration, user} from 'auxiliary/state';

import {PageConfigurationState} from 'redux/reducers/page-configuration-reducer';
import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    pageConfiguration: PageConfigurationState;
    user: UserStateInterface;
}

function MemberHeader(props: Props) {
    const navigationLinks = [
        {target: '/member', text: 'Home', icon: <HomeIcon />},
        {target: '/member/profile', text: 'Profile', icon: <PersonIcon />},
        {target: '/member/purchase-chips', text: 'Purchase Chips', icon: <ShopIcon />},
        {target: '/member/cash-out', text: 'Cash Out', icon: <AttachMoneyIcon />},
        {target: '/member/transactions', text: 'Transactions', icon: <TimelineIcon />},
        {target: '/member/referral', text: 'Referral', icon: <SettingsEthernetIcon />},
        {target: '/member/promotions', text: 'Promotions', icon: <NotificationImportantIcon />},
    ];

    return (
        <React.Fragment>
            <header className="MemberHeader" 
                style={{backgroundColor: props.pageConfiguration.header ? props.pageConfiguration.header.backgroundColor : ''}}> 
                <figure className="header--logo">
                    <img src={props.pageConfiguration.header.logo as string} alt=""/>
                    <figcaption style={{color: props.pageConfiguration.header.fontColor}}>
                        <div>{props.pageConfiguration.organizationName}</div>
                        <div>#{props.pageConfiguration.organizationIdentifier}</div>
                    </figcaption>
                </figure>
                <div className="header--menu">
                    <div className="mobile--menu">
                        <Menu
                            buttonBackgroundColor={props.pageConfiguration.header.fontColor}
                            menuOptions={
                                [
                                    ...(props.user.verified ? navigationLinks : []).map((el, index) =>     
                                        <Link key={index} to={el.target}>
                                            <div className="link--content">
                                                {el.icon}
                                                <span>{el.text}</span>
                                            </div>
                                        </Link>
                                    ),
                                    <LogOut>
                                        <div className="button--content">
                                            <ExitToAppIcon className="margin-right-10" />
                                            <span>Logout</span>
                                        </div>
                                    </LogOut>
                                ]
                            } 
                        />
                    </div>

                    
                    <ul className="menu--standard">
                        {
                            [
                                ...(props.user.verified ? navigationLinks : []).map((el, index) => 
                                    <li key={index} style={{
                                        color: props.pageConfiguration.header.fontColor,
                                    }}>
                                        <NavLink exact to={el.target}>
                                            {el.text}
                                        </NavLink>
                                        <span className="border" style={{
                                            backgroundColor: props.pageConfiguration.header.fontColor
                                        }}></span>
                                    </li>
                                ),
                                <li key={'nav--logout'}>
                                    <LogOut>
                                        <div className="button--content">
                                            <span style={{color: props.pageConfiguration.header.fontColor}}>Logout</span>
                                        </div>
                                    </LogOut>
                                </li>
                            ]
                        }
                    </ul>
                </div>
            </header>
        </React.Fragment>
    )
}

export default compose(
    withStoreConnection({stateProps: [pageConfiguration, user]})
)(MemberHeader);
import React, {useEffect, useState} from 'react';
import Table from 'elements/Table/Table';
import './OwnerMembersList.scss';

import {useHistory} from 'react-router-dom';
import {OwnerMembersState} from 'redux/reducers/owner/owner-members-reducer';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {ownerMembers} from 'auxiliary/state';
import {storeSetOwnerMembers} from 'auxiliary/dispatch';
import SearchInput, {SearchCallbackResult} from 'elements/SearchInput/SearchInput';
import Container from 'elements/Container/Container';

interface Props {
    retrieveMembers(params: string): void;
    storeSetOwnerMembers(params: Partial<OwnerMembersState>): void;
    ownerMembers: OwnerMembersState;
}

function OwnerMembersList(props: Props) {
    const history = useHistory();
    const [searchQuery, setSearchQuery] = useState<string>(props.ownerMembers.searchQuery!);
    const {storeSetOwnerMembers} = props;

    useEffect(() => {
        storeSetOwnerMembers({
            searchQuery: searchQuery
        })
    }, [searchQuery, storeSetOwnerMembers]);

    const previousPageClickHandler = () => {
        props.retrieveMembers(`/members?page=${props.ownerMembers.pagination.currentPage! - 1}&q=${encodeURIComponent(searchQuery)}`);
    }
    const nextPageClickHandler = () => {
        props.retrieveMembers(`/members?page=${props.ownerMembers.pagination.currentPage! + 1}&q=${encodeURIComponent(searchQuery)}`);
    }

    const searchCallback =  async (result: SearchCallbackResult) => {
        switch (result.eventType) {
            case 'immediate':
                    setSearchQuery(result.value)
                break;
            case 'debounced':
                    props.storeSetOwnerMembers({
                        progressIndicatorVisible: true
                    });
                    props.retrieveMembers(`/members?q=${encodeURIComponent(result.value)}`);
                break;
            default:
                throw new Error(`${result.eventType} is not a valid event type`)
        }
    }
    
    return (
        <Container paddingOnly={true}>
            <div className="OwnerMembersList">
                <Table
                    dataFiltrationComponent={
                        <div className="search--input--container">
                            <SearchInput searchCallback={searchCallback} id="filter-members" value={props.ownerMembers.searchQuery!} margin="margin-bottom-10" />
                        </div>
                    }
                    headers={['Name', 'Email', 'Number', 'Date Registered', 'Referral Code', 'Deposits', 'Withdrawals']}
                    content={
                        props.ownerMembers.members.map(el => [
                            `${el.firstName} ${el.lastName}`,
                            el.email,
                            el.mobileNumber,
                            el.date_registered + ' UTC',
                            el.referralCode,
                            el.totalPurchases,
                            el.totalCashOuts
                        ])
                    }
                    tableRowClickHandler={(rowIndex: number) => {
                        const targetMember = props.ownerMembers.members[rowIndex];
                        history.push(`/club/members/profile/${targetMember.id}`);
                    }}
                    pagination={{
                        pages: props.ownerMembers.pagination.pages!,
                        currentPage: props.ownerMembers.pagination.currentPage!,
                        totalCount: props.ownerMembers.pagination.totalCount!
                    }}
                    previousPageClickHandler={previousPageClickHandler}
                    nextPageClickHandler={nextPageClickHandler}
                    progressIndicatorVisible={props.ownerMembers.progressIndicatorVisible}
                />
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [ownerMembers], dispatchProps: [storeSetOwnerMembers]})
)(OwnerMembersList);
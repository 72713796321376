import React, {useState} from 'react';
import './InGameAccountsForm.scss';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';

import { 
    SportsEsports as SportsEsportsIcon,
    AccountBox as AccountBoxIcon
} from '@material-ui/icons';

interface Props {
    formSubmitHandler(params: FormParams): void;
    inGameAccount?: InGameAccountInterface;
    loading: boolean;
}

function InGameAccountsForm(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [gameID, setGameID] = useState({...initialState, value: props.inGameAccount ? props.inGameAccount.game_id : '', valid: props.inGameAccount ? props.inGameAccount.game_id ? true : false : false});
    const [username, setUsername] = useState({...initialState, value: props.inGameAccount ? props.inGameAccount.username : '', valid: props.inGameAccount ? props.inGameAccount.username ? true : false : false});

    const [timeStamp, setTimeStamp] = useState(0);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'game-id':
                    setGameID(newState);
                break;
            case 'username':
                    setUsername(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        setGameID({...gameID, error: gameID.valid ? '' : gameID.error || 'Game ID is invalid'});
        setUsername({...username, error: username.valid ? '' : username.error || 'Username is invalid' });

        const result = [
            gameID,
            username
        ].map(e => e.valid);

        if (result.every(valid => valid)) {
            props.formSubmitHandler!({
                id: props.inGameAccount ? props.inGameAccount.id : null,
                gameID: gameID.value,
                username: username.value
            })
        }
    }

    return (
        <form onSubmit={submitForm} className="InGameAccountsForm">
            <div className="input--group--container">
                <span className="input--group--icon--label">User ID</span>
                <InputGroup
                    autofocus={true}
                    change={handleChange}
                    error={gameID.error}
                    iconElement={<SportsEsportsIcon />}
                    id="game-id"
                    type="text"
                    valid={gameID.valid}
                    validatedProps={{minLength: 2, numbersOnly: true, maxLength: 24}}
                    placeholder="User ID"
                    timeStamp={timeStamp}
                    initialValue={gameID.value}
                />
            </div>

            <div className="input--group--container">
                <span className="input--group--icon--label">Username</span>
                <InputGroup
                    change={handleChange}
                    error={username.error}
                    iconElement={<AccountBoxIcon />}
                    id="username"
                    type="text"
                    valid={username.valid}
                    placeholder="Username"
                    validatedProps={{minLength: 2, maxLength: 24}}
                    timeStamp={timeStamp}
                    initialValue={username.value}
                />
            </div>

            <Button text="Submit" width="80px" margin="margin-top-20" loading={props.loading} />
        </form>
    )
}

export interface InGameAccountInterface {
    id: string;
    game_id: string;
    username: string;
}

export interface FormParams {
    id: string | null;
    gameID: string;
    username: string;
}

export default InGameAccountsForm;
import React, {useRef, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import parse from 'html-react-parser';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';
import {UserStateInterface} from 'redux/reducers/user-reducer';
import MembershipPrompt from '../../LogIn/MembershipPrompt/MembershipPrompt';
import {ModalContext} from 'providers/ModalProvider';

interface Props {
    isMember: boolean;
    hasSessionTokens: boolean;
    gameImages: any;
    editMode: boolean;
    editedNode: string;
    user: UserStateInterface;
}

function LandingPageGameImages(props: Props) {
    console.log("props-landing page game images: ",  props)
    const componentRef = useRef<HTMLDivElement>(null!);
    const modal = useContext(ModalContext);

    useEffect(() => {
        if (!componentRef.current) return;
        componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        componentRef.current.style.outline = '3px dashed #FFEB3B';
    }, [props.editedNode])

    const outline = '3px dashed #FFEB3B';
    const joinSection = <div className="join--section">
        <div className="action--prompt margin-bottom-10">
            Click below to enter
        </div>
        {
          !props.isMember && props.hasSessionTokens ? 
            <button 
              style={{display: 'block', margin: '0 auto', width: '180px'}} 
              onClick={(e) => {
                e.stopPropagation();
                modal.setData({
                  header: 'Confirm',
                  content: <MembershipPrompt email="" password="" />
                });
                modal.show();} 
              }
              className="action--prompt--link margin-top-10">Join
            </button>
            : 
            <Link style={{display: 'block', margin: '0 auto', width: '180px'}} to={`${props.editMode ? '#' : '/login'}`} className="action--prompt--link margin-top-10">Join</Link>
        }
        
    </div>

    return (
        <div className="game--images padding-20" ref={props.editMode ? props.editedNode === 'gameImages' ? componentRef : null : null}
            style={{
                outline: `${props.editMode ? props.editedNode === 'gameImages' ? outline : '' : null}`,
            }}>
            <div className="game--images__header ql-editor" ref={props.editMode ? props.editedNode === 'gameImages__header' ? componentRef : null : null}
                style={{
                outline:  `${props.editMode ? props.editedNode === 'gameImages__header' ? outline : '' : null}`,
            }}> 
                {parse(props.gameImages.header.content)}    
            </div>
            <div className="images--container padding-20 margin-left-right-auto max-width-1300" 
                ref={props.editMode ? props.editedNode === 'gameImages__images--section' ? componentRef : null : null}
                style={{
                    outline: `${props.editMode ? props.editedNode === 'gameImages__images--section' ? outline : '' : null}`,
                }}
                >
                {props.gameImages.images.map((el: string, index: number) => (
                    <img key={index} src={el} alt=""/>
                ))}
            </div>
            {   
                !props.isMember && props.hasSessionTokens ? joinSection : (
                    props.isMember ? null : (
                      props.editMode ?
                      joinSection
                      : 
                      props.user.email ?
                      null
                      :
                      joinSection 
                    )
                )
            }
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user]})
)(LandingPageGameImages);
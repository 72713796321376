import React, {useState} from 'react';
import './OwnerAccountForm.scss';

import Input from 'elements/Input/Input';
import {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';
import Select, {SelectResult} from 'elements/Select/Select';

interface Props {
    id?: number;
    formSubmitHandler(params: FormSubmitResult): void;
    countryInitialValue?: string;
    bankNameInitialValue?: string;
    accountNameInitialValue?: string;
    accountNumberInitialValue?: string;
    buttonLoading: boolean;
}

function OwnerAccountForm(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [country, setCountry] = useState({...initialState, value: props.countryInitialValue, valid: props.countryInitialValue ? true : false});
    const [bankName, setBankName] = useState({...initialState, value: props.bankNameInitialValue, valid: props.bankNameInitialValue ? true : false});
    const [accountName, setAccountName] = useState({...initialState, value: props.accountNameInitialValue, valid: props.accountNameInitialValue ? true : false});
    const [accountNumber, setAccountNumber] = useState({...initialState, value: props.accountNumberInitialValue, valid: props.accountNumberInitialValue ? true : false});
    const [timeStamp, setTimeStamp] = useState(0);
    
    const handleSelect = (result: SelectResult) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error || ''
        }
        setCountry(newState);
    }

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        
        switch (result.origin) {
            case 'bank-name':
                    setBankName(newState);
                break;
            case 'account-name':
                    setAccountName(newState);
                break;
            case 'account-number':
                    setAccountNumber(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());
        
        setCountry({...country, error: country.valid ? '' : country.error || 'Country is invalid'});
        setBankName({...bankName, error: bankName.valid ? '' : bankName.error || 'Bank name is invalid'});
        setAccountName({...accountName, error: accountName.valid ? '' : accountName.error || 'Account name is invalid'});
        setAccountNumber({...accountNumber, error: accountNumber.valid ? '' : accountNumber.error || 'Account number is invalid'});

        const result = [
            country,
            bankName,
            accountName,
            accountNumber
        ].map(e => e.valid);


        if (result.every(valid => valid)) {
            props.formSubmitHandler({
                id: props.id!,
                country: country.value!,
                bank_name: bankName.value!,
                account_name: accountName.value!,
                account_number: accountNumber.value!
            })
        }
    }

    return (
        <form onSubmit={submitForm} className="OwnerAccountForm">
            <div className="margin-top-30 max-width-300">
                <Select
                    id="country"
                    select={handleSelect}
                    selectContent="countries"
                    selectText="Select a country" 
                    selectColor="var(--owner-accent-one-shade-three)"
                    error={country.error}
                    initialValue={props.countryInitialValue}
                    showSearchbar={true}
                />
            </div>

            <Input
                id="bank-name"
                inputBorderColor="var(--accent-one-shade-three)"
                placeholder="Bank Name"
                changeCallback={handleChange}
                valid={bankName.valid}
                error={bankName.error}
                timeStamp={timeStamp}
                value={bankName.value || ''}
            />

            <Input
                id="account-name"
                inputBorderColor="var(--accent-one-shade-three)"
                placeholder="Account Name"
                changeCallback={handleChange}
                valid={accountName.valid}
                error={accountName.error}
                timeStamp={timeStamp}
                value={accountName.value || ''}
            />

            <Input
                id="account-number"
                inputBorderColor="var(--accent-one-shade-three)"
                placeholder="Account Number"
                changeCallback={handleChange}
                valid={accountNumber.valid}
                error={accountNumber.error}
                timeStamp={timeStamp}
                value={accountNumber.value || ''}
            />

            <Button
                width="70px"
                loading={props.buttonLoading}
            />
        </form>
    )
}

export interface FormSubmitResult {
    id: number;
    country: string;
    bank_name: string;
    account_name: string;
    account_number: string;
}

export default OwnerAccountForm;
import React from 'react';
import {CircularProgress} from '@material-ui/core';

interface Props {
    size: number;
    styleAttribute?: Object
}

function Loading({size, styleAttribute}: Props) {
    return (
        <CircularProgress style={styleAttribute} size={size} />
    )
}

export default Loading;
import React from 'react';
import './MemberMenu.scss';
import {Link} from 'react-router-dom';

import profile from 'assets/images/member-menu/profile.png';
import poker from 'assets/images/member-menu/poker.jpg';
import banking from 'assets/images/member-menu/banking.jpg';
import refer from 'assets/images/member-menu/refer.png';
import visa from 'assets/images/member-menu/visa.jpg';
import promotion from 'assets/images/member-menu/promotion.png';

import Card from 'elements/Card/Card';

function MemberMenu() {
    const cardItems = [
        {
            image: profile,
            title: 'Profile',
            description: 'Manage your personal information, connected accounts and joined clubs.',
            linkText: 'Go to profile',
            target: '/member/profile'
        },
        {
            image: poker,
            title: 'Purchase Chips',
            description: 'Purchase chips for any of your connected accounts.',
            linkText: "Purchase",
            target: '/member/purchase-chips'
        },
        {
            image: banking,
            title: 'Cash Out',
            description:'Request to cash-in your winnings from playing the game.',
            linkText: "Cash out",
            target: '/member/cash-out'
        },
        {
            image: visa,
            title: 'Transactions',
            description: 'View your transactions and track their status.',
            linkText: "View transactions",
            target: '/member/transactions'
        },
        {
            image: refer,
            title: 'Referral',
            description: 'Manage your referral link or grab a copy of it to share to other players.',
            linkText: "Referral",
            target: '/member/referral'
        },
        {
            image: promotion,
            title: 'Promotions',
            description: 'View promotions currently available to the club.',
            linkText: "Promotions",
            target: '/member/promotions'
        },
    ]
    
    return (
        <div className="MemberMenu" style={{minHeight: 'calc(100vh - 120px)'}}>
            <div className="member--menu--content">
                <div className="header--container">
                    <p className="header">Select an Action</p>
                </div>
                <div className="menu--container">
                    {cardItems.map((el, index) => 
                        <Card
                            key={index}
                            image={el.image}
                            title={el.title}
                            description={el.description}
                            action={
                                <Action linkText={el.linkText} target={el.target}  />
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

interface ActionProps {
    linkText: string;
    target: string;
}
function Action({linkText, target}: ActionProps) {
    return (
        <div className="Action">
            <Link className="action--link" to={target}>{linkText}</Link>
        </div>
    )
}

export default MemberMenu;
import React, {useRef, useLayoutEffect, useState, ChangeEvent} from 'react';
import './FileInput.scss';
import placeholder from 'assets/images/image-placeholder.png';
import UploadIcon from '@material-ui/icons/Backup';

interface FileChangeResult {
    origin: string;
    value: File;
    valid: boolean;
}

interface Props {
    error: string;
    fileSelect(params: FileChangeResult): void;
    id: string;
    initialHeight?: number;
    placeholder?: string;
}

function FileInput(props: Props) {
    const image = useRef<HTMLImageElement>(null!);
    const imagePlaceholder = useRef<HTMLDivElement>(null!);
    const [imageHeight, setImageHeight] = useState(0);
    const [imageVisible, setImageVisible] = useState(true);
    
    const [imageSrc, setImageSrc] = useState(props.placeholder ? props.placeholder : placeholder);

    const fileChange = (e: ChangeEvent) => {
        const fileReader = new FileReader();
        const target = e.target as HTMLInputElement;
        fileReader.onload = function() {
            const url = fileReader.result as string;
            setImageVisible(false);
            setImageSrc(url);
            setTimeout(() => {
                setImageVisible(true);
            }, 440);
        }
        fileReader.readAsDataURL(target.files![0]);

        const result = {
            origin: props.id,
            value: target.files![0],
            valid: target.files![0] ? true : false,
        }

        props.fileSelect(result);
    }

    const imgLoad = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLImageElement;
        const rect = target.getBoundingClientRect();
        setImageHeight(rect.height);
    }

    useLayoutEffect(() => {
        imagePlaceholder.current.style.height = imageHeight + 'px';
    }, [imageHeight])

    useLayoutEffect(() => {
        if (imageVisible) {
            image.current.src = imageSrc;
            image.current.style.opacity = '1';
        } else {
            image.current.style.opacity = '0';
        }
    }, [imageVisible, imageSrc])

    return (
        <div className="FileInput">
            <div className="image--placeholder" ref={imagePlaceholder} style={{height: props.initialHeight + 'px'}}>
                <img src={placeholder} ref={image} onLoad={imgLoad} alt="Placeholder"/>
            </div>
            <label className="padding-15 margin-top-20 ripple" htmlFor={props.id}>
                <input type="file" id={props.id} onChange={fileChange} />
                <span className="label--text margin-right-20">Select File</span>
                <UploadIcon />
            </label>
            <div className="file--input--error margin-top-10">{props.error}</div>
        </div>
    )
}

export default FileInput;
import React from 'react';
import './AsideTab.scss';
import {Switch, Route, NavLink, useRouteMatch, useLocation} from 'react-router-dom';

type Navigator = {
    path: string;
    element: any;
    iconElement: JSX.Element;
    linkName: string;
    linkDescription: string;
}

interface Props {
    navigators: Array<Navigator>;
}

function AsideTab({navigators}: Props) {
    const {path} = useRouteMatch();
    const location = useLocation();
    return (
        <div className="AsideTab">
            <div className="aside--tab--content--container">
                <ul className="aside--tab__navigation padding-10">
                    {navigators.map((element, index) => {
                        return (
                            <li key={index}>
                                <NavLink to={element.path} className={`aside--tab__navigator ${index === 0 ? location.pathname === path ? 'active' : '' : ''}`}>
                                    {element.iconElement}
                                    <div className="text">
                                        <div className="link--name">{element.linkName}</div>
                                        <div className="link--description">{element.linkDescription}</div>
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })} 
                </ul>
                <div className={`aside--tab__content padding-10`}>
                    {navigators.map((element, index) => {
                        return (
                            <Switch key={index}>
                                <Route exact path={path}>
                                    {index === 0 ? navigators[0].element : null}
                                </Route>
                                <Route path={element.path}>
                                    {element.element}
                                </Route>
                            </Switch>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default AsideTab;
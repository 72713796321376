import React from 'react';
import './ClubData.scss';

import {DoubleArrow as DoubleArrowIcon} from '@material-ui/icons';

interface Props {
    name: string;
    identifier: string;
    game_platform: string;
    url: string;
}

function ClubData(props: Props) {
    return (
        <a
            className="ClubData"
            href={`http://${props.url}`}
            target="_blank"
            rel="noreferrer noopener">
            <div className="club--details">
                <div className="club--name">{props.name}</div>
                <span className="other--data">
                    <span>{props.identifier}, </span>
                    <span>{props.game_platform}</span>
                </span>
            </div>
            <DoubleArrowIcon />
        </a>
    )
}

export default ClubData;
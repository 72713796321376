import React from 'react';
import MemberHeader from './MemberHeader/MemberHeader';
import OwnerHeader from './OwnerHeader/OwnerHeader';

interface Props {
    headerType?: 'member' | 'owner';
}

function Header({headerType}: Props) {
    return headerType === 'member' ? <MemberHeader /> : <OwnerHeader />
}

export default Header;
import React, {useState, useEffect} from 'react';
import Container from 'elements/Container/Container';
import {useLocation, useHistory} from 'react-router-dom';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';

import {compose} from 'redux';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import withStoreConnection from 'hoc/withStoreConnection';
import {storeSetToken, storeSetUser} from 'auxiliary/dispatch';
import {user} from 'auxiliary/state';

import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    storeUserInformation(params: UserStateInterface): void;
}

function ResetPassword(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [newPassword, setNewPassword] = useState({...initialState});
    const [passwordConfirmation, setPasswordConfirmation] = useState({...initialState});
    const [timeStamp, setTimeStamp] = useState(0);

    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    
    useEffect(() => {
        let queryParams = new URLSearchParams(location.search);

        const validatePasswordToken = async function() {
            try {
                await axios.post(
                    '/account/validate-reset-password-token',
                    {
                        key: queryParams.get('key'),
                        token: queryParams.get('token')
                    }
                );
            } catch(error) {
                history.push("/", {message: error.response.data.message});
            }
        }
        validatePasswordToken();
    }, [location, history]);


    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }

        switch (result.origin) {
            case 'new-password':
                    setNewPassword(newState);
                break;
            case 'password-confirmation':
                    setPasswordConfirmation(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    }

    const submitForm = async (event: React.FormEvent) => {
        event.preventDefault();
        setTimeStamp(Date.now());

        setNewPassword({...newPassword, error: newPassword.valid ? '' : 'Password must be 8 or more characters'});
        setPasswordConfirmation({...passwordConfirmation, error: passwordConfirmation.valid ? '' : 'The passwords do not match'});

        const result = [
            newPassword,
            passwordConfirmation,
        ].map(e => e.valid);

        if (result.every(valid => valid)) {
            const data = {
                key: params.get('key'),
                token: params.get('token'),
                password: newPassword.value
            }

            try {
                const responseData = await axios.post('/account/reset-password', data);
                const signInData = {
                    email: responseData.data.email,
                    password: newPassword.value
                }
                const response: UserStateInterface = await axios.post('/members/sign_in', signInData);
                props.storeUserInformation(response);

                history.push('/member/profile', {message: "Your password has been updated successfully.", noticeState: 'success'});
            } catch(error) {
                console.log(error.response)
                history.push("/", {message: error.response.data.message});
            }
        }
    }

    return (
        <Container constrain={600}>
            <Heading text="Password reset" alignment="center" level="one" />
            <form onSubmit={submitForm}>
                
                <div className="input--group--container margin-top-20">
                    <span className="input--group--icon--label">New Password</span>
                    <InputGroup
                        change={handleChange}
                        error={newPassword.error}
                        icon="ti-lock"
                        id="new-password"
                        placeholder="New Password"
                        type="password"
                        valid={newPassword.valid}
                        validatedProps={{minLength: 8}}
                        timeStamp={timeStamp}
                        initialValue={''}
                    />
                </div>

                <div className="input--group--container margin-top-20">
                    <span className="input--group--icon--label">Confirm Password</span>
                    <InputGroup 
                        change={handleChange}
                        error={passwordConfirmation.error}
                        icon="ti-shield"
                        id="password-confirmation"
                        placeholder="Confirm Password"
                        type="password"
                        valid={passwordConfirmation.valid}
                        validatedProps={{}}
                        timeStamp={timeStamp}
                    />
                </div>
                <Button text={'Submit'} margin="margin-top-20" size="small" />
            </form>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetToken, storeSetUser]}),
    withUserInformationStorage
)(ResetPassword);
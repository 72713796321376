import React, {useContext, useEffect, useRef, useState} from 'react';
import './OwnerMenu.scss';
import {NavLink} from 'react-router-dom';

import { 
    Dashboard as DashboardIcon, 
    Assessment as AssessmentIcon,
    People as PeopleIcon,
    AccountBalance as AccountBalanceIcon,
    DeveloperBoard as DeveloperBoardIcon,
    Settings as SettingsIcon,
    Store as StoreIcon,
    MonetizationOn as MonetizationOnIcon
} from '@material-ui/icons';

import {OwnerMenuContext} from '../OwnerMenuProvider';

function OwnerMenu() {
    const context = useContext(OwnerMenuContext);
    const menuRef = useRef<HTMLUListElement>(null!);
    const [left_pos_val, setLeftPosVal] = useState(-200);

    useEffect(() => {
        if (window.innerWidth > 768) {
            menuRef.current.style.display = "block";
            if (context.menuVisible) {
                menuRef.current.style.flex = '0 1 300px';
                setLeftPosVal(0);
            } else {
                menuRef.current.style.flex = '0 1 0px';
                setLeftPosVal(-200);
            }
        }else{
            if (context.menuVisible) {                
                if(left_pos_val != 0){
                  setLeftPosVal(left_pos_val + 10)
                }
            } else {
                if(left_pos_val != -200){
                  setLeftPosVal(left_pos_val - 10)
                }
            }
        }
        menuRef.current.style.left = `${left_pos_val}px`;
    }, [context, left_pos_val]);

    return (
        <ul className="OwnerMenu" ref={menuRef}>
            <li>
                <NavLink exact to='/club'>
                    <DashboardIcon />
                    <div>General</div>
                </NavLink>
            </li>
            <li>
                <NavLink to='/club/activity'>
                    <AssessmentIcon />
                    <div>Club Activity</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/members">
                    <PeopleIcon />
                    <div>Members</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/accounts">
                    <AccountBalanceIcon />
                    <div>Bank Accounts</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/purchase-requests">
                    <StoreIcon />
                    <div>Purchase Requests</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/cash-outs">
                    <MonetizationOnIcon />
                    <div>Cash Outs</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/settings">
                    <SettingsIcon />
                    <div>Settings</div>
                </NavLink>
            </li>
            <li>
                <NavLink to="/club/page-editor">
                    <DeveloperBoardIcon />
                    <div>Page Editor</div>
                </NavLink>
            </li>
        </ul>
    )
}

export interface OwnerMenuContextInterface {
    menuVisible: boolean;
    toggleMenu(): void;
}

export default OwnerMenu;
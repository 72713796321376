import React from 'react';
import AsideTab from 'elements/AsideTab/AsideTab';
import Table from 'elements/Table/Table';
import {useRouteMatch} from 'react-router-dom';

import Container from 'elements/Container/Container';

import bankImage from 'assets/images/bank.png';
import deckImage from 'assets/images/deck.png';
import pokerChipsImage from 'assets/images/poker-chips.png';
import bankRoundImage from 'assets/images/bank-round.png';
import chipPurple from 'assets/images/chip-purple.png';

import {memberPurchaseRequests, memberCashOuts} from 'auxiliary/state';
import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';

import {
    Store as StoreIcon,
    MonetizationOn as MonetizationOnIcon,
    AttachMoney as AttachMoneyIcon
} from '@material-ui/icons';

import {MemberPurchaseRequestState} from 'redux/reducers/member/member-purchase-requests-reducer';
import {MemberCashOutState} from 'redux/reducers/member/member-cash-outs-reducer';

interface Props {
    setCashOuts(params: string): void;
    setPurchaseRequests(params: string): void;
    memberPurchaseRequests: MemberPurchaseRequestState;
    memberCashOuts: MemberCashOutState;
}

function Transactions(props: Props) {
    const {path} = useRouteMatch();

    const previousPageClickHandler = () => {
        props.setPurchaseRequests(`/account/purchase_requests?page=${props.memberPurchaseRequests.pagination.currentPage! - 1}`);
        
    }
    const nextPageClickHandler = () => {
        props.setPurchaseRequests(`/account/purchase_requests?page=${props.memberPurchaseRequests.pagination.currentPage! + 1}`);
    }

    const purchaseRequests = <Table
        headers={['Reference #', 'Request Date', 'Amount', 'Status', 'Bank Account', 'Linked Account']}
        content={
            props.memberPurchaseRequests.purchaseRequests.map((el, index) => [
                <div key={index} className="font-weight-600 ">{el.referenceNumber}</div>,
                el.createdAt,
                <div key={index} className="text--icon">
                    <div className="icon">
                        <AttachMoneyIcon />
                    </div>
                    <div className="text">
                        {el.amount}
                    </div>
                </div>,
                <div key={index} className={`status ${el.status}`}>
                    {el.status}
                </div>,
                <div key={index} className="text--image">
                    <img src={bankImage} alt="bank"/>
                    <div className="text">
                        <div>{el.bankAccount.accountName}</div>
                        <div>{el.bankAccount.accountNumber}</div>
                    </div>
                </div>,
                <div key={index} className="text--image">
                    <img src={deckImage} alt="deck"/>
                    <div className="text">
                        <div>{el.linkedAccount.gameID}</div>
                        <div>{el.linkedAccount.username}</div>
                    </div>
                </div>
            ])
        }
        pagination={{
            pages: props.memberPurchaseRequests.pagination.pages!,
            currentPage: props.memberPurchaseRequests.pagination.currentPage!,
            totalCount: props.memberPurchaseRequests.pagination.totalCount!
        }}
        previousPageClickHandler={previousPageClickHandler}
        nextPageClickHandler={nextPageClickHandler}
        progressIndicatorVisible={props.memberPurchaseRequests.progressIndicatorVisible}
    />

    const cashOuts = <Table
        headers={['Reference #', 'Request Date', 'Chips', 'Status', 'Bank Account', 'Linked Account']}
        content={
            props.memberCashOuts.cashOuts.map((el, index) => [
                <div key={index} className="font-weight-600">{el.referenceNumber || 'N/A'}</div>,
                el.createdAt,
                <div key={index} className="text--image--icon">
                    <div className="image-icon">
                        <img src={pokerChipsImage} alt="poker-chips"/>
                    </div>
                    <div className="text">
                        {el.chips}
                    </div>
                </div>,
                <div key={index} className={`status ${el.status}`}>
                    {el.status}
                </div>,
                <div key={index} className="text--image">
                    <img src={bankRoundImage} alt="bank"/>
                    <div className="text">
                        <div>{el.bankAccount.accountName}</div>
                        <div>{el.bankAccount.accountNumber}</div>
                    </div>
                </div>,
                <div key={index} className="text--image">
                    <img src={chipPurple} alt="deck"/>
                    <div className="text">
                        <div>{el.linkedAccount.gameID}</div>
                        <div>{el.linkedAccount.username}</div>
                    </div>
                </div>
            ])
        }
        pagination={{
            pages: props.memberCashOuts.pagination.pages!,
            currentPage: props.memberCashOuts.pagination.currentPage!,
            totalCount: props.memberCashOuts.pagination.totalCount!
        }}
        previousPageClickHandler={
            () => props.setCashOuts(`/account/withdrawal_requests?page=${props.memberPurchaseRequests.pagination.currentPage! - 1}`)
        }
        nextPageClickHandler={
            () => props.setCashOuts(`/account/withdrawal_requests?page=${props.memberPurchaseRequests.pagination.currentPage! + 1}`)
        }
        progressIndicatorVisible={props.memberCashOuts.progressIndicatorVisible}
    />

    const navigators = [
        {
            path: `${path}/purchase-requests`,
            iconElement: <StoreIcon />,
            linkName: 'Purchase Requests',
            linkDescription: 'Requests for purchasing chips',
            element: purchaseRequests
        },
        {
            path: `${path}/cash-outs`,
            iconElement: <MonetizationOnIcon />,
            linkName: 'Cash Outs',
            linkDescription: 'Withdrawal requests',
            element: cashOuts
        }
    ];

    return (
        <Container paddingOnly={true}>
            <div className="Transactions">
                <AsideTab
                    navigators={navigators}
                />
            </div>
        </Container>
    )
}

export default compose(
    withStoreConnection({stateProps: [memberPurchaseRequests, memberCashOuts]})
)(Transactions);
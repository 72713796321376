import {PaginationInterface, ActionInterface} from '../interfaces';

const initialState: OwnerMembersState = {
    members: [],
    pagination: {
        nextUrl: '',
        previousUrl: '',
        currentPage: null,
        pages: null,
        totalCount: null,
        searchQuery: null
    },
    searchQuery: '',
    progressIndicatorVisible: false
}

const actions = {
    SET_OWNER_MEMBERS: 'SET_OWNER_MEMBERS',
    REMOVE_OWNER_MEMBERS: 'REMOVE_OWNER_MEMBERS'
}

function reducer(state = initialState, action: ActionInterface<OwnerMembersState>) {
    switch(action.type) {
        case actions.SET_OWNER_MEMBERS:
            return {
                ...state,
                ...action.payload
            };
        case actions.REMOVE_OWNER_MEMBERS:
            return {
                ...initialState
            };
        default: {
            return state;
        }
    }
}

export interface OwnerMembersState {
    members: Array<any>,
    pagination: PaginationInterface;
    searchQuery?: string,
    progressIndicatorVisible: boolean;
}

export default reducer;
import {PaginationInterface, ActionInterface} from '../interfaces';

const initialState = {
    purchaseRequests: [],
    pagination: {
        nextUrl: '',
        previousUrl: '',
        currentPage: null,
        pages: null,
        totalCount: null,
        searchQuery: null
    },
    progressIndicatorVisible: false
}

const actions = {
    SET_MEMBER_PURCHASE_REQUESTS: 'SET_MEMBER_PURCHASE_REQUESTS',
    REMOVE_MEMBER_PURCHASE_REQUESTS: 'REMOVE_MEMBER_PURCHASE_REQUESTS'
}

function reducer(state = initialState, action: ActionInterface<MemberPurchaseRequestState>) {
    switch(action.type) {
        case actions.SET_MEMBER_PURCHASE_REQUESTS:
            return {
                ...state,
                ...action.payload
            };
        case actions.REMOVE_MEMBER_PURCHASE_REQUESTS:
            return {
                ...initialState
            };
        default: {
            return state;
        }
    }
}

export interface MemberPurchaseRequestState {
    purchaseRequests: Array<any>;
    pagination: PaginationInterface;
    progressIndicatorVisible: boolean;
}

export default reducer;
import React, {useContext, useState} from 'react';
import './OwnerAccountsDeleteAccount.scss';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';

import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {ownerBankAccounts} from 'auxiliary/state';
import {storeSetOwnerBankAccounts} from 'auxiliary/dispatch';

import {OwnerBankAccountsState} from 'redux/reducers/owner-bank-accounts-reducer';

interface Props {
    id: number;
    ownerBankAccounts: OwnerBankAccountsState;
    storeSetOwnerBankAccounts(params: OwnerBankAccountsState): void; 
}

function OwnerAccountsDeleteAccount(props: Props) {
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);
    const [buttonLoading, setButtonLoading] = useState(false);

    const deleteAccountHandler = async () => {
        try {
            setButtonLoading(true);
            await axios.delete(`/bank_accounts/${props.id}`);
            setTimeout(() => {
                const bankAccounts = [...props.ownerBankAccounts.bankAccounts];
                const index = bankAccounts.findIndex(account => account.id === props.id);
                bankAccounts.splice(index, 1);

                props.storeSetOwnerBankAccounts({
                    bankAccounts: bankAccounts,
                    pagination: {
                        ...props.ownerBankAccounts.pagination,
                        totalCount: props.ownerBankAccounts.pagination.totalCount! - 1
                    }
                })
                setButtonLoading(false);
                modal.hide();
                snackbar.show({text: 'The account has beeen deleted'});
            }, 2000)
        } catch(error) {
            setButtonLoading(false);
            modal.hide();
            let text = 'There was an error removing the account';
            if (error.response.status === 409) {
               text = 'The account could not be removed as there are transactions that linked to it.'
            } 
            setTimeout(() => {
                modal.setData({
                    header: 'Error',
                    content: {
                        template: 'error',
                        text: text
                    }
                })
                modal.show();
            }, 350)
            console.log(error.response)
        }
    }

    return (
        <div className="OwnerAccountsDeleteAccount">
            <div className="modal--data--content">
                <p>Are you certain you want to do this?</p>
                <div className="confirmation--buttons margin-top-20">
                    <Button
                        size="small" 
                        buttonType="text" 
                        text="Cancel" 
                        color="dark-red"
                        className="cancel"
                        clickHandler={
                            () => {
                                modal.hide();
                            }
                        }
                        margin="margin-right-20"
                    />
                    <Button 
                        width="80px"
                        text="Yes" 
                        loading={buttonLoading}
                        clickHandler={deleteAccountHandler}
                    />
                </div>
            </div>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [ownerBankAccounts], dispatchProps: [storeSetOwnerBankAccounts]})
)(OwnerAccountsDeleteAccount);
import React from 'react';
import {Switch, Route} from 'react-router-dom';
import LogIn from 'components/LogIn/LogIn';
import ForgotPassword from 'components/ForgotPassword/ForgotPassword';
import MemberRegistration from 'components/MemberRegistration/MemberRegistration';
import Owner from 'components/Owner/Owner';
import Error from 'components/Error/Error';
import LandingPage from 'components/LandingPage/LandingPage';

function OwnerTemplate(props: any) {
    return (
        <div className="App">
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/login" component={LogIn} />
                <Route path="/club" component={Owner} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/signup" component={MemberRegistration} />
                <Route path="/signup/:referral_code" component={MemberRegistration} />
                <Route path="*" render={() => <Error {...props} />} />
            </Switch>
        </div>
    );
}

export default OwnerTemplate;
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

interface Result {
    origin: string;
    value: string;
    valid: boolean;
}

interface Props {
    id: string;
    iconElement?: JSX.Element;
    change(params: Result): void,
    error: string;
    margin?: string;
    placeholder?: string;
}

function FormattedNumberInput(props: Props) {

    const handleValueChange = ({formattedValue, floatValue}: NumberFormatValues) => {
        const result = {
            origin: props.id,
            value: formattedValue,
            floatValue: floatValue,
            valid: floatValue ? true : false
        }

        props.change(result);
    }

    return (
        <div className={`InputGroup ${props.margin}`}>
            <label htmlFor={props.id}>
                <span className="input--group__icon">
                    {props.iconElement ? props.iconElement : <i className="ti-money"></i>}
                </span>
                <NumberFormat
                    thousandSeparator=','
                    allowNegative={false} 
                    id={props.id} 
                    placeholder={props.placeholder || "Amount to Send"}
                    onValueChange={handleValueChange}
                    decimalScale={2}
                    type='tel'
                />
            </label>
            <div className="input--error">{props.error}</div>
        </div>
    )
}

export default FormattedNumberInput;
import React, {useState, useContext} from 'react';
import './EditMobileNumber.scss';
import MobileNumberInput from 'elements/MobileNumberInput/MobileNumberInput';
import Button from 'elements/Button/Button';
import {useHistory} from 'react-router-dom';
import axios from 'auxiliary/axios';
import { connect } from 'react-redux';
import {ResultInterface} from 'elements/InputGroup/InputGroup';
import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';

import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    onSetMobileNumber(params: {mobileNumber: string}): void;
    user: UserStateInterface;
}

function EditMobileNumber(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [mobileNumber, setMobileNumber] = useState({...initialState, value: props.user.mobileNumber, valid: true});
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const history = useHistory();
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error

        }
        setMobileNumber(newState);
    }

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());
        setMobileNumber({...mobileNumber, error: mobileNumber.valid ? '' : mobileNumber.error || 'The number is invalid.'});
        if (mobileNumber.valid) {
            setButtonLoading(true);
            await axios.post("/account/update_mobile_number", {mobile_number: mobileNumber.value});
            props.onSetMobileNumber({
                mobileNumber: mobileNumber.value!
            });

            setTimeout(() => {
                setButtonLoading(false);
                snackbar.show({text: 'Number successfully updated'});
                history.push("/member-verification");
                modal.hide();
            }, 2000)
        }
    }

    return (
        <div className="EditMobileNumber margin-left-right-auto">
            <p className="text-align-center">Please enter your new mobile number</p>
            <form onSubmit={submitForm}>
                <MobileNumberInput 
                    change={handleChange}
                    error={mobileNumber.error}
                    margin="margin-top-30"
                    id="mobile-number"
                    timeStamp={timeStamp}
                    valid={mobileNumber.valid}
                    value={mobileNumber.value!}
                />

                <Button
                    text={'Submit'}
                    margin="margin-top-10"
                    width="80px"
                    backgroundColor="var(--owner-complement-shade-two)"
                    loading={buttonLoading}
                />
            </form>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    return {
        user: state.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        onSetMobileNumber: (newMobileNumber: any) => dispatch({type: 'SET_MOBILE_NUMBER', payload: newMobileNumber})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMobileNumber);
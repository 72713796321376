import React, {useRef, useEffect} from 'react';
import parse from 'html-react-parser';

interface Props {
    editedNode: string;
    editMode: boolean;
    detailsSection: any;
}

function LandingPageDetailsSection({editedNode, detailsSection}: Props) {
    const elementRef = useRef<HTMLImageElement>(null!);

    useEffect(() => {
        if (!elementRef.current) return;
        elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [editedNode]);

    const outlineProvider = (element: string) => {
        if (!editedNode) return null;
        return editedNode.match(element) ? '3px dashed #FFEB3B' : null
    }
    
    return (
        <section className="details--section">
            <div className="details--section__content padding-20" style={{maxWidth: '1300px', margin: '0 auto'}}>
                <div className="margin-bottom-20">Getting Started is Easy</div>
                <div className="detail--items">
                    {detailsSection.detailItems.map((el: any, index: number) => (
                        <div key={index} className="item"
                            ref={editedNode ? (editedNode.match(`detailsSection__itemContainer__${index + 1}`)) ? elementRef : null : null} 
                            style={{
                                outline: outlineProvider(`detailsSection__itemContainer__${index + 1}`)!
                            }}
                            > 
                            <img src={el.image} alt="" ref={editedNode ? (editedNode.match(`detailsSection__item__image__${index + 1}`)) ? elementRef : null : null}
                                style={{
                                    outline: outlineProvider(`detailsSection__item__image__${index + 1}`)!
                                }}
                            />
                            <div className="detail--item--text" ref={editedNode ? (editedNode.match(`detailsSection__item__text__${index + 1}`)) ? elementRef : null : null}
                                style={{
                                    outline: outlineProvider(`detailsSection__item__text__${index + 1}`)!
                                }}>
                                <span>{index + 1}.</span>
                                <div className="content--container">
                                    {parse(el.text)}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <a href="https://www.pppoker.net/#/home"
                className="download--link padding-20 margin-bottom-20" rel="noopener noreferrer" target="_blank">Download App and Play</a>
            <div className="need--help padding-20">
                <p>Need help?</p>
                <p>Use the bottom-right button to start a Live Chat</p>
                <p>Support available 24/7</p>
            </div>
        </section>
    )
}

export default LandingPageDetailsSection;
import React from 'react';
import Carousel from 'elements/Carousel/Carousel';
import {pageConfiguration} from 'auxiliary/state';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import { PageConfigurationState } from 'redux/reducers/page-configuration-reducer';

interface Props {
    pageConfiguration: PageConfigurationState;
}

function MemberPromotions(props: Props) {
    return (
        <div className="MemberPromotions" style={{height: 'calc(100vh - 80px)', backgroundColor: '#fff'}}>
            <Carousel images={props.pageConfiguration.carouselSection.images} />
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [pageConfiguration]})
)(MemberPromotions);
import React, {useEffect, useRef} from 'react';
import './LoadingScreen.scss';
import {LoadingScreenContextInterface} from './LoadingScreenProvider';

interface Props {
    loadingScreenContext: LoadingScreenContextInterface
}

function LoadingScreen({loadingScreenContext}: Props) {
    const screenRef = useRef<HTMLDivElement>(null!);
    const {visibility} = loadingScreenContext;

    useEffect(function toggleVisibility() {
        const screen = screenRef.current;
        if (visibility === 'visible') {
            screen.style.cssText = 'visibility: visible; opacity: 0.98';
        } else {
            screen.style.cssText = 'opacity: 0';
            setTimeout(() => {
                screen.style.cssText = 'visibility: hidden';
            }, 300)
        }
    }, [visibility])

    return (
        <div className="LoadingScreen" ref={screenRef}>
            <div className="loading--element">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default LoadingScreen;
import React from 'react';
import {Switch, Route, useRouteMatch, Redirect} from 'react-router-dom';
import InGameAccountsList from './InGameAccountsList/InGameAccountsList';

function InGameAccounts() {
    const {path} = useRouteMatch();
    return (
        <Switch>
            <Route exact path={path} component={InGameAccountsList} />
            <Route path="*" render={() => <Redirect to={path} />} />
        </Switch>
    )
}

export default InGameAccounts;
import React from 'react';
import './Card.scss';

interface Props {
    image: string;
    title: string;
    description: string;
    action: JSX.Element
}

function Card({image, title, description, action}: Props) {
    return (
        <div className="Card">
            <div className="image--container">
                <img src={image} alt="card content" />
            </div>
            <div className="text--content">
                <h2 className="title">{title}</h2>
                <p className="description">{description}</p>
            </div>
            <div className="card--action">
                {action}
            </div>
        </div>
    )
}

export default Card;
import React from 'react';
import './CheckBoxStyle.scss';

interface Props {
    id: string;
    text: string;
}

function CheckBox({id, text}: Props) {
    return (
        <label htmlFor={id} className="CheckBox">
            <input type="checkbox" id={id} />
            <span className="svg--container margin-right-10">
                <svg viewBox="0, 0, 50, 50">
                    <path d="M5 30 L 20 45 L 45 5"></path>
                </svg>
            </span>
            <span className="svg--checkbox__text">{text || 'Remember me'}</span>
        </label>
    )
}

export default CheckBox;

import React, {useState, useContext, useEffect} from 'react';
import './ClubSettings.scss';
import FormHeader from '../components/FormHeader/FormHeader';
// import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import axios from 'auxiliary/axios';

import {compose} from 'redux';
import {user} from 'auxiliary/state';
import {storeSetUser, storeSetToken} from 'auxiliary/dispatch';
import withStoreConnection from 'hoc/withStoreConnection';
import withUserInformationStorage from 'hoc/withUserInformationStorage';
import {UserStateInterface} from 'redux/reducers/user-reducer';

import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
    MenuBook as MenuBookIcon,
    ViewAgenda as ViewAgendaIcon,
    Stars as StarIcon,
    AttachMoney as CurrencyIcon
} from '@material-ui/icons';

interface Club {
    id: number;
    name: string;
    identifier: string;
    currency: string;
    chip_value: number;
    game_platform_id: number;
    game_platform: {
        id: number;
        name: string;
    }
    url: string;
}

interface Currency {
  code: string;
  name: string;
}

interface GamePlatform {
  id: number;
  name: string;
}

interface Props {
    user: UserStateInterface;
    storeUserInformation(params: {data: UserStateInterface}): void;
}

function ClubSettings({user, storeUserInformation}: Props) {    
    const initialState = {value: '', valid: false, error: ''};
    const initialClubState = {
      id: 0,
      name: '',
      identifier: '',
      currency: '',
      chip_value: 1,
    };
    const snackbar = useContext(SnackbarContext);
    const [timeStamp, setTimeStamp] = useState(0);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [currencyList, setCurrencyList] = useState<Array<Currency>>([]);
    const [gamePlatformsList, setGamePlatformsList] = useState<Array<GamePlatform>>([]);
    const [currentClub, setCurrentClub] = useState<Partial<Club>>({...initialClubState});

    useEffect(function getCurrentClub() {
        const init = async () => {
            const response = await axios.get(process.env.REACT_APP_BASE_URL!);
            setCurrentClub({...response.data, game_platform_id: Number(response.data.game_platform.id)});

            // FIXME
            const currencyResponse = await axios.get('https://api.admindesk.club/api/v1/currencies');
            setCurrencyList(currencyResponse.data.currencies);

            // FIXME
            const gamePlatformsResponse = await axios.get('https://api.admindesk.club/api/v1/marketing/game-platforms');
            setGamePlatformsList(
              gamePlatformsResponse.data.game_platforms.map((g: any) => 
                ({ id: Number(g.value), name: g.text })
              )
            );

        }
        init();
    }, [])


    const formSubmitHandler = async (e: React.FormEvent) => {
        e.preventDefault();
        setTimeStamp(Date.now());

        console.log(currentClub);

        try {
            setButtonLoading(true);
            const response = await axios.patch(`/`, currentClub);
            setTimeout(() => {
                setButtonLoading(false);
                snackbar.show({text: 'Your club settings have been updated'});
            }, 2500)
        } catch(error) {
            setTimeout(() => {
                setButtonLoading(false);
                const errorMessage = error.response.data.error;
            }, 2500)
        }
    }

    return (
        <div className="ClubSettings">
            <form onSubmit={formSubmitHandler} className="navigation--content--form">
                <FormHeader
                  buttonLoading={buttonLoading}
                  label="Edit Club"
                  description="Change your club settings"/>
                <div className="form--input">
                    
                    <div className="inputGroup">
                      <TextField 
                      className="formField"
                      variant="outlined"
                      label="Club Name"
                      onChange={e => setCurrentClub({ ...currentClub, name: e.target.value })}
                      value={currentClub.name}
                      InputProps={
                        {
                          startAdornment: (
                            <InputAdornment position="start">
                              <MenuBookIcon />
                            </InputAdornment>
                          ),
                        }
                      } />
                    </div>

                    <div className="inputGroup">
                      <TextField 
                      className="formField"
                      variant="outlined"
                      label="Club Identifier" 
                      value={currentClub.identifier}
                      onChange={e => setCurrentClub({ ...currentClub, identifier: e.target.value })}
                      InputProps={
                        {
                          startAdornment: (
                            <InputAdornment position="start">
                              <ViewAgendaIcon />
                            </InputAdornment>
                          ),
                        }
                      } />
                    </div>

                    <div className="inputGroup">
                      <TextField 
                      className="formField"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      label="Game Platform" 
                      value={currentClub.game_platform_id}
                      onChange={e => setCurrentClub({ ...currentClub, game_platform_id: Number(e.target.value) })}
                      InputProps={
                        {
                          startAdornment: (
                            <InputAdornment position="start">
                              <ViewAgendaIcon />
                            </InputAdornment>
                          ),
                        }
                      }>
                        {gamePlatformsList.map((platform) => (
                          <option key={platform.id} value={platform.id}>
                            { platform.name }
                          </option>
                        ))}
                      </TextField>
                    </div>

                    <div className="inputGroup">
                      <TextField 
                      className="formField"
                      variant="outlined"
                      select
                      SelectProps={{
                        native: true,
                      }}
                      label="Currency" 
                      value={currentClub.currency}
                      onChange={e => setCurrentClub({ ...currentClub, currency: e.target.value })}
                      InputProps={
                        {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CurrencyIcon />
                            </InputAdornment>
                          ),
                        }
                      }>
                        {currencyList.map((currency) => (
                          <option key={currency.code} value={currency.code}>
                            { currency.code } ({ currency.name })
                          </option>
                        ))}
                      </TextField>
                    </div>

                    <div className="inputGroup">
                      <TextField 
                      className="formField"
                      variant="outlined"
                      type="number"
                      label="Chip Value" 
                      value={currentClub.chip_value}
                      onChange={e => setCurrentClub({ ...currentClub, chip_value: Number(e.target.value!) })}
                      InputProps={
                        {
                          startAdornment: (
                            <InputAdornment position="start">
                              <StarIcon />
                            </InputAdornment>
                          ),
                        }
                      } />
                      1 chip = { currentClub.chip_value } { currentClub.currency }
                    </div>

                </div>
            </form>
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetToken, storeSetUser]}),
    withUserInformationStorage
)(ClubSettings);
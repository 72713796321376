import React from 'react';

import {
    Person as PersonIcon,
    ArrowUpward as ArrowUpwardIcon,
    ShoppingCart as ShoppingCartIcon,
    AccountBalance as AccountBalanceIcon,
    Assignment as AssignmentIcon
} from '@material-ui/icons';

export const initialCardData = [
    {
        icon: <PersonIcon />,
        label: 'Members',
        count: 754,
        updateDetails: (
            <span className="update">
                <ArrowUpwardIcon className="increase" />
                <span className="text">15 new members</span>
            </span>
        ),
        chartColor: "#7986CB",
        chartData: [10, 15, 14, 17, 15, 16, 10]
    },
    {
        icon: <ShoppingCartIcon />,
        label: 'Purchases',
        count: 87,
        updateDetails: (
            <span className="update">
                <ArrowUpwardIcon className="increase" />
                <span className="text">10% from this week</span>
            </span>
        ),
        chartColor: '#FFB74D',
        chartData: [10, 13, 14, 13, 15, 14, 10]
    },
    {
        icon: <AccountBalanceIcon />,
        label: 'Withdrawals',
        count: 43,
        updateDetails: (
            <span className="update">
                <ArrowUpwardIcon className="increase" />
                <span className="text">16% from this week</span>
            </span>
        ),
        chartColor: '#4DD0E1',
        chartData: [10, 12, 13, 12, 14, 15, 10]
    },
    {
        icon: <AssignmentIcon />,
        label: 'Completed Tasks',
        count: 331,
        updateDetails: (
            <span className="update">
                <ArrowUpwardIcon className="increase" />
                <span className="text">21% from this week</span>
            </span>
        ),
        chartColor: '#81C784',
        chartData: [10, 13, 12, 13, 11, 13, 10]
    }
]
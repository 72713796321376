import React, {useEffect, useCallback, useState} from 'react';
import Table from 'elements/Table/Table';
import Container from 'elements/Container/Container';
import axios from 'auxiliary/axios';

import {
  MonetizationOn as MonetizationOnIcon,
  AttachMoney as AttachMoneyIcon
} from '@material-ui/icons';
import bankImage from 'assets/images/bank.png';
import deckImage from 'assets/images/deck.png';

interface TableData {
  cash_outs: Array<any>;
  pagination: {
    pages: number;
    currentPage: number;
    totalCount: number;
    progressIndicatorVisible: boolean;
  };
}


function CashOuts(props: any) {
  const [cash_outs_data, setCashOutsData] = useState<TableData>({ cash_outs: [], pagination: {pages: 0, currentPage: 0, totalCount: 0, progressIndicatorVisible: true} });
  const getCashOuts = async (url: any) => {

    try {
        
      const response = await axios.get(url);
      const cashOuts = response.data.withdrawal_requests.map((el: any) => {
          return {
              id: el.id,
              referenceNumber: el.reference_number,
              createdAt: el.created_at,
              amount: el.amount,
              bankAccount: {
                  id: el.bank_account.id,
                  accountName: el.bank_account.account_name,
                  accountNumber: el.bank_account.account_number,
                  bankName: el.bank_account.bank_name
              },
              imageUrl: el.image_url,
              linkedAccount: {
                  id: el.linked_account.id,
                  gameID: el.linked_account.game_id,
                  username: el.linked_account.username
              },
              status: el.status
          }
      });
      
      setCashOutsData({
        cash_outs: cashOuts, 
        pagination: {
          pages: response.data.pagination.pages, currentPage: response.data.pagination.current, totalCount: response.data.pagination.count, progressIndicatorVisible: false
        }});
    } catch(error) {
      console.log(error)
      console.log('owner withdrawal_requests retrieval failure')
    }
  }

  useEffect(() => {
    getCashOuts('/account/withdrawal_requests');
  }, []);

  const previousPageClickHandler = () => {
    getCashOuts(`/account/withdrawal_requests?page=${cash_outs_data.pagination.currentPage - 1}`);
      
  }
  const nextPageClickHandler = () => {
    getCashOuts(`/account/withdrawal_requests?page=${cash_outs_data.pagination.currentPage + 1}`);
  }

  return (
    <Container paddingOnly={true}>
        <Table
        headers={['Reference #', 'Request Date', 'Chips', 'Status', 'Bank Account', 'Linked Account']}
        content={
            cash_outs_data.cash_outs.map((el, index) => [
                <div key={index} className="font-weight-600 ">{el.referenceNumber}</div>,
                el.createdAt,
                <div key={index} className="text--icon">
                    <div className="icon">
                        <AttachMoneyIcon />
                    </div>
                    <div className="text">
                        {el.amount}
                    </div>
                </div>,
                <div key={index} className={`status ${el.status}`}>
                    {el.status}
                </div>,
                <div key={index} className="text--image">
                    <img src={bankImage} alt="bank"/>
                    <div className="text">
                        <div>{el.bankAccount.accountName}</div>
                        <div>{el.bankAccount.accountNumber}</div>
                    </div>
                </div>,
                <div key={index} className="text--image">
                    <img src={deckImage} alt="deck"/>
                    <div className="text">
                        <div>{el.linkedAccount.gameID}</div>
                        <div>{el.linkedAccount.username}</div>
                    </div>
                </div>
            ])
        }
        pagination={{
            pages: cash_outs_data.pagination.pages,
            currentPage: cash_outs_data.pagination.currentPage,
            totalCount: cash_outs_data.pagination.totalCount
        }}
        previousPageClickHandler={previousPageClickHandler}
        nextPageClickHandler={nextPageClickHandler}
        progressIndicatorVisible={cash_outs_data.pagination.progressIndicatorVisible}
      />
    </Container>
  )
}
export default CashOuts;
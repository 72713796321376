import React, {useState, useEffect, useRef, useContext} from 'react';
import './VerifyMobileNumber.scss';
import Heading from 'elements/Heading/Heading';
import InputGroup, {ResultInterface} from 'elements/InputGroup/InputGroup';
import Button from 'elements/Button/Button';
import axios from 'auxiliary/axios';
import {ModalContext} from 'providers/ModalProvider';
import {SnackbarContext} from 'elements/Snackbar/SnackbarProvider';
import EditMobileNumber from '../EditMobileNumber/EditMobileNumber';

import EditIcon from '@material-ui/icons/Edit';
import {useHistory, useParams} from 'react-router-dom';

import {compose} from 'redux';
import withStoreConnection from 'hoc/withStoreConnection';
import {user} from 'auxiliary/state';
import {storeSetUser, storeVerifyUser} from 'auxiliary/dispatch';

import {UserStateInterface} from 'redux/reducers/user-reducer';

interface Props {
    user: UserStateInterface;
    location: {
        state: {
            message: string;
            noticeState: string;
        }
    }
    storeVerifyUser(): void;
}

function VerifyMobileNumber(props: Props) {
    const initialState = {value: '', valid: false, error: ''};
    const [verificationCode, setVerificationCode] = useState({...initialState});
    const [inputTimeStamp, setInputTimeStamp] = useState(0);
    const [resendButtonLoading, setResendButtonLoading] = useState(false);
    const [continueButtonLoading, setContinueButtonLoading] = useState(false);
    const history = useHistory();
    const modal = useContext(ModalContext);
    const snackbar = useContext(SnackbarContext);
    
    const componentRef = useRef<HTMLDivElement>(null!);
    const {verification_code} = useParams();

    const handleChange = (result: ResultInterface) => {
        const newState = {
            value: result.value,
            valid: result.valid,
            error: result.error
        }
        switch (result.origin) {
            case 'verification-code':
                    setVerificationCode(newState);
                break;
            default: 
                throw new Error(`${result.origin} origin is not defined`);
        }
    };

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setContinueButtonLoading(true);
            await axios.post(`/account/verify?verification_code=${verificationCode.value}`);
            setTimeout(() => {
                snackbar.show({text: 'Your account has been verified successfully'});
                history.push('/member/profile');
                props.storeVerifyUser();
            }, 2000)
        } catch(error) {
            setTimeout(() => {
                console.log(error.response.data.error);
                setContinueButtonLoading(false);
                const errorMessage = /did not match/.test(error.response.data.error) ? 'Verification code is incorrect.' : error.response.data.error;
                setVerificationCode({...verificationCode, valid: false, error: verificationCode.error || errorMessage});
                setInputTimeStamp(Date.now());
            }, 2000)
        }
    }

    const resendCode = () => {
        setResendButtonLoading(true);
        setTimeout(async () => {
            await axios.post('/account/resend_code');
            setResendButtonLoading(false);
            snackbar.show({text: 'Verification code has been resent'});
        }, 2500)
    }

    const editNumberHandler = (e: React.MouseEvent) => {
        e.preventDefault();
        modal.setData({
            header: 'Edit mobile number',
            content: (
                <div className="EditMobileNumber">
                    <EditMobileNumber />
                </div>
            )
        })
        modal.show();
    }

    useEffect(function () {
        if (verification_code !== undefined) {
            setVerificationCode(previousValue => ({...previousValue, value: verification_code}));
        }
    }, [verification_code])

    return (
        <div className="VerifyMobileNumber" ref={componentRef}>
            <form onSubmit={submitForm}>
                <Heading text="Verify Number" alignment="center" level="two"/>
                <img src="https://i.ibb.co/X7Pstn9/mobile-phone.png" alt="mobile-phone" className="mobile--phone margin-top-50 margin-left-right-auto" />
                <div className="max-width-300 margin-left-right-auto">
                    <p className="margin-top-20">
                        We&apos;ve sent a 4-digit verification code to your number {props.user.mobileNumber}
                        <button className="edit--number--button" onClick={editNumberHandler}>
                            <EditIcon />
                            <span>Edit</span>
                        </button>
                    </p>
                    <InputGroup 
                        autofocus={true}
                        change={handleChange}
                        error={verificationCode.error}
                        icon="ti-split-v-alt" 
                        id="verification-code"
                        placeholder="Verification Code"
                        type="text"
                        margin="margin-top-20"
                        valid={verificationCode.valid}
                        validatedProps={{}}
                        value={verificationCode.value}
                        timeStamp={inputTimeStamp}
                    />
                </div>
                <Button
                    text="Continue"
                    margin="margin-top-20"
                    size="medium"
                    loading={continueButtonLoading}
                    width="85px"
                />
            </form>
            <Button 
                text="Resend Code"
                margin="margin-top-20"
                size="small"
                buttonType="text"
                clickHandler={resendCode}
                color="accent-two"
                loading={resendButtonLoading}
                width="105px"
            />
        </div>
    )
}

export default compose(
    withStoreConnection({stateProps: [user], dispatchProps: [storeSetUser, storeVerifyUser]})
)(VerifyMobileNumber)
